import React from 'react'
import clsx from 'clsx'
import styles from './Likert.module.scss'

interface LikertProps {
  count?: number
  value?: number
  onSelect?: (value: number) => void
  isLittleCard?: boolean
}

export const Likert: React.FC<LikertProps> = ({
  count = 10,
  isLittleCard,
  onSelect,
  value,
}) => {
  const numbers = Array.from({ length: count }, (_, i) => count - i)

  return (
    <div className={styles.container}>
      {numbers.map((n) => {
        return (
          <LikertTile
            selected={value === n}
            isLittleCard={isLittleCard}
            onClick={onSelect ? () => onSelect(n) : undefined}
            key={n}
          >
            {n}
          </LikertTile>
        )
      })}
    </div>
  )
}

interface LikertTileProps {
  onClick?: () => void
  selected?: boolean
  isLittleCard?: boolean
}
const LikertTile: React.FC<LikertTileProps> = ({
  children,
  isLittleCard,
  onClick,
  selected,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        isLittleCard ? styles.littleTitle : styles.title,
        selected && styles.selected
      )}
    >
      {children}
    </div>
  )
}
