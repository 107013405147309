import React from 'react'
import { Indicators } from '../../components2/Indicators/Indicators'
import PageMeta from '../../components2/PageMeta'
import { isNotNull, namespaceStyle } from '../../utils'
import { ServerListeningPost, ServerPublicNamespace } from '../../utils/trpc'
import styles from './DisplayListeningPortal.module.scss'
import { SlideContentContainer } from './slide/SlideContent/SlideContentContainer'
import { useSlidesDisplayContext } from './state/slides-display.context'

interface Props {
  namespace: ServerPublicNamespace
  listeningPost: ServerListeningPost
}

const DisplayListeningPortal: React.FC<Props> = ({ namespace, listeningPost }) => {
  const {
    state: { slides, activeIndex },
    dispatch,
  } = useSlidesDisplayContext()

  const [feedbackId, setFeedbackId] = React.useState<string>()

  React.useEffect(() => {
    const slides = listeningPost.sections
      ?.map((section) => listeningPost.groupedSlideIds[section])
      .flat()
      .map((id) => (id ? listeningPost.slides?.[id] : undefined))
      .filter(isNotNull)

    if (slides) {
      dispatch({ type: 'SET_SLIDES', payload: slides })
    }
  }, [dispatch, listeningPost])

  const style = namespaceStyle(listeningPost)

  return (
    <div className={styles.container} style={style}>
      <PageMeta namespace={namespace} listeningPost={listeningPost} />
      <Indicators
        times={
          slides?.map(({ duration, kind }) => (kind === 'text' ? (duration ?? 0) * 1000 : -1)) || []
        }
        dispatch={dispatch}
        index={activeIndex}
      />

      {slides.map((slide, i) => {
        return (
          <SlideContentContainer
            key={slide.id}
            visible={activeIndex === i}
            activeIndex={activeIndex}
            slide={slide}
            feedbackId={feedbackId}
            setFeedbackId={setFeedbackId}
            namespace={namespace}
            listeningPost={listeningPost}
          />
        )
      })}
    </div>
  )
}

export default DisplayListeningPortal
