import clsx from 'clsx'
import React from 'react'
import { LinkProps, NavLink } from 'react-router-dom'
import styles from './LeftNav.module.scss'

interface LinkRowProps extends LinkProps {
  text: string
  icon: JSX.Element
  end?: boolean
}

export const LinkRow: React.FC<LinkRowProps> = ({ icon, text, to, end }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? clsx(styles.row, styles.active) : styles.row || '')}
      end={end}
    >
      <div className={styles.rowIcon}>{icon}</div>
      <div className={styles.rowText}>{text}</div>
    </NavLink>
  )
}