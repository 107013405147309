import clsx from 'clsx'
import { keyBy, sortBy } from 'lodash'
import React, { useRef } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { generate } from 'short-uuid'
import { DragHandleIcon, TrashIcon } from '../../../../currentColorIcons'
import { ServerListeningPostSlideAnswerOption } from '../../../../utils/trpc'
import styles from './MultipleChoiceField.module.scss'

interface Props {
  scrollTo?: boolean
  defaultOptions?: Record<string, ServerListeningPostSlideAnswerOption>
  onUpdate?: (options: Record<string, ServerListeningPostSlideAnswerOption>) => void
  onDelete?: (answerOptionId: string) => void
  isLittleCard?: boolean
}

type OptionState = { id: string; label: string; focus: boolean }

export const MultipleChoiceField: React.FC<Props> = ({
  onUpdate,
  isLittleCard,
  defaultOptions,
  onDelete,
  scrollTo,
}) => {
  const [options, setOptions] = React.useState<OptionState[]>(() => {
    return sortBy(
      Object.values(
        defaultOptions || [{ id: generate().toString(), label: '', focus: scrollTo || false }]
      ),
      'order'
    ).map((o) => ({
      id: o.id,
      label: o.label,
      focus: o.focus || false,
    }))
  })

  const updateOptionsInDb = React.useCallback(
    (options: OptionState[]) => {
      const serverOptions = options
        .filter((option) => option.label !== '')
        .map((option, i) => ({ id: option.id, label: option.label, order: i }))

      onUpdate?.(keyBy(serverOptions, 'id'))
    },
    [onUpdate]
  )

  const handleOnChangeOption = React.useCallback(
    (changeId: string, updatedValue: string) => {
      setOptions((options) => {
        const newOptions = options.map((option) =>
          option.id === changeId ? { ...option, label: updatedValue } : option
        )

        updateOptionsInDb(newOptions)
        return newOptions
      })
    },
    [updateOptionsInDb]
  )

  const handleOnAddOption = React.useCallback((index: number) => {
    const addIndex = index + 1
    setOptions((options) => [
      ...options.slice(0, addIndex).map((o) => ({ ...o, focus: false })),
      {
        id: generate().toString(),
        label: '',
        focus: true,
      },
      ...options.slice(addIndex).map((o) => ({ ...o, focus: false })),
    ])
  }, [])

  const deleteOption = React.useCallback(
    (index: number) => {
      setOptions((options) => {
        if (options.length === 1) return options

        // delete this option in DB as well
        const optionId = options[index]?.id
        if(optionId) {
          onDelete?.(optionId)
        }

        return options
          .filter((o, i) => i !== index)
          .map((o, i) => {
            return {
              ...o,
              focus: i === Math.max(index - 1, 0),
            }
          })
      })
    },
    [onDelete]
  )

  const handleOnKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, keyIndex: number, value: string) => {
      if (e.key === 'Backspace' && value === '') {
        e.preventDefault()
        deleteOption(keyIndex)
      }

      if (e.key === 'Enter') {
        handleOnAddOption(keyIndex)
      }
    },
    [deleteOption, handleOnAddOption]
  )

  const handleOnKeyDownAddOption = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, keyIndex: number) => {
      if (
        ![
          'Tab',
          'Shift',
          'ArrowUp',
          'ArrowDown',
          'ArrowLeft',
          'ArrowRight',
          'Backspace',
          'Delete',
          'Meta',
          'Alt',
          'Control',
        ].includes(e.key)
      ) {
        handleOnAddOption(keyIndex)
      }
    },
    [handleOnAddOption]
  )

  const handleOnDragEnd = React.useCallback(
    (result) => {
      if (!result.destination) return

      setOptions((options) => {
        const newOptions = Array.from(options)
        const [reorderedItem] = newOptions.splice(result.source.index, 1)
        if (!reorderedItem) return options
        newOptions.splice(result.destination.index, 0, reorderedItem)

        updateOptionsInDb(newOptions)

        return newOptions
      })
    },
    [setOptions, updateOptionsInDb]
  )

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="multiple-options">
        {(provided) => {
          return (
            <div
              className={clsx(isLittleCard ? styles.little : undefined, styles.container)}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {options.map((option, i) => (
                <Draggable draggableId={option.id} index={i} key={option.id}>
                  {(provided, snapshot) => {
                    return (
                      <Option
                        ref={provided.innerRef}
                        dndProps={{
                          draggableProps: provided.draggableProps,
                          dragHandleProps: provided.dragHandleProps || undefined,
                          isDragging: snapshot.isDragging,
                        }}
                        focus={option.focus}
                        index={i}
                        value={option.label}
                        className={clsx(options.length === 1 && styles.noSideActions)}
                        onDelete={() => (onUpdate ? deleteOption(i) : undefined)}
                        onChange={(value) =>
                          onUpdate ? handleOnChangeOption(option.id, value) : undefined
                        }
                        onKeyDown={(e) =>
                          onUpdate ? handleOnKeyDown(e, i, option.label) : undefined
                        }
                      />
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
              <Option
                focus={false}
                onClick={() => handleOnAddOption(options.length)}
                index={options.length}
                value="Add option"
                readOnly={true}
                onKeyDown={(e) =>
                  onUpdate ? handleOnKeyDownAddOption(e, options.length) : undefined
                }
              />
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

interface OptionsProps {
  index: number
  value: string
  onClick?: () => void
  className?: string
  readOnly?: boolean
  onChange?: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onDelete?: () => void
  focus: boolean
  dndProps?: {
    draggableProps?: React.HtmlHTMLAttributes<HTMLDivElement>
    dragHandleProps?: React.HtmlHTMLAttributes<HTMLSpanElement>
    isDragging?: boolean
  }
}

const Option = React.forwardRef<HTMLDivElement, OptionsProps>(
  (
    {
      onClick,
      index,
      value,
      onChange,
      readOnly = false,
      className,
      onKeyDown,
      focus,
      dndProps,
      onDelete,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value)
      }
    }

    React.useEffect(() => {
      if (focus) {
        inputRef.current?.focus()
      }
    }, [focus])

    const classList = clsx(
      styles.option,
      !readOnly && styles.focusable,
      dndProps?.isDragging && styles.isDragging,
      className
    )

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={classList}
        {...dndProps?.draggableProps}
        tabIndex={-1}
      >
        {!readOnly && (
          <div className={styles.sideActions}>
            <button className={clsx(styles.button, styles.trash)} onClick={onDelete} tabIndex={-1}>
              <TrashIcon />
            </button>
            <button className={styles.button} {...dndProps?.dragHandleProps} tabIndex={-1}>
              <DragHandleIcon />
            </button>
          </div>
        )}

        <div className={clsx(styles.box, readOnly && styles.readOnly)}>{index + 1}</div>
        {readOnly && (
          <span tabIndex={-1} className={styles.optionText}>
            {value}
          </span>
        )}
        {!readOnly && (
          <input
            ref={inputRef}
            tabIndex={0}
            onKeyDown={onKeyDown}
            className={styles.optionInputText}
            placeholder={`Option ${index + 1}`}
            onChange={handleOnChange}
            value={value}
          />
        )}
      </div>
    )
  }
)
Option.displayName = 'Option'
