import React from 'react'
import { Likert7 } from '../../../DesignListeningPortal/components/slide-extra-field/Likert7/Likert7'
import { ServerFeedbackCreate } from '../../../utils/trpc'

interface Props {
  visible: boolean
  onSelect: (data: ServerFeedbackCreate) => void
}

const Likert7Slide: React.FC<Props> = ({ onSelect, visible }) => {
  const [value, setValue] = React.useState(0)

  const handleSelect = React.useCallback(
    (value: number) => {
      setValue(value)
      onSelect({ kind: 'likert7', value })
    },
    [onSelect]
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Likert7 value={value} label={labelById(value)} onSelect={handleSelect} visible={visible} />
    </div>
  )
}


const labelById = (n: number) => {
  switch(n) {
    case 1: return 'Strongly Disagree';
    case 2: return 'Disagree';
    case 3: return 'Somewhat Disagree';
    case 4: return 'Neutral';
    case 5: return 'Somewhat Agree';
    case 6: return 'Agree';
    case 7: return 'Strongly Agree';
  }
}

export default Likert7Slide
