import React from 'react'
import { Link } from 'react-router-dom'
import { HarmonizeLogo, HarmonizeLogoText, Heart } from '../../toneIcons'
import { namespaceStyle } from '../../utils'
import { trpc } from '../../utils/trpc'
import styles from './About.module.scss'

interface AboutProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespaceId?: string
}

export const About: React.FC<AboutProps> = ({ namespaceId }) => {
  const namespaceQuery = trpc.namespace.publicId.useQuery(
    { id: namespaceId || '' },
    { enabled: Boolean(namespaceId) }
  )

  const style = namespaceStyle(namespaceQuery.data)

  return (
    <div className={styles.container} style={style}>
      <div className={styles.topBar}></div>
      <div className={styles.content}>
        <div className={styles.loveIcons}>
          <HarmonizeLogo className={styles.ourLogo} />
          <Heart className={styles.heart} />
          <img className={styles.theirLogo} src={namespaceQuery.data?.logo} alt="logo" />
        </div>
        <h1>A Listening Portal for {namespaceQuery.data?.name}</h1>

        <h2>ListensIn</h2>
        <p>
          ListensIn is a 1st Party VoC inquiry platform offered by{' '}
          <a href="https://harmonize.ai">HarmonizeAi</a>.
        </p>

        <h2>What are Listening Portals?</h2>
        <p>
          Listening portals are one of several new channels for customer / employee / and citizen
          feedback that leverage Design, Behavioral Psychology, and the latest advanced in Natural
          Language Processing and Machine Learning to provide timely, direct and valuable inputs
          from customers and prospects to decision makers. Listening portals are more engaging than
          traditional surveys. Harmonize's Listening Portals are especially suited to an era of
          'contactless' interaction.
        </p>

        <h2>Value to Respondents</h2>
        <p>
          For respondents, they provide a more humanizing, straightforward and respectful form of
          interaction–better than bubble surveys designed for 100-year-old card-sorting machines–
          that opens a dialog with another human being.
        </p>

        <h2>Value to Organizations</h2>
        <p>
          For organizations, listening portals are places, either on-line or real-world, where an
          organization takes samples of people's opinions, feelings, comments and other feedback.
          They're also places to offer support, advice, and respond to the feedback and close the
          loop.
        </p>

        <p>
          Go back to <Link to="/">{namespaceQuery.data?.name} Listens</Link>.
        </p>

        <HarmonizeLogoText className={styles.harmonizeLogo} />
      </div>
    </div>
  )
}

export default About
