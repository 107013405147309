import React, { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { namespaceStyle } from '../../../utils'
import { ServerNamespace } from '../../../utils/trpc'
import { LoadingTile, PhotoTile } from '../Tile/Tile'
import { TilesContainer } from '../TilesContainer/TilesContainer'
import { UnsplashPhoto } from '../unsplash.model'
import { unsplashService } from '../unsplash.service'
import styles from './UnsplashPhotoFinder.module.scss'

interface Props {
  onSelect: (photo: UnsplashPhoto) => void
  namespace: ServerNamespace
}

const NUMBER_OF_PHOTOS_TO_SHOW = 9
const UNSPLASH_SEARCH_DEBOUNCE_TIME = 1000
const UNSPLASH_PHOTO_ORIENTATION = 'landscape'
const UNSPLASH_UNEXPECTED_ERROR_MESSAGE = 'Some Unexpected error has occurred'

const UnsplashPhotoFinder: React.FC<Props> = ({ onSelect, namespace }) => {
  const [query, setQuery] = React.useState('')
  const [photos, setPhotos] = React.useState<UnsplashPhoto[]>()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState<number>(0)

  const searchPhotos = useDebouncedCallback((query) => {
    unsplashService
      .searchPhotos(query, UNSPLASH_PHOTO_ORIENTATION, currentPage)
      .then((response) => {
        setTotalPages(response.total_pages)
        setPhotos(response.results)
      })
      .catch((response) => {
        setError(response?.errors[0] || UNSPLASH_UNEXPECTED_ERROR_MESSAGE)
      })
      .finally(() => {
        setLoading(false)
      })
  }, UNSPLASH_SEARCH_DEBOUNCE_TIME)

  const fetchRandomPhotos = useCallback(() => {
    unsplashService
      .randomPhotos(UNSPLASH_PHOTO_ORIENTATION, NUMBER_OF_PHOTOS_TO_SHOW)
      .then((response) => {
        setPhotos(response)
      })
      .catch((response) => {
        setError(response?.errors[0] || UNSPLASH_UNEXPECTED_ERROR_MESSAGE)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const hasFirst = currentPage > 1
  const hasLast = currentPage < totalPages

  const visibleFirst = currentPage - 2 !== 0 && currentPage - 2 !== 2 && currentPage - 1 !== 2 // for the right representation of suspension points and pagination generally
  const visibleLast = // --//--
    currentPage + 3 !== totalPages &&
    currentPage !== totalPages &&
    currentPage + 2 !== totalPages &&
    currentPage + 1 !== totalPages

  const prevPage = () => {
    setCurrentPage((page) => page - 1)
  }

  const nextPage = () => {
    setCurrentPage((page) => page + 1)
  }

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  React.useEffect(() => {
    setLoading(true)
    setError('')

    if (query.length > 0) {
      searchPhotos(query)
    } else {
      setTotalPages(0)
      fetchRandomPhotos()
    }
  }, [query, searchPhotos, fetchRandomPhotos, currentPage])

  return (
    <div style={namespaceStyle(namespace)} className={styles.container}>
      <input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className={styles.input}
        placeholder="Search Unsplash..."
      />

      <div>
        {error && <div className={styles.error}>{error}</div>}
        {loading && (
          <TilesContainer>
            {[...Array(NUMBER_OF_PHOTOS_TO_SHOW)].map((_, i) => (
              <LoadingTile key={i} />
            ))}
          </TilesContainer>
        )}
        {!loading && photos != null && (
          <>
            {photos.length > 0 && (
              <TilesContainer>
                {photos.map((photo) => (
                  <PhotoTile onSelect={onSelect} photo={photo} key={photo.id} />
                ))}
              </TilesContainer>
            )}
            {photos.length <= 0 && (
              <span>No photos found with that query, try finding something else.</span>
            )}
          </>
        )}
      </div>
      {totalPages !== 0 && (
        <div className={styles.pagination}>
          <div className={styles.paginationLeft}>
            <button onClick={prevPage} style={!hasFirst ? { visibility: 'hidden' } : undefined}>
              Prev
            </button>
          </div>

          <div className={styles.paginationMid}>
            {hasFirst && visibleFirst && <button onClick={() => changePage(1)}>1</button>}
            {hasFirst && visibleFirst && <span>...</span>}

            {[-2, -1, 0, 1, 2].map((page) => {
              const displayPage = currentPage + page
              if (displayPage > 0 && displayPage <= totalPages) {
                const className =
                  currentPage === displayPage ? styles.currentColor : styles.defaultColor
                return (
                  <button
                    key={displayPage}
                    className={className}
                    onClick={() => changePage(displayPage)}
                  >
                    {displayPage}
                  </button>
                )
              } else {
                return null
              }
            })}

            {hasLast && visibleLast && <span>...</span>}
            {hasLast && visibleLast && (
              <button onClick={(e) => changePage(totalPages)}>{totalPages}</button>
            )}
          </div>

          <div className={styles.paginationRight}>
            <button
              style={!hasLast ? { visibility: 'hidden' } : undefined}
              onClick={() => nextPage()}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {totalPages === 0 && (
        <div className={styles.randomContainer}>
          <span className={styles.randomContainerButton} onClick={fetchRandomPhotos}>
            random
          </span>
        </div>
      )}
    </div>
  )
}

export default UnsplashPhotoFinder
