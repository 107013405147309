import axios from 'axios'
import { UnsplashPhotoResponse, PhotoOrientation } from './unsplash.model'

class UnsplashService {
  BASE_URL = 'https://api.unsplash.com'
  UNSPLASH_API_KEY = process.env.REACT_APP_UNSPLASH_API_KEY

  searchPhotos = async (
    query: string,
    orientation: PhotoOrientation = 'landscape',
    page?: number
  ): Promise<UnsplashPhotoResponse> => {
    return this.get<UnsplashPhotoResponse>('/search/photos', {
      query,
      orientation,
      per_page: 9,
      page,
    })
  }

  randomPhotos = async (orientation: PhotoOrientation, count: number) => {
    return this.get('/photos/random', { orientation, count })
  }

  private async get<T = any>(path: string, params?: any) {
    return await axios
      .get<T>(`${this.BASE_URL}${path}`, {
        headers: {
          Authorization: `Client-ID ${this.UNSPLASH_API_KEY}`,
        },
        params,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error.response.data
      })
  }
}

export const unsplashService = new UnsplashService()
