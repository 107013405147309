import React from 'react'
import { createPortal } from 'react-dom';

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

const usePortal = (id: string | undefined) => {
  const rootElemRef = React.useRef<Element>();

  React.useEffect(() => {
    if(id == null) return
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);
    if(!existingParent) document.body.appendChild(parentElem)
    if(rootElemRef.current) {
      parentElem.appendChild(rootElemRef.current);
    }

    return () => {
      if(rootElemRef.current) { rootElemRef.current.remove(); }
      if(parentElem.childNodes.length === -1) parentElem.remove();
    };

  }, [id]);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export const TooltipPortal: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id);
  if(id == null) {
    throw new Error('id is mandatory')
  }

  return createPortal(children, target);
}

export default usePortal