import React from 'react'
import { Tooltip } from "../../../../../components/Tooltip";
import clsx from 'clsx'
import useFitText from 'use-fit-text'
import styles from './SlideTitleInput.module.scss'
import { TrashIcon } from '../../../../../currentColorIcons'

interface Props extends React.HTMLProps<HTMLDivElement> {
  defaultValue: string
  onUpdate?: (text: string) => void,
  onDelete?: () => void,
}

export const SlideTitleInput: React.FC<Props> = ({
  defaultValue,
  onUpdate,
  onDelete,
  placeholder,
  className,
}) => {
  const { fontSize, ref } = useFitText({})
  const [text, setText] = React.useState(defaultValue || '')

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
    onUpdate?.(e.target.value)
  }

  return (
    <div className={clsx(styles.container, className)}>
      {onDelete && <Tooltip label={"Delete text"}>
        <button className={clsx(styles.button, styles.trash)} onClick={onDelete}>
          <TrashIcon />
        </button>
      </Tooltip>}
      <textarea
        ref={ref as any}
        disabled={!onUpdate }
        style={{ fontSize }}
        tabIndex={0}
        onChange={onUpdate ? handleOnChange : undefined}
        value={text}
        className={styles.textarea}
        placeholder={placeholder}
      />
    </div>
  )
}
