import { trpc } from "../../utils/trpc"

export const useUpdateNamespaceTrpc = () => {
  const utils = trpc.useContext()
  return trpc.namespace.update.useMutation({
    onMutate: ({id, data: updatingNamespace}) => {
      utils.namespace.id.setData({id}, (n) => Object.assign({}, n, updatingNamespace))
      utils.namespace.publicId.setData({id}, (n) => Object.assign({}, n, updatingNamespace))
    },
    onSuccess: () => {
      utils.namespace.all.invalidate()
    },
  })
}


