import React from 'react'
import { LinkedinAuthButton } from '../../../../DesignListeningPortal/components/shared/LinkedinAuthButton/LinkedinAuthButton'
import { slidesSession } from '../../../../models/services/session-storage.service'
import { ServerFeedback, ServerFeedbackCreate, ServerListeningPost } from '../../../../utils/trpc'
import { Spinner2 } from '../../../loading/loading'
import { useSlidesDisplayContext } from '../../state/slides-display.context'
import styles from './LinkedinSlide.module.scss'

const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID
const LINKEDIN_SCOPES = encodeURI('r_liteprofile r_emailaddress')

interface Props {
  listeningPost: ServerListeningPost
  isLoading?: boolean
  feedbackId?: string
  feedback?: ServerFeedback

  onAuth: (data: ServerFeedbackCreate, feedbackId?: string) => void
}

const LinkedinSlide: React.FC<Props> = ({ listeningPost, onAuth, isLoading, feedback }) => {
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const {
    state: { activeIndex },
    dispatch,
  } = useSlidesDisplayContext()

  const redirectUri = React.useMemo(() => {
    const url = new URL(window.location.href)
    const redirectUri = url.protocol + '//' + url.host + '/linkedin'
    return redirectUri
  }, [])

  React.useEffect(() => {
    const authParams = slidesSession.getParsedItem('LINKEDIN_AUTH_PARAMS')
    const authResp = slidesSession.getParsedItem('LINKEDIN_AUTH_RESPONSE')

    if (!authParams || !authResp) return

    dispatch({
      type: 'SET_ACTIVE_INDEX',
      payload: authParams.lastActiveIndex || 0,
    })

    if (authResp.errorDescription) {
      setErrorMessage(authResp.errorDescription)
      return
    }

    if (authResp.code == null) {
      setErrorMessage('Some required parameters are missing, please try again.')
      return
    }

    if (authResp.state !== authParams.listeningPostId) {
      setErrorMessage("Some required parameters don't match, please try again.")
      return
    }
    onAuth({ kind: 'linkedin', code: authResp.code, answerObj: redirectUri }, authParams.feedbackId)

    slidesSession.removeItem('LINKEDIN_AUTH_PARAMS')
    slidesSession.removeItem('LINKEDIN_AUTH_RESPONSE')
  }, [onAuth, redirectUri, dispatch])

  const listeningPostId = listeningPost.id
  const feedbackId = feedback?.id

  const onClickHandler = React.useCallback(
    (e) => {
      e.stopPropagation()
      setErrorMessage(undefined)
      console.log('onClickHandler', listeningPostId, feedbackId)
      slidesSession.setStringyItem('LINKEDIN_AUTH_PARAMS', {
        listeningPostId,
        feedbackId,
        lastActiveIndex: activeIndex,
      })

      const state = listeningPostId
      const linkedinHref = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${LINKEDIN_SCOPES}`
      window.location.href = linkedinHref
    },
    [listeningPostId, activeIndex, feedbackId, redirectUri]
  )
  const feedbackLinkedin = feedback?.linkedin

  return (
    <div>
      <LinkedinAuthButton onClick={onClickHandler}>
        {feedbackLinkedin?.firstName && feedbackLinkedin?.lastName
          ? `Logged in as ${feedbackLinkedin?.lastName} ${feedbackLinkedin?.firstName}`
          : 'Login'}
        {feedbackLinkedin?.profilePicture && (
          <img src={feedbackLinkedin?.profilePicture} className={styles.image} alt="profile" />
        )}
        {isLoading && <Spinner2 color="white" />}
      </LinkedinAuthButton>
      <span className={styles.error}>{errorMessage}</span>
    </div>
  )
}

export default LinkedinSlide
