import clsx from 'clsx'
import React from 'react'
import { Tooltip } from '../../../components/Tooltip'
import { TrashIcon } from '../../../currentColorIcons'
import { ServerListeningPostSlideUpdate } from '../../../utils/trpc'
import styles from './CTAInput.module.scss'

interface CTAInputProps {
  defaultCtaText?: string
  defaultCtaLink?: string
  onDelete: () => void
  onUpdate: (data: ServerListeningPostSlideUpdate) => void
}
export const CTAInput: React.FC<CTAInputProps> = ({
  defaultCtaLink,
  defaultCtaText,
  onDelete,
  onUpdate,
}) => {
  const [ctaLink, setCtaLink] = React.useState(defaultCtaLink)
  const [ctaText, setCtaText] = React.useState(defaultCtaText)

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ctaText = e.target.value
    setCtaText(ctaText)
    onUpdate({ ctaText })
  }
  const onChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ctaLink = e.target.value
    setCtaLink(ctaLink)
    onUpdate({ ctaLink })
  }

  return (
    <div className={styles.buttons}>
      <Tooltip label={'Delete Call to Action'}>
        <button className={clsx(styles.button, styles.trash)} onClick={onDelete}>
          <TrashIcon />
        </button>
      </Tooltip>

      <label className={styles.ctaButton}>
        <input value={ctaText} onChange={onChangeText} placeholder="CTA button text" />
      </label>
      <span className={styles.arrow}>&rarr;</span>
      <label className={styles.ctaLink}>
        <input value={ctaLink} onChange={onChangeLink} placeholder="http link" />
      </label>
    </div>
  )
}
