import React from 'react'
import { useSearch } from '../../hooks/useSearch'
import { Delete } from '../../toneIcons'
import { ServerTag } from '../../utils/trpc'
import styles from './feedbackSelectedTags.module.scss'

interface TagProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  name: string
}

const Tag: React.FC<TagProps> = ({ onClick, name }) => {
  return (
    <span className={styles.tag} onClick={onClick}>
      <span>{name}</span>
      <Delete />
    </span>
  )
}

interface FeedbackSelectedTagsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  tags?: ServerTag[]
}

export const FeedbackSelectedTags: React.FC<FeedbackSelectedTagsProps> = ({ tags }) => {
  const [search, setSearch] = useSearch()
  const removeSelectedTag = (tagId: string) => {
    setSearch((s) => ({
      ...s,
      untagged: undefined,
      // tags: without(s.tags, tagId),
      tags: (s.tags === tagId) ? undefined : tagId,
    }))
  }

  const removeUntagged = () => {
    setSearch((s) => ({
      ...s,
      untagged: undefined,
    }))
  }

  if ((tags?.length ?? 0) <= 0 && !search.untagged) return null

  return (
    <div className={styles.container}>
      {tags?.map((tag) => {
        return <Tag onClick={() => removeSelectedTag(tag.id)} name={tag.name} key={tag.id} />
      })}

      {search.untagged && <Tag onClick={() => removeUntagged()} name="Untagged" />}
    </div>
  )
}

export default FeedbackSelectedTags
