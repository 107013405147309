import clsx from 'clsx'
import React from 'react'
import styles from './CellHeader.module.scss'

interface CellHeaderProps extends React.HtmlHTMLAttributes<HTMLOrSVGElement> {
  sticky?: boolean
}

export const CellHeader: React.FC<CellHeaderProps> = ({sticky, className, children}) => {
  const classList = clsx(
    styles.header,
    sticky ? styles.sticky : undefined,
    className,
  )
  return <div className={classList}>{children}</div>
}