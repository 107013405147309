import clsx from 'clsx'
import React, { CSSProperties } from 'react'
import { TrashIcon } from '../../currentColorIcons'
import { useSearch } from '../../hooks/useSearch'
import { SmileBox } from '../../toneIcons'
import { useCreateTag } from '../../trpcHooks/tags/useCreateTag'
import { useDeleteTag } from '../../trpcHooks/tags/useDeleteTag'
import { scoreToFeedbackColor, sentimentWords } from '../../utils'
import { trpc } from '../../utils/trpc'
import { Scrollable } from '../Scrollable/Scrollable'
import styles from './Filters.module.scss'

interface FiltersProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespaceId: string
}

export const Filters: React.FC<FiltersProps> = ({ namespaceId }) => {
  const [search, setSearch] = useSearch()
  const SENTIMENTS = 5
  const sentimentCounts = Array.from({ length: SENTIMENTS }, (_, i) => SENTIMENTS - i)
  const tagsQuery = trpc.tags.all.useQuery({ namespaceId })
  const tagCreateMutaiton = useCreateTag()
  const tagDeleteMutaiton = useDeleteTag()

  const setSentiment = (sentiment: number) => {
    setSearch((s) => ({
      ...s,
      sentiment: s.sentiment === sentiment * 2 ? undefined : sentiment * 2,
    }))
  }

  const toggleUntaggedFeedbacks = () => {
    setSearch((s) => {
      if (s.untagged) {
        return { ...s, untagged: undefined }
      } else {
        return { ...s, untagged: true, tags: undefined }
      }
    })
  }

  const toggleSelectedTag = (tagId: string) => {
    setSearch((s) => ({
      ...s,
      untagged: undefined,
      tags: s.tags === tagId ? undefined : tagId,
      // tags: s.tags?.includes(tagId) ? without(s.tags, tagId) : [...(s.tags || []), tagId],
    }))
  }

  const addTag = () => {
    if(search.query != null && (search.query?.length ?? 0) > 0) {
      tagCreateMutaiton.mutateAsync({namespaceId, data: {name: search.query}})
    }
  }

  const deleteTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tagId: string) => {
    e.stopPropagation()
    e.preventDefault()

    tagDeleteMutaiton.mutateAsync({namespaceId, tagId}) 
  }

  const isQueryAlreadyATag = tagsQuery.data?.tags.find(t => t.name === search.query)

  return (
    <div className={styles.container}>
      <div className={styles.header}>Filters</div>

      <Scrollable className={styles.filtersScroll}>
        <div className={styles.content}>
          {sentimentCounts?.map((count) => {
            const sentiment = (count - 1) / Math.floor(SENTIMENTS / 2) - 1
            const color = scoreToFeedbackColor(sentiment)
            const style = { '--color': color } as CSSProperties

            const isSelected = search.sentiment === sentiment * 2
            const classList = clsx(styles.row, isSelected ? styles.selected : undefined)
            return (
              <div
                key={count}
                style={style}
                className={classList}
                onClick={() => setSentiment(sentiment)}
              >
                <div className={styles.icon}>
                  <SmileBox score={sentiment} />
                </div>
                <div className={styles.name}>{sentimentWords[count]}</div>
              </div>
            )
          })}

          <div className={styles.section}>
            <div className={styles.sectionHeader}>COMMON TAGS</div>

            <div className={styles.tags}>
              {search.query && !isQueryAlreadyATag && <span className={clsx(styles.tag, styles.add)} onClick={addTag}>
                <span>+ {search.query}</span>
              </span>}
              {tagsQuery.data?.tags?.map((tag) => {
                return (
                  <div
                    className={clsx(
                      styles.tag,
                      search.tags?.includes(tag.id) ? styles.selected : undefined
                    )}
                    key={tag.id}
                    onClick={() => toggleSelectedTag(tag.id)}
                  >
                    <span>{tag.name}</span>
                    
                    <div className={styles.trashIcon} onClick={(e) => deleteTag(e, tag.id)}>
                      <TrashIcon />
                    </div>
                  </div>
                )
              })}
              <span
                className={clsx(styles.tag, search.untagged ? styles.selected : undefined)}
                onClick={toggleUntaggedFeedbacks}
              >
                <span>Untagged</span>
              </span>
            </div>
          </div>
        </div>
      </Scrollable>
    </div>
  )
}

export default Filters
