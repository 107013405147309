import React from 'react'
import {Helmet} from "react-helmet";
import {getMetaTags} from '../utils'

interface PageMetaProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: any,
  listeningPost?: any
}

export const PageMeta: React.FC<PageMetaProps> = ({namespace, listeningPost}) => {
  const { title, description, titleWithShortDescription, ogSquare, ogImage } = getMetaTags(namespace, listeningPost);

  return <Helmet>
    <title>{title}</title>

    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary" />

    <meta property="og:image:alt" content={titleWithShortDescription} />
    <meta property="og:title" content={titleWithShortDescription} />
    <meta name="twitter:title" content={titleWithShortDescription} />

    <meta name="twitter:description" content={description} />
    <meta property="og:description" content={description} />

    <meta name="twitter:image" content={ogSquare} />
    <meta property="og:image" content={ogImage} />
  </Helmet>
}

export default PageMeta