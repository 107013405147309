import { debounce } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import Popup from '../../../../../components/popup/popup'
import { XmarkThin } from '../../../../../currentColorIcons'
import { createBaseUrl, getMetaTags, namespaceStyle } from '../../../../../utils'
import { ServerListeningPost, ServerPublicNamespace, trpc } from '../../../../../utils/trpc'
import { useUpdateListeningPostTrpc } from '../../../../../trpcHooks/listeningPost/useUpdateListeningPostTrpc'
import Card from '../Card/Card'
import CardV2 from '../CardV2/CardV2'
import styles from './OgPopup.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  onClose: () => void
  namespace: ServerPublicNamespace
  listeningPost: ServerListeningPost
}

export interface FormData {
  description: string
  title: string
  url: string
  ogImage?: string
  ogSquare?: string
}

const OgPopup: React.FC<Props> = ({ onClose, namespace, listeningPost }) => {
  const namespaceId = namespace.id
  const listeningPostId = listeningPost.id
  const listeningPostUpdateMutation = useUpdateListeningPostTrpc()
  const configQuery = trpc.config.info.useQuery()

  const portalDefaultURLPath = createBaseUrl(configQuery.data, namespace.id, '/')
  const metaTags = getMetaTags(namespace, listeningPost)

  const handelMakeImageWithText = (slideId: string) => {
    listeningPostUpdateMutation.mutate({
      namespaceId,
      id: listeningPostId,
      data: { ogSlideId: slideId },
    })
  }

  const setValueCallback = async (data: any) => {
    if (data.url === '') {
      data.url = null
    }
    if (data.publicName === '') {
      data.publicName = null
    }
    if (data.name === '') {
      data.name = null
    }
    if (data.shortDescription === '') {
      data.shortDescription = null
    }
    if (data.description === '') {
      data.description = null
    }

    await listeningPostUpdateMutation.mutateAsync({ namespaceId, id: listeningPostId, data })
  }

  const listeningPostUrl = namespace.namespaceIdToPublicId?.[listeningPost.id]

  const { watch, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: metaTags.description,
      ogImage: metaTags.ogImage,
      title: metaTags.title,
      ogSquare: metaTags.ogSquare,
      url: listeningPostUrl,
      shortDescription: metaTags.shortDescription,
    },
  })

  const verify = debounce((value, name) => {
    setValueCallback({ [name]: value[name] })
  }, 200)

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        verify(value, name)
      }

      return true
    })
    return () => subscription.unsubscribe()
  }, [watch, verify])

  return (
    <Popup
      onClose={onClose}
      popupContentClassName={styles.container}
      style={namespaceStyle(listeningPost)}
      popupContentSizeClassname={styles.popupClassName}
    >
      <div className={styles.navigationBlock}>
        <button onClick={onClose} className={styles.xmark}>
          <XmarkThin />
        </button>
      </div>
      <div className={styles.functionalityBlock}>
        <div className={styles.column}>
          <div className={styles.header}>Adjust Social media Preview Image (Large)</div>
          <Card
            namespace={namespace}
            listeningPost={listeningPost}
            isPreview={false}
            getValues={getValues}
            setValue={setValue}
            defaultURL={portalDefaultURLPath}
            handelMakeImageWithText={(slideId) => handelMakeImageWithText(slideId)}
          />
        </div>

        <div className={styles.column}>
          <div className={styles.header}>Twitter, Slack, and sites with smaller previews</div>
          <div className={styles.previews}>
            <CardV2
              defaultURL={portalDefaultURLPath}
              listeningPost={listeningPost}
              className={styles.containerPreviewCard}
              listeningPostUrl={listeningPostUrl}
              shortDescription={metaTags.shortDescription}
              description={metaTags.description}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default OgPopup
