import clsx from 'clsx'
import React from 'react'
import { createPortal } from 'react-dom'
import { useKey } from '../../hooks/useKey'
import usePortal from '../../hooks/usePortal'
import styles from './popup.module.scss'

interface PopupProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  popupClassName?: string
  popupContentClassName?: string
  popupContentSizeClassname?: string
  icon?: any
  onClose?: () => void
}

export const Popup: React.FC<PopupProps> = ({
  popupClassName,
  popupContentClassName,
  popupContentSizeClassname,
  onClose,
  icon,
  onSubmit,
  children,
  style,
}) => {
  React.useEffect(() => {
    document.body.classList.add('popupShown')
    return () => {
      document.body.classList.remove('popupShown')
    }
  }, [])
  useKey(['Escape'], () => onClose?.())

  const Tag = onSubmit != null ? 'form' : 'div'
  const props: any = {}
  if (onSubmit) {
    props.onSubmit = onSubmit
    props.autoComplete = 'off'
  }

  return (
    <PopupPortal>
      <div className={clsx(styles.container, popupClassName)} style={style}>
        <div className={styles.background} onClick={onClose} />
        <Tag className={clsx(styles.content, popupContentSizeClassname)} {...props}>
          {icon != null && <div className={styles.circle}>{icon}</div>}
          <div className={clsx(styles.contentBody, popupContentClassName)}>{children}</div>
        </Tag>
      </div>
    </PopupPortal>
  )
}

export default Popup

interface PopupPortalProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}
export const PopupPortal: React.FC<PopupPortalProps> = ({ id, children }) => {
  const target = usePortal(id || 'modal-portal')
  return createPortal(children, target)
}
