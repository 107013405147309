import { TinyColor } from '@ctrl/tinycolor';
import clsx from 'clsx'
import React from 'react';
import { Link } from "react-router-dom";
import { CircleDelete, Pencil, Plus } from '../../toneIcons';
import { isDark } from '../../utils';
import { Tooltip } from '../Tooltip';
import styles from './pills.module.css';
interface PillProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
}

interface ColorPillProps extends PillProps {
  color: string
}

export const ColorPill: React.FC<ColorPillProps> = ({color, onClick}) => {
  if(color == null) return null;

  let textColor = '#333'
  try {
    textColor = isDark(new TinyColor(color)) ? '#fefefe' : '#333';
  } catch(error) {}

  return <div className={clsx(styles.container, onClick ? styles.clickable : null)} style={{'--color': color, minWidth:'80px'} as any} onClick={onClick}>
    <div className={styles.colorBox} style={{'--tone1': textColor, '--tone2': textColor} as any}>
      {onClick && <Pencil />}
    </div>
    <div className={styles.text}>{color.toLowerCase()}</div>
  </div>
}

interface EditPillProps extends PillProps {
  text?: string
  tagName?: string
}

export const EditPill: React.FC<EditPillProps> = ({text="edit", tagName, onClick, ...props}) => {
  const Tag: any = tagName || 'button';
  return <Tag className={styles.createContainer} disabled={onClick == null} {...props} onClick={onClick}>
    <div className={styles.box}><Pencil /></div>
    <div className={styles.text}>{text}</div>
  </Tag>
}

export const CreatePill: React.FC<EditPillProps> = ({text="create", tagName, onClick, ...props}) => {
  const Tag: any = tagName || 'button';
  return <Tag className={styles.createContainer} disabled={onClick == null} {...props} onClick={onClick}>
    <div className={styles.box}><Plus /></div>
    <div className={styles.text}>{text}</div>
  </Tag>
}

interface DeletablePillProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  text?: string
  onDelete?: () => void
}

export const DeletablePill = React.forwardRef<HTMLDivElement, DeletablePillProps>(({text, onDelete}, ref) => {
  if(text == null) return null;
  return <div className={styles.container} ref={ref}>
    <div className={styles.text}>{text}</div>
    {onDelete && <div onClick={onDelete} className={styles.delete}><CircleDelete /></div>}
  </div>
})

interface PillProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  icon?: any,
  text?: string,
  tagName?: string | any,
  target?: any
  href?: any,
  withTooltip?: boolean,
  to?: any
}

export const Pill: React.FC<PillProps> = ({icon, text, tagName, withTooltip=false, ...props}) => {
  const Tag: any = tagName || 'div';
  
  const clickable = props.onClick != null || tagName === 'a' || Tag === Link;
  const className = clsx(
    styles.pill,
    clickable ? styles.clickable : null
  )
  
  if(withTooltip) {
    return <Tooltip placement="top" label={text}>
      <Tag className={className} {...props}>{icon}</Tag>
    </Tooltip>
  } else {
    return <Tag className={className} {...props}>{icon}<span>{text}</span></Tag>
  }
}