import clsx from 'clsx'
import React from 'react'
import { DeleteIcon } from '../../currentColorIcons'
import { Tooltip } from '../Tooltip'
import styles from './InputFile.module.scss'

interface InputProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
  name: string
  value?: any
  floatTitle?: boolean
  disabled?: boolean
  type?: string
  showDelete?: any
  autoFocus?: any
  error?: any
}

interface FileInputProps extends InputProps {
  multiple?: any
  allowedFileTypes?: any
}

export const InputFile = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      className,
      placeholder,
      title,
      name,
      value,
      onChange,
      showDelete,
      autoFocus,
      error,
      allowedFileTypes,
      disabled,
      multiple,
    },
    ref
  ) => {
    const dragCounterRef = React.useRef(0)
    const [isDraggingOver, setIsDraggingOver] = React.useState<boolean>()
    const onDelete = showDelete
      ? (e: any) => {
          e.stopPropagation()
          e.preventDefault()
          const event = { target: { name, value: null, type: 'file', files: [null] } } as any
          onChange?.(event)
        }
      : undefined

    const fileInputKey = value ? value.name + new Date() : undefined
    const isString = typeof value === 'string'
    const isFile = value instanceof File
    const isAllowedFile =
      isFile && (allowedFileTypes == null || allowedFileTypes.indexOf(value.type) >= 0)
    const isImage = value?.type?.startsWith('image/')

    const TagName = disabled ? Tooltip : 'div'
    const extraProps: any = {}

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      dragCounterRef.current += 1
      if (e.dataTransfer?.items?.length > 0) {
        setIsDraggingOver(true)
      }
    }
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      dragCounterRef.current -= 1
      if (dragCounterRef.current > 0) return
      setIsDraggingOver(false)
    }
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setIsDraggingOver(false)

      if (e.dataTransfer?.files?.length > 0) {
        const files = e.dataTransfer?.files
        const event = { target: { name, value: null, type: 'file', files } } as any
        onChange?.(event)
      } else if (e.dataTransfer?.items?.length > 0) {
        const value = e.dataTransfer?.getData?.('text/html')?.match?.(/src\s*=\s*"(.+?)"/)?.[1]
        const event = { target: { name, value, type: 'string' } } as any
        onChange?.(event)
      }

      e.dataTransfer.clearData()
      dragCounterRef.current = 0
    }

    const classList = clsx(
      styles.container,
      error ? styles.error : null,
      disabled ? styles.disabled : null,
      isDraggingOver ? styles.draggingOver : null,
      className
    )

    return (
      <TagName
        className={classList}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        {...extraProps}
      >
        <div className={styles.title}>
          <span>{title}</span>
          {error && <span className={styles.errorMessage}>{error}</span>}
        </div>
        <label className={clsx(styles.label, showDelete ? styles.hasDelete : null)}>
          <input
            type="file"
            onChange={(e: any) => onChange?.(e.target.files?.[0])}
            ref={ref}
            name={name}
            autoFocus={autoFocus}
            className={styles.input}
            key={fileInputKey}
            disabled={disabled}
            multiple={multiple}
          />

          {isString && (
            <span className={styles.input}>
              <img alt={title} src={value} className={styles.imgFile} />
            </span>
          )}
          {isAllowedFile && isImage && (
            <span className={styles.input}>
              <img alt="to upload" src={URL.createObjectURL(value)} className={styles.imgFile} />
            </span>
          )}
          {isAllowedFile && !isImage && <span className={styles.input}>File ({value.type})</span>}
          {value != null && !isString && !isAllowedFile && (
            <Tooltip label={`${value.name} is of type ${value.type || 'unknown'}`}>
              <div className={styles.input}>
                <strong>Invalid file type</strong>, must be {allowedFileTypes.join(' ')}
              </div>
            </Tooltip>
          )}
          {value == null && (
            <span className={clsx(styles.input, styles.placeholder)}>{placeholder}</span>
          )}
          {showDelete && value != null && (
            <Tooltip label="REMOVE">
              <div className={styles.delete} onClick={onDelete}>
                <DeleteIcon />
              </div>
            </Tooltip>
          )}
        </label>
      </TagName>
    )
  }
)
