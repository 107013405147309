import clsx from 'clsx'
import { groupBy, mapValues, sortBy } from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { AddIcon } from '../../../../currentColorIcons'
import { useSearch } from '../../../../hooks/useSearch'
import { useAddSlideTrpc } from '../../../../trpcHooks/listeningPost/useAddSlideTrpc'
import { useReorderSlidesTrpc } from '../../../../trpcHooks/listeningPost/useReorderSlidesTrpc'
import { isNotNull } from '../../../../utils'
import {
  ServerListeningPost,
  ServerListeningPostSlide,
  ServerPublicNamespace
} from '../../../../utils/trpc'
import { NavItemLink, NavItemV2 } from '../NavItem/NavItem'
import { NavSectionTitle } from '../NavSectionTitle/NavSectionTitle'
import styles from './LeftNav.module.scss'
import OgButton from './OgButton/OgButton'
import OgPopup from './OgPopup/OgPopup'

interface Props extends React.HTMLProps<HTMLDivElement> {
  listeningPost: ServerListeningPost
  namespace: ServerPublicNamespace
}

const LeftNav: React.FC<Props> = ({ listeningPost, className, namespace }) => {
  const listeningPostId = listeningPost.id
  const namespaceId = namespace.id
  const [search, setSearch] = useSearch()
  const addSlideMutation = useAddSlideTrpc()
  const { mutateAsync: reorderSlidesMutation } = useReorderSlidesTrpc()
  const selectedListeningPostId = search.ogEdit

  const handleOnAddSlide = (sectionId: string) => {
    addSlideMutation.mutate({ namespaceId, listeningPostId, sectionId })
  }
  const handleOnChangeSlideOrder = React.useCallback(
    (slideOrders: Record<string, string[]>) => {
      reorderSlidesMutation({ namespaceId, listeningPostId, slideOrders })
    },
    [reorderSlidesMutation, namespaceId, listeningPostId]
  )

  const allSlides = listeningPost?.slides

  const sectionSlides = React.useMemo(() => {
    return mapValues(
      groupBy(allSlides, (s) => s.section),
      (slides) => sortBy(slides, 'order')
    )
  }, [allSlides])

  const handleOnDragEnd = React.useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }

      const sectionSlideIds = mapValues(sectionSlides, (slides) => slides.map((s) => s.id))
      const [reorderedId] =
        sectionSlideIds[result.source.droppableId]?.splice(result.source.index, 1) || []
      if (reorderedId) {
        if (sectionSlideIds[result.destination.droppableId] == null) {
          sectionSlideIds[result.destination.droppableId] = []
        }
        sectionSlideIds[result.destination.droppableId]?.splice(
          result.destination.index,
          0,
          reorderedId
        )
      }

      handleOnChangeSlideOrder(sectionSlideIds)
    },
    [sectionSlides, handleOnChangeSlideOrder]
  )

  const onClose = () => {
    setSearch((s) => ({ ...s, ogEdit: undefined }))
  }

  const onOpen = () => {
    setSearch((s) => ({ ...s, ogEdit: true }))
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className={clsx(styles.container, className)}>
        <div className={styles.ogMenuContainer}>
          <OgButton onOpen={onOpen} namespace={namespace} listeningPost={listeningPost} />

          {selectedListeningPostId && listeningPost && (
            <OgPopup namespace={namespace} listeningPost={listeningPost} onClose={onClose} />
          )}
        </div>
        {listeningPost.sections?.map((section) => {
          const slideIds = listeningPost.groupedSlideIds?.[section] || []
          const slides = slideIds.map((id) => listeningPost.slides?.[id]).filter(isNotNull)
          return (
            <Section
              sectionId={section}
              slides={slides}
              onAddSlide={handleOnAddSlide}
              key={section}
            />
          )
        })}
      </div>
    </DragDropContext>
  )
}

interface SectionProps {
  slides: ServerListeningPostSlide[]
  sectionId: string
  onAddSlide: (sectionId: string) => void
}
const Section: React.FC<SectionProps> = ({ slides, sectionId, onAddSlide }) => {
  return (
    <div className={styles.section}>
      <Droppable droppableId={sectionId}>
        {(provided) => (
          <div
            className={styles.sectionContent}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <NavSectionTitle sectionId={sectionId} />
            {slides.map((slide, i) => (
              <Draggable draggableId={slide.id} index={i} key={slide.id}>
                {(provided) => {
                  return (
                    <NavItemV2
                      className={styles.navItem}
                      slide={slide}
                      index={i + 1}
                      key={slide.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    />
                  )
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <NavItemLink
        className={styles.navItemLink}
        onClick={() => onAddSlide(sectionId)}
        icon={<AddIcon />}
      >
        add slide
      </NavItemLink>
    </div>
  )
}

export default LeftNav
