import { Popover } from '@mui/material'
import React from 'react'
import {
  ServerListeningPostSlideBackground,
  ServerListeningPostSlideBackgroundType,
  ServerNamespace,
} from '../../utils/trpc'
import BackgroundPicker from './BackgroundPicker/BackgroundPicker'

interface Props {
  backgroundType?: ServerListeningPostSlideBackgroundType
  namespace: ServerNamespace
  onSelect: (data: ServerListeningPostSlideBackground) => void
  onDelete?: () => void
  children: (handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void) => JSX.Element
}

const BackgroundPickerTrigger: React.FC<Props> = ({
  children,
  namespace,
  onSelect,
  onDelete,
  backgroundType,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        componentsProps={{
          backdrop: {
            invisible: false,
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <BackgroundPicker
          namespace={namespace}
          backgroundType={backgroundType}
          onSelect={(bg) => {
            onSelect(bg)
            handleClose()
          }}
          onDelete={
            onDelete
              ? () => {
                  onDelete()
                  handleClose()
                }
              : undefined
          }
        />
      </Popover>

      {children(handleClick)}
    </React.Fragment>
  )
}

export default BackgroundPickerTrigger
