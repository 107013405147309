import React from 'react'
import { HarmonizeLogo } from '../../toneIcons'
import { BeatingHeart } from '../BeatingHeart/BeatingHeart'
import styles from './Home.module.scss'

interface HomeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const Home: React.FC<HomeProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.spacer} />

        <HarmonizeLogo className={styles.harmonizeLogo} />
        <h1>ListensIn</h1>
        <h2>by HarmonizeAi</h2>

        <p>
          A <strong>Privacy Forward</strong>, <strong>No-Code AI</strong> portal for engaging and
          understanding your most cherished customers, and the customers that you <em>wish</em> you
          had.
        </p>

        <div className={styles.spacer} />

        <div className={styles.bottomFooter}>
          Made with
          <BeatingHeart className={styles.heartBeating} />
          by a fearless remote team.
        </div>
      </div>
    </div>
  )
}

export default Home
