import clsx from 'clsx'
import { HTMLProps } from 'react'
import { getOgSlide } from '../../../../../utils'
import { ServerListeningPost } from '../../../../../utils/trpc'
import styles from './CardV2.module.scss'

interface Props extends HTMLProps<HTMLDivElement> {
  listeningPost: ServerListeningPost
  defaultURL?: string
  listeningPostUrl?: string,
  shortDescription?: string,
  description?: string
}

const CardV2: React.FC<Props> = ({ listeningPost, className, listeningPostUrl, shortDescription, description, defaultURL }) => {
  const ogSlide = getOgSlide(listeningPost)

  return (
    <div className={clsx(styles.containerV2, className)}>
      <div className={styles.imageContainerV2}>
        <div className={styles.imageContentV2}>
          <img src={ogSlide?.ogSquare} alt="og square" />
        </div>
      </div>
      <form className={styles.contentV2}>
        <div className={styles.shortDescriptionV2}>{shortDescription}</div>
        <div className={styles.descriptionV2}>{description}</div>

        <label className={styles.hostV2} htmlFor="url">
          {defaultURL}
          <input
            id="url"
            type="text"
            name="url"
            disabled={true}
            value={listeningPostUrl ? listeningPostUrl : listeningPost?.id}
          />
        </label>
      </form>
    </div>
  )
}

export default CardV2
