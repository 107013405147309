import React from 'react'
import ShortcutAnswer from '../../../../components/shortcutAnswer/shortcutAnswer'
import styles from './Likert7.module.scss'

interface LikertProps {
  count?: number
  value?: number
  label?: string
  visible?: boolean
  onSelect?: (value: number) => void
  isLittleCard?: boolean
}

const titleFromNumber = (n: number) => {
  switch(n) {
    case 1: return 'Strongly Disagree';
    case 2: return 'Disagree';
    case 3: return 'Somewhat Disagree';
    case 4: return 'Neutral';
    case 5: return 'Somewhat Agree';
    case 6: return 'Agree';
    case 7: return 'Strongly Agree';
  }
}

export const Likert7: React.FC<LikertProps> = ({
  count = 7,
  isLittleCard,
  visible,
  onSelect,
  value,
  label,
}) => {
  const [activeId, setActiveId] = React.useState<number>();
  const numbers = Array.from({ length: count }, (_, i) => count - i)

  return (
    <div className={styles.container}>
      {numbers.map((n) => {
        return <ShortcutAnswer key={n} label={titleFromNumber(n)} value={n} shortcutKey={`${n}`} onSelectAnswer={onSelect} selected={value === n} activeId={activeId} setActiveId={setActiveId} focus={visible} />
      })}
    </div>
  )
}