import React from 'react'
import Input from '../../../../components/largeInput/largeInput'
import SlideSubmitButton from '../../../../slideSubmitButton/slideSubmitButton'
import styles from './DemographicQuestionField.module.scss'

interface DemographicQuestionFieldProps {
  isLittleCard?: boolean
}

export const DemographicQuestionField: React.FC<
  DemographicQuestionFieldProps
> = ({ isLittleCard }) => {
  return (
    <form
      className={styles.content}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <Input
        placeholder="Full name"
        title="Name"
        name="name"
        autoComplete="off"
        className={styles.input}
        value=""
        isLittleCard={isLittleCard}
      />
      <Input
        placeholder="(123) 456-7890 or name@domain.com"
        title="Email / Phone number"
        name="emailPhoneRaw"
        autoComplete="off"
        className={styles.input}
        value=""
        isLittleCard={isLittleCard}
      />
      <SlideSubmitButton
        isSubmitting={false}
        disabled={false}
        isLittleCard={isLittleCard}
      />
    </form>
  )
}
