import React from 'react'
import { Navigate } from 'react-router-dom'
import { LoadingContainer } from '../loading/loading'
import { useAuthContext } from '../../contexts/auth.context'
import Error404 from '../../components2/Error404/Error404'
import Logout from './Logout2'


interface RequireSuperAdminAuthProps extends React.HTMLProps<HTMLDivElement> {
  redirectTo?: string
}
export const RequireSuperAdminAuth: React.FC<RequireSuperAdminAuthProps> = ({
  children,
  redirectTo = '/login',
}) => {
  const { user, me } = useAuthContext()
  if (user === undefined && me === undefined) return <LoadingContainer />
  if (user === null) {
    return <Navigate to={redirectTo} />
  }
  if (user != null && me === null) return <Logout />
  if (user != null && me != null) {
    if (me?.isAdmin) {
      return <>{children}</>
    } else {
      return <Error404 />
    }
  }
  return <LoadingContainer />
}
interface RequireAdminAuthProps extends React.HTMLProps<HTMLDivElement> {
  redirectTo?: string
  namespaceId: string
}
export const RequireAdminAuth: React.FC<RequireAdminAuthProps> = ({
  children,
  redirectTo = '/login',
}) => {
  const { me, user } = useAuthContext()

  if (user === undefined && me === undefined) return <LoadingContainer />
  if (user === null) {
    return <Navigate to={redirectTo} />
  }
  if (user != null && me === null) return <Logout />
  if (user != null && me != null) {
    const isAdmin = me?.isAdmin || me?.isNamespaceAdmin
    if (isAdmin) {
      return <>{children}</>
    } else {
      return <Error404 />
    }
  }

  return <LoadingContainer />
}

interface UnAuthedRouteProps {
  redirectTo?: string
}

export const RequireUnAuthedRoute: React.FC<UnAuthedRouteProps> = ({
  children,
  redirectTo = '/admin',
}) => {
  const { user, me } = useAuthContext()

  console.log("RequireUnAuthedRoute", user)

  if (user === undefined && me === undefined) return <LoadingContainer />
  if (user === null) return <>{children}</>
  if (user != null && me === null) return <>{children}</>
  if (user != null && me != null) {
    return <Navigate to={redirectTo} />
  }
  return <LoadingContainer />
}
