import React from "react";
import { useDebouncedCallback } from "use-debounce";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onEnter?: () => void;
  onEscape?: () => void;
  onChangeDebounced?: (value: string) => void;
}

const KeyDownInput = React.forwardRef<HTMLInputElement, Props>(
  ({ onEnter, onEscape, onChange, onChangeDebounced, ...props }, ref) => {
    const internalRef = React.useRef<HTMLInputElement | null>(null);

    const handleChangeDebounced = useDebouncedCallback((value) => {
      onChangeDebounced?.(value);
    }, 2000);

    return (
      <input
        ref={(node) => {
          internalRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Escape") {
            internalRef.current?.blur();
            onEscape?.();
          }

          if (e.key === "Enter") {
            internalRef.current?.blur();
            onEnter?.();
          }
        }}
        onChange={(e) => {
          onChange?.(e);
          handleChangeDebounced?.(e.target.value);
        }}
        {...props}
      />
    );
  }
);
KeyDownInput.displayName = "KeyDownInput"

export default KeyDownInput;
