import clsx from 'clsx'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Tooltip } from '../Tooltip'
import styles from './input.module.css'

const DeleteIcon = () => {
  return (
    <svg width="10px" height="10px" viewBox="0 0 10 10">
      <path d="M8.21693789,0.188564238 L5,3.40550213 L1.78306211,0.188564238 C1.53369674,-0.0608011267 1.14698093,-0.0608011267 0.897615568,0.188564238 L0.187024024,0.899155783 C-0.0623413413,1.14852115 -0.0623413413,1.53523696 0.187024024,1.78460232 L3.40396192,5.00154021 L0.199741657,8.20576047 C-0.0496237077,8.45512584 -0.0496237077,8.84184165 0.199741657,9.09120701 L0.910333202,9.80179856 C1.15969857,10.0511639 1.54641438,10.0511639 1.79577974,9.80179856 L5,6.57226771 L8.21693789,9.78920561 C8.46630326,10.038571 8.85301907,10.038571 9.10238443,9.78920561 L9.81297598,9.07861406 C10.0623413,8.8292487 10.0623413,8.44253289 9.81297598,8.19316752 L6.58344513,4.98894726 L9.80038303,1.77200937 C10.0497484,1.52264401 10.0497484,1.1359282 9.80038303,0.886562832 L9.08979148,0.175971288 C8.85260761,-0.0607263171 8.45371031,-0.0607263171 8.2170127,0.188634061 L8.21693789,0.188564238 Z" />
    </svg>
  )
}

interface InputProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
  name: string
  value?: any
  floatTitle?: boolean
  disabled?: boolean
  type?: string
  showDelete?: any
  autoFocus?: any
  error?: any
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      title,
      name,
      value,
      onChange,
      placeholder,
      type,
      showDelete,
      autoFocus,
      error,
      autoCapitalize,
      disabled,
      floatTitle,
    },
    ref
  ) => {
    if (type === 'file') throw new Error('Please use <FileInput /> instead')
    const onDelete = showDelete
      ? (e: any) => {
          e.stopPropagation()
          e.preventDefault()
          const event = { target: { name, value: null, type, files: [null] } } as any
          onChange?.(event)
        }
      : undefined

    const extraProps: any = {}

    const classList = clsx(
      styles.container,
      floatTitle ? styles.floatTitleContainer : null,
      error ? styles.error : null,
      disabled ? styles.disabled : null,
      className
    )

    return (
      <div className={classList} {...extraProps}>
        {!floatTitle && (
          <div className={styles.title}>
            <span>{title}</span>
            {error && <span className={styles.errorMessage}>{error}</span>}
          </div>
        )}
        <label className={clsx(styles.label, showDelete ? styles.hasDelete : null)}>
          <input
            placeholder={placeholder}
            onChange={onChange}
            type={type}
            name={name}
            value={value || ''}
            autoFocus={autoFocus}
            className={styles.input}
            autoCapitalize={autoCapitalize}
            disabled={disabled}
            ref={ref}
          />

          {floatTitle && (
            <div className={styles.title}>
              <span>{placeholder}</span>
            </div>
          )}

          {showDelete && value != null && (
            <Tooltip label="REMOVE">
              <div onClick={onDelete} className={styles.delete}>
                <DeleteIcon />
              </div>
            </Tooltip>
          )}
        </label>
      </div>
    )
  }
)

export const Textarea = React.forwardRef<any, InputProps>(
  ({ className, title, name, value, onChange, placeholder, autoFocus, error, floatTitle }, ref) => {
    const classList = clsx(
      styles.container,
      floatTitle ? styles.floatTitleContainer : null,
      error ? styles.error : null,
      className
    )

    return (
      <div className={classList}>
        {!floatTitle && (
          <div className={styles.title}>
            <span>{title}</span>
            {error && <span className={styles.errorMessage}>{error}</span>}
          </div>
        )}
        <label className={styles.label}>
          <TextareaAutosize
            ref={ref}
            name={name}
            className={styles.input}
            onChange={onChange as any}
            placeholder={placeholder}
            value={value || ''}
            autoFocus={autoFocus}
          />
          {floatTitle && (
            <div className={styles.title}>
              <span>{placeholder}</span>
            </div>
          )}
        </label>
      </div>
    )
  }
)

interface FileInputProps extends InputProps {
  multiple?: any
  allowedFileTypes?: any
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      className,
      placeholder,
      title,
      name,
      value,
      onChange,
      showDelete,
      autoFocus,
      error,
      allowedFileTypes,
      disabled,
      multiple,
    },
    ref
  ) => {
    const dragCounterRef = React.useRef(0)
    const [isDraggingOver, setIsDraggingOver] = React.useState<boolean>()
    const onDelete = showDelete
      ? (e: any) => {
          e.stopPropagation()
          e.preventDefault()
          const event = { target: { name, value: null, type: 'file', files: [null] } } as any
          onChange?.(event)
        }
      : undefined

    const fileInputKey = value ? value.name + new Date() : undefined
    const isString = typeof value === 'string'
    const isFile = value instanceof File
    const isAllowedFile =
      isFile && (allowedFileTypes == null || allowedFileTypes.indexOf(value.type) >= 0)
    const isImage = value?.type?.startsWith('image/')

    const extraProps: any = {}

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      dragCounterRef.current += 1
      if (e.dataTransfer?.items?.length > 0) {
        setIsDraggingOver(true)
      }
    }
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      dragCounterRef.current -= 1
      if (dragCounterRef.current > 0) return
      setIsDraggingOver(false)
    }
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setIsDraggingOver(false)

      if (e.dataTransfer?.files?.length > 0) {
        const files = e.dataTransfer?.files
        const event = { target: { name, value: null, type: 'file', files } } as any
        onChange?.(event)
      } else if (e.dataTransfer?.items?.length > 0) {
        const value = e.dataTransfer?.getData?.('text/html')?.match?.(/src\s*=\s*"(.+?)"/)?.[1]
        const event = { target: { name, value, type: 'string' } } as any
        onChange?.(event)
      }

      e.dataTransfer.clearData()
      dragCounterRef.current = 0
    }

    const classList = clsx(
      styles.container,
      error ? styles.error : null,
      disabled ? styles.disabled : null,
      isDraggingOver ? styles.draggingOver : null,
      className
    )

    return (
      <div
        className={classList}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        {...extraProps}
      >
        <div className={styles.title}>
          <span>{title}</span>
          {error && <span className={styles.errorMessage}>{error}</span>}
        </div>
        <label className={clsx(styles.label, showDelete ? styles.hasDelete : null)}>
          <input
            onChange={onChange}
            type="file"
            name={name}
            ref={ref}
            autoFocus={autoFocus}
            className={styles.input}
            key={fileInputKey}
            disabled={disabled}
            multiple={multiple}
          />

          {isString && (
            <span className={styles.input}>
              <img alt={title} src={value} className={styles.imgFile} />
            </span>
          )}
          {isAllowedFile && isImage && (
            <span className={styles.input}>
              <img alt="to upload" src={URL.createObjectURL(value)} className={styles.imgFile} />
            </span>
          )}
          {isAllowedFile && !isImage && <span className={styles.input}>File ({value.type})</span>}
          {value != null && !isString && !isAllowedFile && (
            <Tooltip label={`${value.name} is of type ${value.type || 'unknown'}`}>
              <div className={styles.input}>
                <strong>Invalid file type</strong>, must be {allowedFileTypes.join(' ')}
              </div>
            </Tooltip>
          )}
          {value == null && (
            <span className={clsx(styles.input, styles.placeholder)}>{placeholder}</span>
          )}

          {showDelete && value != null && (
            <Tooltip label="REMOVE">
              <div onClick={onDelete} className={styles.delete}>
                <DeleteIcon />
              </div>
            </Tooltip>
          )}
        </label>
      </div>
    )
  }
)
