import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
          paddingTop: "1.2rem",
          paddingBottom: "1.2rem",
          '&:hover': {
            color: 'black',
          },
          "&.Mui-selected": {
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "3px",
          backgroundColor: "#AFD1DC",
        },
      },
    },
    MuiPopover:{
      styleOverrides: {
        paper: {
          "box-shadow": "none"
        },
      }
    },
  },
  typography: {
    fontFamily: [
      "averta",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
  },
});

