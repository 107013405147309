import React from 'react'
import UtfString from 'utfstring'
import { Tooltip } from '../../components/Tooltip'
import {
  getFadedHighlightRangeColor,
  getHighlightRangeColor,
  getHoverHighlightRangeColor,
  getRangeColor2,
  getRangeString,
  getScore,
} from '../../utils'
// import Tooltip from '../../components/tooltip/tooltip'
import styles from './Hightlight.module.scss'

interface HighlightProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  text: string
  phrases?: any
  highlightedText?: string
}

export const Highlight: React.FC<HighlightProps> = ({
  text,
  phrases,
  className,
  highlightedText,
}) => {
  let offset = 0
  const data: React.ReactNode[] = []

  if (phrases == null) return <div className={className}>{text}</div>

  for (var i = 0; i < phrases.length; i++) {
    const phrase = phrases[i]
    const start = phrase.start <= 0 ? 0 : phrase.start
    if (start > 0)
      data.push(
        <span key={offset + '.' + start}>{UtfString.slice(text ?? '', offset, start)}</span>
      )

    const sentiment = phrase.sentiment && phrase.sentiment.score
    const score = getScore(sentiment)

    const color = getRangeColor2(score)
    const hightlightColor = getHighlightRangeColor(score)
    const fadedHighlightColor = getFadedHighlightRangeColor(score)
    const hoverHightlightColor = getHoverHighlightRangeColor(score)
    const style = {
      '--color': color,
      '--highlight-color': hightlightColor,
      '--highlight-faded-color': fadedHighlightColor,
      '--hover-highlight-color': hoverHightlightColor,
      '--hover-highlight-faded-color': fadedHighlightColor,
    } as React.CSSProperties

    const sentimentString = getRangeString(score)

    data.push(
      // <Tooltip
      //   tagName="span"
      //   content={`Sentiment: ${sentimentString}`}
      //   background={color}
      //   className={styles.highlight}
      //   style={style}
      //   key={phrase.start + '.' + phrase.end}
      // >
      //   {UtfString.slice(text ?? '', phrase.start, phrase.end)}
      // </Tooltip>
      <Tooltip label={`Sentiment: ${sentimentString}`} key={phrase.start + '.' + phrase.end} placement="top" autoPlace={false} background={color}>
        <span className={styles.highlight} style={style}>
          {UtfString.slice(text ?? '', phrase.start, phrase.end)}
        </span>
      </Tooltip>
    )
    offset = phrase.end
  }
  if (offset < UtfString.length(text ?? '')) {
    data.push(<span key={offset}>{UtfString.slice(text ?? '', offset)}</span>)
  }
  return (
    <div className={className}>
      <div className={styles.pRelative}>
        {/* {highlightedText && (
          <div
            className={styles.searchHighlight}
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          />
        )} */}
        {data}
      </div>
    </div>
  )
}
