import clsx from 'clsx'
import { sortBy } from 'lodash'
import React from 'react'
import { namespaceStyle } from '../../../utils'
import { ServerListeningPost } from '../../../utils/trpc'
import { TilesContainer } from '../../BackgroundPicker/TilesContainer/TilesContainer'
import styles from './PreviewBackgroundPicker.module.scss'

interface Props {
  listeningPost: ServerListeningPost
  generateImage: (slideId: string) => void
}

const PreviewBackgroundPicker: React.FC<Props> = ({ listeningPost, generateImage }) => {
  const handleClickSlide = (slideId: string) => {
    generateImage(slideId)
  }

  const backgroundSlides = React.useMemo(() => {
    const sortedSlides = sortBy(Object.values(listeningPost.slides || {}), 'order')
    return sortedSlides
      .map((slide) => ({
        id: slide.id,
        title: slide.title,
        imageUrl: slide.url,
      }))
      .filter((s) => s?.imageUrl != null)
  }, [listeningPost.slides])

  return (
    <div className={styles.container} style={namespaceStyle(listeningPost)}>
      <div className={styles.content}>
        <TilesContainer>
          {backgroundSlides.map(({ id, imageUrl, title }) => (
            <div
              key={id}
              className={styles.tile}
              style={{ backgroundImage: `url(${imageUrl})` }}
              onClick={() => handleClickSlide(id)}
            >
              <div className={clsx(styles.tileContent, styles.colorGradient)}>
                <span className={styles.text}>{title}</span>
              </div>
            </div>
          ))}
        </TilesContainer>
      </div>
    </div>
  )
}

export default PreviewBackgroundPicker
