import clsx from 'clsx'
import React from 'react'
import styles from './TableHeader.module.scss'

export const TableHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return <div className={clsx(styles.container, className)}>{children}</div>
}

export const TableHeaderAdd: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({
  children,
  onClick,
}) => {
  return (
    <div className={styles.add} onClick={onClick}>
      {children}
    </div>
  )
}
