export class SessionStorageService<S extends { [key: string]: any }> {
  getParsedItem<T extends keyof S, R extends S[T]>(key: T): R | null {
    const item = sessionStorage.getItem(key as string);

    try {
      if (!item) return null;
      return JSON.parse(item) as R;
    } catch (e) {}

    return null;
  }

  setStringyItem<T extends keyof S, R extends S[T]>(key: T, value: R): void {
    sessionStorage.setItem(key as string, JSON.stringify(value));
  }

  removeItem<T extends keyof S>(key: T): void {
    sessionStorage.removeItem(key as string);
  }
}

type SlidesData = {
  LINKEDIN_AUTH_PARAMS: {
    listeningPostId: string;
    feedbackId?: string;
    lastActiveIndex?: number;
  };
  LINKEDIN_AUTH_RESPONSE: {
    code?: string | null,
    state?: string | null,
    errorDescription?: string | null,
  };
  RECAPTCHA: { success: boolean };
};

export const slidesSession = new SessionStorageService<SlidesData>();
