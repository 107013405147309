import React from 'react'
import clsx from 'clsx'

import styles from './Scrollable.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  innerClassName?: string
}

/**
 * Scrollable always takes as much width and height as possible and doesn't respect margins you give it.
 */
export const Scrollable: React.FC<Props> = ({className, innerClassName, children}) => {  
  return <div className={clsx(className, styles.container)}>
    <div className={clsx(innerClassName, styles.content)}>
      {children}
    </div>
  </div>
}