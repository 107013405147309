import { trpc } from "../../utils/trpc"
import { groupSlideIds } from "./helpers"

export const useUpdateListeningPostSlideTrpc = () => {
  const utils = trpc.useContext()
  return trpc.listeningPostSlide.updateSlide.useMutation({
    onMutate: ({namespaceId, listeningPostId, slideId, data}) => {
      utils.listeningPost.id.setData({namespaceId, id: listeningPostId}, (listeningPost) => {
        const slides = Object.assign({}, listeningPost?.slides)
        slides[slideId] = Object.assign({}, listeningPost?.slides?.[slideId], data)

        const groupedSlideIds = groupSlideIds(slides)

        return Object.assign({}, listeningPost, { groupedSlideIds, slides })
      })
    },

    onSuccess: (_, {namespaceId, listeningPostId}) => {
      utils.listeningPost.id.invalidate({namespaceId, id: listeningPostId})
    },
    onError: (_, {namespaceId, listeningPostId}) => {
      utils.listeningPost.id.invalidate({namespaceId, id: listeningPostId})
    }
  })
}