import { ActionMap } from '../../../models/state.models'
import { ServerListeningPostSlide } from '../../../utils/trpc'

export type SlidesDisplayPayload = {
  SET_SLIDES: ServerListeningPostSlide[]
  SET_ACTIVE_INDEX: number
  NEXT_SLIDE: undefined
  PREV_SLIDE: undefined
}

export interface SlidesDisplayState {
  activeIndex: number
  slides: ServerListeningPostSlide[]
}

export const slidesDisplayInitialState: SlidesDisplayState = {
  activeIndex: 0,
  slides: [],
}

export type SlidesDisplayAction =
  ActionMap<SlidesDisplayPayload>[keyof ActionMap<SlidesDisplayPayload>]

export const slidesDisplayReducer = (
  state: SlidesDisplayState,
  action: SlidesDisplayAction
): SlidesDisplayState => {
  switch (action.type) {
    case 'SET_SLIDES':
      return {
        ...state,
        slides: action.payload,
      }

    case 'SET_ACTIVE_INDEX':
      return {
        ...state,
        activeIndex: action.payload,
      }

    case 'NEXT_SLIDE':
      const nextIndex = state.activeIndex + 1
      const isLastSlide = nextIndex === state.slides.length

      return {
        ...state,
        activeIndex: isLastSlide ? state.activeIndex : nextIndex,
      }

    case 'PREV_SLIDE':
      const prevIndex = state.activeIndex - 1
      const isFirstSlide = prevIndex === -1

      return {
        ...state,
        activeIndex: isFirstSlide ? state.activeIndex : prevIndex,
      }

    default:
      return state
  }
}
