import clsx from 'clsx'
import React, { HTMLProps } from 'react'
import styles from './Recaptcha.module.scss'

interface Props extends HTMLProps<HTMLDivElement> {
  isLittleCard?: boolean
}

export const Recaptcha: React.FC<Props> = ({ isLittleCard }) => {
  return (
    <div
      className={clsx(
        isLittleCard ? styles.little : undefined,
        styles.container
      )}
    >
      <div className={styles.subTittle}>
        Preview (this is not interactive while building portal)
      </div>
      <svg
        width="312"
        height="86"
        viewBox="0 0 312 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_836_1349)">
          <rect x="5" y="5" width="302" height="76" rx="3" fill="#F9F9F9" />
          <rect
            x="5.5"
            y="5.5"
            width="301"
            height="75"
            rx="2.5"
            stroke="#D3D3D3"
          />
        </g>
        <path
          d="M243.237 56.5273C243.101 56.5046 242.952 56.4932 242.793 56.4932C242.201 56.4932 241.799 56.7454 241.587 57.25V61H240.684V55.7168H241.562L241.577 56.3271C241.873 55.8551 242.293 55.6191 242.837 55.6191C243.013 55.6191 243.146 55.6419 243.237 55.6875V56.5273Z"
          fill="#555555"
        />
        <path
          d="M246.167 61.0977C245.451 61.0977 244.868 60.8633 244.419 60.3945C243.97 59.9225 243.745 59.2926 243.745 58.5049V58.3389C243.745 57.8148 243.844 57.3477 244.043 56.9375C244.245 56.5241 244.525 56.2018 244.883 55.9707C245.244 55.7363 245.635 55.6191 246.055 55.6191C246.742 55.6191 247.275 55.8454 247.656 56.2979C248.037 56.7503 248.228 57.3981 248.228 58.2412V58.6172H244.648C244.661 59.138 244.813 59.5596 245.103 59.8818C245.396 60.2008 245.767 60.3604 246.216 60.3604C246.535 60.3604 246.805 60.2952 247.026 60.165C247.248 60.0348 247.441 59.8623 247.607 59.6475L248.159 60.0771C247.716 60.7575 247.052 61.0977 246.167 61.0977ZM246.055 56.3613C245.69 56.3613 245.384 56.4948 245.137 56.7617C244.889 57.0254 244.736 57.3965 244.678 57.875H247.324V57.8066C247.298 57.3477 247.174 56.9928 246.953 56.7422C246.732 56.4883 246.432 56.3613 246.055 56.3613Z"
          fill="#555555"
        />
        <path
          d="M254.648 58.7441C254.561 59.4961 254.282 60.0771 253.813 60.4873C253.348 60.8942 252.728 61.0977 251.953 61.0977C251.113 61.0977 250.439 60.7965 249.932 60.1943C249.427 59.5921 249.175 58.7865 249.175 57.7773V57.0938C249.175 56.4329 249.292 55.8519 249.526 55.3506C249.764 54.8493 250.099 54.4652 250.532 54.1982C250.965 53.9281 251.466 53.793 252.036 53.793C252.791 53.793 253.397 54.0046 253.853 54.4277C254.308 54.8477 254.574 55.4303 254.648 56.1758H253.706C253.625 55.6094 253.447 55.1992 253.174 54.9453C252.904 54.6914 252.524 54.5645 252.036 54.5645C251.437 54.5645 250.967 54.7858 250.625 55.2285C250.286 55.6712 250.117 56.3011 250.117 57.1182V57.8066C250.117 58.5781 250.278 59.1917 250.601 59.6475C250.923 60.1032 251.374 60.3311 251.953 60.3311C252.474 60.3311 252.873 60.2139 253.149 59.9795C253.429 59.7419 253.615 59.3301 253.706 58.7441H254.648Z"
          fill="#555555"
        />
        <path
          d="M259.858 59.1445H256.88L256.211 61H255.244L257.959 53.8906H258.779L261.499 61H260.537L259.858 59.1445ZM257.163 58.373H259.58L258.369 55.0479L257.163 58.373Z"
          fill="#555555"
        />
        <path
          d="M263.394 58.2168V61H262.456V53.8906H265.078C265.856 53.8906 266.465 54.0892 266.904 54.4863C267.347 54.8835 267.568 55.4092 267.568 56.0635C267.568 56.7536 267.352 57.2858 266.919 57.6602C266.489 58.0312 265.872 58.2168 265.068 58.2168H263.394ZM263.394 57.4502H265.078C265.579 57.4502 265.964 57.333 266.23 57.0986C266.497 56.861 266.631 56.5192 266.631 56.0732C266.631 55.6501 266.497 55.3115 266.23 55.0576C265.964 54.8037 265.597 54.6719 265.132 54.6621H263.394V57.4502Z"
          fill="#555555"
        />
        <path
          d="M273.677 54.6621H271.392V61H270.459V54.6621H268.179V53.8906H273.677V54.6621Z"
          fill="#555555"
        />
        <path
          d="M279.824 58.7441C279.736 59.4961 279.458 60.0771 278.989 60.4873C278.524 60.8942 277.904 61.0977 277.129 61.0977C276.289 61.0977 275.615 60.7965 275.107 60.1943C274.603 59.5921 274.351 58.7865 274.351 57.7773V57.0938C274.351 56.4329 274.468 55.8519 274.702 55.3506C274.94 54.8493 275.275 54.4652 275.708 54.1982C276.141 53.9281 276.642 53.793 277.212 53.793C277.967 53.793 278.573 54.0046 279.028 54.4277C279.484 54.8477 279.749 55.4303 279.824 56.1758H278.882C278.8 55.6094 278.623 55.1992 278.35 54.9453C278.079 54.6914 277.7 54.5645 277.212 54.5645C276.613 54.5645 276.143 54.7858 275.801 55.2285C275.462 55.6712 275.293 56.3011 275.293 57.1182V57.8066C275.293 58.5781 275.454 59.1917 275.776 59.6475C276.099 60.1032 276.549 60.3311 277.129 60.3311C277.65 60.3311 278.049 60.2139 278.325 59.9795C278.605 59.7419 278.791 59.3301 278.882 58.7441H279.824Z"
          fill="#555555"
        />
        <path
          d="M286.572 61H285.63V57.7139H282.046V61H281.108V53.8906H282.046V56.9473H285.63V53.8906H286.572V61Z"
          fill="#555555"
        />
        <path
          d="M292.251 59.1445H289.272L288.604 61H287.637L290.352 53.8906H291.172L293.892 61H292.93L292.251 59.1445ZM289.556 58.373H291.973L290.762 55.0479L289.556 58.373Z"
          fill="#555555"
        />
        <path
          d="M239.75 69.5391V71.7656H239V66.0781H241.098C241.72 66.0781 242.207 66.237 242.559 66.5547C242.913 66.8724 243.09 67.293 243.09 67.8164C243.09 68.3685 242.917 68.7943 242.57 69.0938C242.227 69.3906 241.733 69.5391 241.09 69.5391H239.75ZM239.75 68.9258H241.098C241.499 68.9258 241.806 68.832 242.02 68.6445C242.233 68.4544 242.34 68.181 242.34 67.8242C242.34 67.4857 242.233 67.2148 242.02 67.0117C241.806 66.8086 241.513 66.7031 241.141 66.6953H239.75V68.9258Z"
          fill="#555555"
        />
        <path
          d="M245.977 68.1875C245.867 68.1693 245.749 68.1602 245.621 68.1602C245.147 68.1602 244.826 68.362 244.656 68.7656V71.7656H243.934V67.5391H244.637L244.648 68.0273C244.885 67.6497 245.221 67.4609 245.656 67.4609C245.797 67.4609 245.904 67.4792 245.977 67.5156V68.1875Z"
          fill="#555555"
        />
        <path
          d="M247.43 71.7656H246.707V67.5391H247.43V71.7656ZM246.648 66.418C246.648 66.3008 246.684 66.2018 246.754 66.1211C246.827 66.0404 246.934 66 247.074 66C247.215 66 247.322 66.0404 247.395 66.1211C247.467 66.2018 247.504 66.3008 247.504 66.418C247.504 66.5352 247.467 66.6328 247.395 66.7109C247.322 66.7891 247.215 66.8281 247.074 66.8281C246.934 66.8281 246.827 66.7891 246.754 66.7109C246.684 66.6328 246.648 66.5352 246.648 66.418Z"
          fill="#555555"
        />
        <path
          d="M249.984 70.7852L251.031 67.5391H251.77L250.254 71.7656H249.703L248.172 67.5391H248.91L249.984 70.7852Z"
          fill="#555555"
        />
        <path
          d="M255.012 71.7656C254.97 71.6823 254.936 71.5339 254.91 71.3203C254.574 71.6693 254.173 71.8438 253.707 71.8438C253.29 71.8438 252.948 71.7266 252.68 71.4922C252.414 71.2552 252.281 70.9557 252.281 70.5938C252.281 70.1536 252.448 69.8125 252.781 69.5703C253.117 69.3255 253.589 69.2031 254.195 69.2031H254.898V68.8711C254.898 68.6185 254.823 68.418 254.672 68.2695C254.521 68.1185 254.298 68.043 254.004 68.043C253.746 68.043 253.53 68.1081 253.355 68.2383C253.181 68.3685 253.094 68.526 253.094 68.7109H252.367C252.367 68.5 252.441 68.2969 252.59 68.1016C252.741 67.9036 252.944 67.7474 253.199 67.6328C253.457 67.5182 253.74 67.4609 254.047 67.4609C254.534 67.4609 254.915 67.5833 255.191 67.8281C255.467 68.0703 255.611 68.4049 255.621 68.832V70.7773C255.621 71.1654 255.671 71.474 255.77 71.7031V71.7656H255.012ZM253.812 71.2148C254.039 71.2148 254.254 71.1562 254.457 71.0391C254.66 70.9219 254.807 70.7695 254.898 70.582V69.7148H254.332C253.447 69.7148 253.004 69.974 253.004 70.4922C253.004 70.7188 253.079 70.8958 253.23 71.0234C253.382 71.151 253.576 71.2148 253.812 71.2148Z"
          fill="#555555"
        />
        <path
          d="M258.449 71.2539C258.707 71.2539 258.932 71.1758 259.125 71.0195C259.318 70.8633 259.424 70.668 259.445 70.4336H260.129C260.116 70.6758 260.033 70.9062 259.879 71.125C259.725 71.3438 259.52 71.5182 259.262 71.6484C259.007 71.7786 258.736 71.8438 258.449 71.8438C257.874 71.8438 257.415 71.6523 257.074 71.2695C256.736 70.8841 256.566 70.3581 256.566 69.6914V69.5703C256.566 69.1589 256.642 68.793 256.793 68.4727C256.944 68.1523 257.16 67.9036 257.441 67.7266C257.725 67.5495 258.06 67.4609 258.445 67.4609C258.919 67.4609 259.312 67.6029 259.625 67.8867C259.94 68.1706 260.108 68.5391 260.129 68.9922H259.445C259.424 68.7188 259.32 68.4948 259.133 68.3203C258.948 68.1432 258.719 68.0547 258.445 68.0547C258.078 68.0547 257.793 68.1875 257.59 68.4531C257.389 68.7161 257.289 69.0977 257.289 69.5977V69.7344C257.289 70.2214 257.389 70.5964 257.59 70.8594C257.79 71.1224 258.077 71.2539 258.449 71.2539Z"
          fill="#555555"
        />
        <path
          d="M262.324 70.707L263.309 67.5391H264.082L262.383 72.418C262.12 73.1211 261.702 73.4727 261.129 73.4727L260.992 73.4609L260.723 73.4102V72.8242L260.918 72.8398C261.163 72.8398 261.353 72.7904 261.488 72.6914C261.626 72.5924 261.74 72.4115 261.828 72.1484L261.988 71.7188L260.48 67.5391H261.27L262.324 70.707Z"
          fill="#555555"
        />
        <path
          d="M276.93 66.6953H275.102V71.7656H274.355V66.6953H272.531V66.0781H276.93V66.6953Z"
          fill="#555555"
        />
        <path
          d="M279.023 71.8438C278.451 71.8438 277.984 71.6562 277.625 71.2812C277.266 70.9036 277.086 70.3997 277.086 69.7695V69.6367C277.086 69.2174 277.165 68.8438 277.324 68.5156C277.486 68.1849 277.71 67.9271 277.996 67.7422C278.285 67.5547 278.598 67.4609 278.934 67.4609C279.483 67.4609 279.91 67.6419 280.215 68.0039C280.52 68.3659 280.672 68.8841 280.672 69.5586V69.8594H277.809C277.819 70.276 277.94 70.6133 278.172 70.8711C278.406 71.1263 278.703 71.2539 279.062 71.2539C279.318 71.2539 279.534 71.2018 279.711 71.0977C279.888 70.9935 280.043 70.8555 280.176 70.6836L280.617 71.0273C280.263 71.5716 279.732 71.8438 279.023 71.8438ZM278.934 68.0547C278.642 68.0547 278.397 68.1615 278.199 68.375C278.001 68.5859 277.879 68.8828 277.832 69.2656H279.949V69.2109C279.928 68.8438 279.829 68.5599 279.652 68.3594C279.475 68.1562 279.236 68.0547 278.934 68.0547Z"
          fill="#555555"
        />
        <path
          d="M283.555 68.1875C283.445 68.1693 283.327 68.1602 283.199 68.1602C282.725 68.1602 282.404 68.362 282.234 68.7656V71.7656H281.512V67.5391H282.215L282.227 68.0273C282.464 67.6497 282.799 67.4609 283.234 67.4609C283.375 67.4609 283.482 67.4792 283.555 67.5156V68.1875Z"
          fill="#555555"
        />
        <path
          d="M284.902 67.5391L284.922 68.0078C285.232 67.6432 285.65 67.4609 286.176 67.4609C286.767 67.4609 287.169 67.6875 287.383 68.1406C287.523 67.9375 287.706 67.7734 287.93 67.6484C288.156 67.5234 288.423 67.4609 288.73 67.4609C289.658 67.4609 290.129 67.9518 290.145 68.9336V71.7656H289.422V68.9766C289.422 68.6745 289.353 68.4492 289.215 68.3008C289.077 68.1497 288.845 68.0742 288.52 68.0742C288.251 68.0742 288.029 68.1549 287.852 68.3164C287.674 68.4753 287.572 68.6901 287.543 68.9609V71.7656H286.816V68.9961C286.816 68.3815 286.516 68.0742 285.914 68.0742C285.44 68.0742 285.116 68.276 284.941 68.6797V71.7656H284.219V67.5391H284.902Z"
          fill="#555555"
        />
        <path
          d="M293.699 70.6445C293.699 70.4492 293.625 70.2982 293.477 70.1914C293.331 70.082 293.074 69.9883 292.707 69.9102C292.342 69.832 292.052 69.7383 291.836 69.6289C291.622 69.5195 291.464 69.3893 291.359 69.2383C291.258 69.0872 291.207 68.9076 291.207 68.6992C291.207 68.3529 291.353 68.0599 291.645 67.8203C291.939 67.5807 292.314 67.4609 292.77 67.4609C293.249 67.4609 293.637 67.5846 293.934 67.832C294.233 68.0794 294.383 68.3958 294.383 68.7812H293.656C293.656 68.5833 293.572 68.4128 293.402 68.2695C293.236 68.1263 293.025 68.0547 292.77 68.0547C292.507 68.0547 292.301 68.112 292.152 68.2266C292.004 68.3411 291.93 68.4909 291.93 68.6758C291.93 68.8503 291.999 68.9818 292.137 69.0703C292.275 69.1589 292.523 69.2435 292.883 69.3242C293.245 69.4049 293.538 69.5013 293.762 69.6133C293.986 69.7253 294.151 69.8607 294.258 70.0195C294.367 70.1758 294.422 70.3672 294.422 70.5938C294.422 70.9714 294.271 71.2747 293.969 71.5039C293.667 71.7305 293.275 71.8438 292.793 71.8438C292.454 71.8438 292.155 71.7839 291.895 71.6641C291.634 71.5443 291.43 71.3776 291.281 71.1641C291.135 70.9479 291.062 70.7148 291.062 70.4648H291.785C291.798 70.707 291.895 70.8997 292.074 71.043C292.257 71.1836 292.496 71.2539 292.793 71.2539C293.066 71.2539 293.285 71.1992 293.449 71.0898C293.616 70.9779 293.699 70.8294 293.699 70.6445Z"
          fill="#555555"
        />
        <path
          d="M269.328 69.9648H267.277V69.1992H269.328V69.9648Z"
          fill="black"
        />
        <g filter="url(#filter1_d_836_1349)">
          <path
            d="M278.401 23.3378C278.266 23.1691 278.126 23.0031 277.983 22.84C275.688 20.234 272.522 18.554 269.077 18.1147C265.633 17.6754 262.146 18.507 259.271 20.4537L263.387 26.5341C264.783 25.589 266.476 25.1853 268.148 25.3985C269.821 25.6118 271.358 26.4275 272.472 27.6927C272.722 27.9763 272.946 28.2781 273.144 28.5947L269.501 32.2385H281.61V20.1292L278.401 23.3378Z"
            fill="#1C3AA9"
          />
        </g>
        <g filter="url(#filter2_d_836_1349)">
          <path
            d="M267.238 18.0005H255.129L258.301 21.1723C255.931 23.0878 254.227 25.7199 253.457 28.6902C252.585 32.0514 252.969 35.6151 254.536 38.7137L261.089 35.3995C260.328 33.8952 260.142 32.165 260.565 30.5331C260.988 28.9013 261.991 27.4796 263.387 26.5345C263.442 26.4972 263.498 26.4608 263.554 26.4252L267.238 30.1098V18.0005Z"
            fill="#4285F4"
          />
        </g>
        <g filter="url(#filter3_d_836_1349)">
          <path
            d="M253 32.3716H265.109L261.451 36.0302C262.227 37.2318 263.359 38.1693 264.698 38.705C266.263 39.3312 268.003 39.3652 269.592 38.8007C271.18 38.2363 272.509 37.112 273.328 35.6385L278.624 40.9213C276.954 43.1132 274.674 44.7873 272.05 45.7197C268.778 46.8824 265.195 46.8123 261.971 45.5225C259.703 44.6153 257.722 43.1482 256.202 41.2793L253 44.4809V32.3716Z"
            fill="#ABABAB"
          />
        </g>
        <path d="M60.5635 47H59.251V37.0469H60.5635V47Z" fill="black" />
        <path
          d="M62.8604 39.8496L62.1426 39.3574C62.5664 38.765 62.7852 38.1406 62.7988 37.4844V36.5H64.0498V37.3887C64.0452 37.8444 63.9313 38.3001 63.708 38.7559C63.4893 39.207 63.2067 39.5716 62.8604 39.8496Z"
          fill="black"
        />
        <path
          d="M66.627 39.6035L66.6611 40.4238C67.2035 39.7858 67.9349 39.4668 68.8555 39.4668C69.89 39.4668 70.5941 39.8633 70.9678 40.6562C71.2139 40.3008 71.5329 40.0137 71.9248 39.7949C72.3213 39.5762 72.7884 39.4668 73.3262 39.4668C74.9486 39.4668 75.7734 40.3258 75.8008 42.0439V47H74.5361V42.1191C74.5361 41.5905 74.4154 41.1963 74.1738 40.9365C73.9323 40.6722 73.5267 40.54 72.957 40.54C72.4876 40.54 72.098 40.6813 71.7881 40.9639C71.4782 41.2419 71.2982 41.6178 71.248 42.0918V47H69.9766V42.1533C69.9766 41.0778 69.4502 40.54 68.3975 40.54C67.568 40.54 67.0007 40.8932 66.6953 41.5996V47H65.4307V39.6035H66.627Z"
          fill="black"
        />
        <path
          d="M82.3838 39.6035L82.4248 40.5332C82.9899 39.8223 83.7282 39.4668 84.6396 39.4668C86.2028 39.4668 86.9912 40.3486 87.0049 42.1123V47H85.7402V42.1055C85.7357 41.5723 85.6126 41.1781 85.3711 40.9229C85.1341 40.6676 84.7627 40.54 84.2568 40.54C83.8467 40.54 83.4867 40.6494 83.1768 40.8682C82.8669 41.0869 82.6253 41.374 82.4521 41.7295V47H81.1875V39.6035H82.3838Z"
          fill="black"
        />
        <path
          d="M88.5771 43.2334C88.5771 42.5088 88.7184 41.8571 89.001 41.2783C89.2881 40.6995 89.6846 40.2529 90.1904 39.9385C90.7008 39.624 91.2819 39.4668 91.9336 39.4668C92.9408 39.4668 93.7542 39.8154 94.374 40.5127C94.9984 41.21 95.3105 42.1374 95.3105 43.2949V43.3838C95.3105 44.1038 95.1715 44.751 94.8936 45.3252C94.6201 45.8949 94.2259 46.3392 93.7109 46.6582C93.2005 46.9772 92.6126 47.1367 91.9473 47.1367C90.9447 47.1367 90.1312 46.7881 89.5068 46.0908C88.887 45.3936 88.5771 44.4707 88.5771 43.3223V43.2334ZM89.8486 43.3838C89.8486 44.2041 90.0378 44.8626 90.416 45.3594C90.7988 45.8561 91.3092 46.1045 91.9473 46.1045C92.5898 46.1045 93.1003 45.8538 93.4785 45.3525C93.8568 44.8467 94.0459 44.1403 94.0459 43.2334C94.0459 42.4222 93.8522 41.766 93.4648 41.2646C93.082 40.7588 92.5716 40.5059 91.9336 40.5059C91.3092 40.5059 90.8057 40.7542 90.4229 41.251C90.04 41.7477 89.8486 42.4587 89.8486 43.3838Z"
          fill="black"
        />
        <path
          d="M98.6123 37.8125V39.6035H99.9932V40.5811H98.6123V45.168C98.6123 45.4642 98.6738 45.6875 98.7969 45.8379C98.9199 45.9837 99.1296 46.0566 99.4258 46.0566C99.5716 46.0566 99.7721 46.0293 100.027 45.9746V47C99.6947 47.0911 99.3711 47.1367 99.0566 47.1367C98.4915 47.1367 98.0654 46.9658 97.7783 46.624C97.4912 46.2822 97.3477 45.7969 97.3477 45.168V40.5811H96.001V39.6035H97.3477V37.8125H98.6123Z"
          fill="black"
        />
        <path
          d="M109.516 47C109.443 46.8542 109.383 46.5944 109.338 46.2207C108.75 46.8314 108.048 47.1367 107.232 47.1367C106.503 47.1367 105.904 46.9316 105.435 46.5215C104.97 46.1068 104.737 45.5827 104.737 44.9492C104.737 44.179 105.029 43.582 105.612 43.1582C106.2 42.7298 107.025 42.5156 108.087 42.5156H109.317V41.9346C109.317 41.4925 109.185 41.1416 108.921 40.8818C108.657 40.6175 108.267 40.4854 107.752 40.4854C107.301 40.4854 106.923 40.5993 106.617 40.8271C106.312 41.055 106.159 41.3307 106.159 41.6543H104.888C104.888 41.2852 105.018 40.9297 105.277 40.5879C105.542 40.2415 105.897 39.9681 106.344 39.7676C106.795 39.5671 107.289 39.4668 107.827 39.4668C108.679 39.4668 109.347 39.681 109.83 40.1094C110.313 40.5332 110.564 41.1188 110.582 41.8662V45.2705C110.582 45.9495 110.669 46.4896 110.842 46.8906V47H109.516ZM107.417 46.0361C107.813 46.0361 108.189 45.9336 108.545 45.7285C108.9 45.5234 109.158 45.2568 109.317 44.9287V43.4111H108.326C106.777 43.4111 106.002 43.8646 106.002 44.7715C106.002 45.168 106.134 45.4779 106.398 45.7012C106.663 45.9245 107.002 46.0361 107.417 46.0361Z"
          fill="black"
        />
        <path
          d="M119.612 40.7383C119.421 40.7064 119.214 40.6904 118.99 40.6904C118.161 40.6904 117.598 41.0436 117.302 41.75V47H116.037V39.6035H117.268L117.288 40.458C117.703 39.7972 118.291 39.4668 119.052 39.4668C119.298 39.4668 119.485 39.4987 119.612 39.5625V40.7383Z"
          fill="black"
        />
        <path
          d="M120.31 43.2334C120.31 42.5088 120.451 41.8571 120.733 41.2783C121.021 40.6995 121.417 40.2529 121.923 39.9385C122.433 39.624 123.014 39.4668 123.666 39.4668C124.673 39.4668 125.487 39.8154 126.106 40.5127C126.731 41.21 127.043 42.1374 127.043 43.2949V43.3838C127.043 44.1038 126.904 44.751 126.626 45.3252C126.353 45.8949 125.958 46.3392 125.443 46.6582C124.933 46.9772 124.345 47.1367 123.68 47.1367C122.677 47.1367 121.864 46.7881 121.239 46.0908C120.619 45.3936 120.31 44.4707 120.31 43.3223V43.2334ZM121.581 43.3838C121.581 44.2041 121.77 44.8626 122.148 45.3594C122.531 45.8561 123.042 46.1045 123.68 46.1045C124.322 46.1045 124.833 45.8538 125.211 45.3525C125.589 44.8467 125.778 44.1403 125.778 43.2334C125.778 42.4222 125.585 41.766 125.197 41.2646C124.814 40.7588 124.304 40.5059 123.666 40.5059C123.042 40.5059 122.538 40.7542 122.155 41.251C121.772 41.7477 121.581 42.4587 121.581 43.3838Z"
          fill="black"
        />
        <path
          d="M134.891 43.3838C134.891 44.514 134.631 45.4232 134.111 46.1113C133.592 46.7949 132.895 47.1367 132.02 47.1367C131.085 47.1367 130.363 46.8063 129.853 46.1455L129.791 47H128.629V36.5H129.894V40.417C130.404 39.7835 131.108 39.4668 132.006 39.4668C132.904 39.4668 133.608 39.8063 134.118 40.4854C134.633 41.1644 134.891 42.0941 134.891 43.2744V43.3838ZM133.626 43.2402C133.626 42.3789 133.46 41.7135 133.127 41.2441C132.794 40.7747 132.316 40.54 131.691 40.54C130.857 40.54 130.258 40.9274 129.894 41.7021V44.9014C130.281 45.6761 130.885 46.0635 131.705 46.0635C132.311 46.0635 132.783 45.8288 133.12 45.3594C133.457 44.89 133.626 44.1836 133.626 43.2402Z"
          fill="black"
        />
        <path
          d="M136.155 43.2334C136.155 42.5088 136.297 41.8571 136.579 41.2783C136.866 40.6995 137.263 40.2529 137.769 39.9385C138.279 39.624 138.86 39.4668 139.512 39.4668C140.519 39.4668 141.332 39.8154 141.952 40.5127C142.576 41.21 142.889 42.1374 142.889 43.2949V43.3838C142.889 44.1038 142.75 44.751 142.472 45.3252C142.198 45.8949 141.804 46.3392 141.289 46.6582C140.779 46.9772 140.191 47.1367 139.525 47.1367C138.523 47.1367 137.709 46.7881 137.085 46.0908C136.465 45.3936 136.155 44.4707 136.155 43.3223V43.2334ZM137.427 43.3838C137.427 44.2041 137.616 44.8626 137.994 45.3594C138.377 45.8561 138.887 46.1045 139.525 46.1045C140.168 46.1045 140.678 45.8538 141.057 45.3525C141.435 44.8467 141.624 44.1403 141.624 43.2334C141.624 42.4222 141.43 41.766 141.043 41.2646C140.66 40.7588 140.15 40.5059 139.512 40.5059C138.887 40.5059 138.384 40.7542 138.001 41.251C137.618 41.7477 137.427 42.4587 137.427 43.3838Z"
          fill="black"
        />
        <path
          d="M146.19 37.8125V39.6035H147.571V40.5811H146.19V45.168C146.19 45.4642 146.252 45.6875 146.375 45.8379C146.498 45.9837 146.708 46.0566 147.004 46.0566C147.15 46.0566 147.35 46.0293 147.605 45.9746V47C147.273 47.0911 146.949 47.1367 146.635 47.1367C146.07 47.1367 145.644 46.9658 145.356 46.624C145.069 46.2822 144.926 45.7969 144.926 45.168V40.5811H143.579V39.6035H144.926V37.8125H146.19Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9259 30H20.0741C20.0332 30 20 30.0332 20 30.0741V53.9259C20 53.9668 20.0332 54 20.0741 54H43.9259C43.9668 54 44 53.9668 44 53.9259V30.0741C44 30.0332 43.9668 30 43.9259 30ZM43.9259 28H20.0741C18.9286 28 18 28.9286 18 30.0741V53.9259C18 55.0714 18.9286 56 20.0741 56H43.9259C45.0714 56 46 55.0714 46 53.9259V30.0741C46 28.9286 45.0714 28 43.9259 28Z"
          fill="#C1C1C1"
        />
        <rect x="20" y="30" width="24" height="24" fill="white" />
        <defs>
          <filter
            id="filter0_d_836_1349"
            x="0"
            y="0"
            width="312"
            height="86"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_836_1349"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_836_1349"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_836_1349"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_836_1349"
            x="258.271"
            y="17"
            width="24.3391"
            height="16.2385"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_836_1349"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_836_1349"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_836_1349"
            x="252"
            y="17.0005"
            width="16.2385"
            height="22.7131"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_836_1349"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_836_1349"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_836_1349"
            x="252"
            y="31.3716"
            width="27.6238"
            height="16.1719"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_836_1349"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_836_1349"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}
