import { initializeApp } from 'firebase/app'
import 'firebase/auth'
import type { User as ClientUser } from 'firebase/auth'
import { getAuth } from 'firebase/auth'
import React from 'react'
import { getNamespaceId } from '../App'
import { ServerUser, trpc } from '../utils/trpc'

const firebaseConfig = {
  apiKey: 'AIzaSyCIUlbqsOPRSgBxY8xdLWJaqSaqTqJo6Og',
  authDomain: 'seattle-listens.firebaseapp.com',
}

initializeApp(firebaseConfig)

interface AuthType {
  user?: ClientUser | null
  me?: ServerUser
  isLoading?: boolean,
  isError?: boolean
}

export const AuthContext = React.createContext<AuthType>({})

export const AuthProvider: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({ children }) => {
  const namespaceId = getNamespaceId()
  const [firebaseUser, setFirebaseUser] = React.useState<ClientUser | null>()
  const { data, isLoading, isError } = trpc.user.me.useQuery(namespaceId, {
    enabled: Boolean(firebaseUser),
  })

  React.useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(setFirebaseUser)
    return unsubscribe
  }, [])

  const value = {
    user: firebaseUser,
    me: data,
    isLoading: Boolean(firebaseUser) ? isLoading : false,
    isError: isError || firebaseUser === null
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)
