import { omit } from 'lodash'
import { ServerListeningPostSlideUpdate, trpc } from '../../utils/trpc'

export const useDeleteListeningPostAnswerOptionTrpc = () => {
  const utils = trpc.useContext()
  return trpc.listeningPostSlide.deleteListeningPostSlideAnswer.useMutation({
    onMutate: ({ namespaceId, listeningPostId, slideId, optionId }) => {
      utils.listeningPost.id.setData({ namespaceId, id: listeningPostId }, (listeningPost) => {
        const slides = listeningPost?.slides
        const slide = slides?.[slideId]
        const answerOptions = omit(slide?.answerOptions, [optionId])
        const newSlide: ServerListeningPostSlideUpdate = Object.assign({}, slide, {answerOptions})
        
        const newSlides = Object.assign({}, slides, {[slideId]: newSlide})
        return Object.assign({}, listeningPost, {slides: newSlides})
      })
    },

    onSuccess: (_, { namespaceId, listeningPostId, slideId }) => {
      utils.listeningPost.id.invalidate({ namespaceId, id: listeningPostId })
    },
    onError: (_, { namespaceId, listeningPostId, slideId }) => {
      utils.listeningPost.id.invalidate({ namespaceId, id: listeningPostId })
      
    },
  })
}
