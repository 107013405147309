import clsx from 'clsx'
import { AspectRatio } from '../../../../../components2/AspectRatio/AspectRatio'
import { getOgSlide } from '../../../../../utils'
import { ServerListeningPost } from '../../../../../utils/trpc'
import styles from './CardPreview.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  listeningPost: ServerListeningPost
  defaultURL?: string
  listeningPostUrl?: string
}

const CardPreview: React.FC<Props> = ({
  listeningPost,
  className,
  listeningPostUrl,
  defaultURL,
}) => {
  const ogSlide = getOgSlide(listeningPost)

  return (
    <div className={clsx(styles.container, className)}>
      <AspectRatio aspectWH={16 / 9} className={styles.image}>
        <img src={ogSlide?.og} alt="og preview" className={styles.image} />
      </AspectRatio>
      <form className={styles.content}>
        <label className={styles.host} htmlFor="url">
          {defaultURL}
          <input
            id="url"
            type="text"
            name="url"
            disabled={true}
            value={(listeningPostUrl ? listeningPostUrl : listeningPost.id) ?? ''}
          />
        </label>
        <input
          disabled={true}
          className={styles.shortDescription}
          value={listeningPost.shortDescription ?? ""}
        />
        <input
          disabled={true}
          className={styles.description}
          value={listeningPost.description ?? ''}
        />
      </form>
    </div>
  )
}

export default CardPreview
