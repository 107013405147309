import React, { useLayoutEffect, useState } from 'react';


export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

const getDimensionObject = (node: any): DimensionObject => {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: "x" in rect ? rect.x : rect.top,
    left: "y" in rect ? rect.y : rect.left,
    x: "x" in rect ? rect.x : rect.left,
    y: "y" in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom
  };
}

function useDimensions<T>({liveMeasure = true}: UseDimensionsArgs = {}): [React.RefObject<T>, DimensionObject | undefined] {
  const ref = React.useRef<T>(null)
  const [dimensions, setDimensions] = useState<DimensionObject | undefined>(undefined);

  useLayoutEffect(() => {
    if(ref.current == null) return;
    const node = ref.current

      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener("resize", measure);
        window.addEventListener("scroll", measure);

        return () => {
          window.removeEventListener("resize", measure);
          window.removeEventListener("scroll", measure);
        };
      }
  }, [ref, liveMeasure]);

  return [ref, dimensions];
}

export default useDimensions;