import { useLocalStorage } from '@rehooks/local-storage'
import clsx from 'clsx'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useMediaQueryContext } from '../../contexts'
import { namespaceStyle } from '../../utils'
import Login from '../auth/login'
import Logout from '../auth/Logout2'
import PasswordConfirm from '../auth/passwordConfirm'
import PasswordReset from '../auth/passwordReset'
import { RequireSuperAdminAuth, RequireUnAuthedRoute } from '../auth/Routes2'
import Signup from '../auth/Signup2'
import { SuperadminLeftNav } from '../../components2/LeftNav/LeftNav'
import { NamespacesDashboard } from '../../components2/NamespacesDashboard/NamespacesDashboard'
import Settings from '../settings/settings'
import styles from './superAdmin.module.scss'

export const SuperAdmin = () => {
  const { isMobileView } = useMediaQueryContext()
  const [navMinimized] = useLocalStorage('navMinimized', false)

  const isOpened = !(navMinimized || isMobileView)

  const style = {
    '--nav-width': isOpened ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties

  return (
    <div style={namespaceStyle(undefined, style)}>
      <Helmet>
        <title>Listens.in</title>
      </Helmet>

      <Routes>
        <Route
          path="login"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <Login />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="signup"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <Signup />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="passwordReset"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <PasswordReset />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="passwordConfirm"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <PasswordConfirm />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="logout"
          element={
              <Logout />
          }
        />

        <Route
          path="admin/*"
          element={
            <RequireSuperAdminAuth>
              <AuthedSuperAdmin />
            </RequireSuperAdminAuth>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  )
}

export const AuthedSuperAdmin = () => {
  return (
    <>
      <SuperadminLeftNav />
      <div className={clsx(styles.container, styles.withLeftNav)}>
        <Routes>
          <Route path="settings" element={<Settings />} />
          <Route path="/" element={<NamespacesDashboard />} />
          {/* <Route path="create" element={<CreateListeningPost namespace={namespace} />} />
        <Route path="/" element={<ListeningPosts namespace={namespace} />} /> */}
        </Routes>
      </div>
    </>
  )
}

export default SuperAdmin
