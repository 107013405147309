import { ActionMap } from '../../models/state.models'

type SlidesDesignerPayload = {
  SET_VISIBLE_SLIDE: {
    visibleSectionId: string
    visibleSlideId: string
  }
}

export interface SlidesDesignerState {
  visibleSectionId?: string
  visibleSlideId?: string
}

export type SlidesDesignerAction =
  ActionMap<SlidesDesignerPayload>[keyof ActionMap<SlidesDesignerPayload>]

export const slidesDesignerReducer = (
  state: SlidesDesignerState,
  action: SlidesDesignerAction
): SlidesDesignerState => {
  switch (action.type) {
    case 'SET_VISIBLE_SLIDE': {
      return {
        ...state,
        visibleSlideId: action.payload.visibleSlideId,
        visibleSectionId: action.payload.visibleSectionId,
      }
    }

    default:
      return state
  }
}
