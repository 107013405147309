import React from 'react'
import clsx from 'clsx'
import { LinkedinLogoIcon } from '../../../../currentColorIcons'
import styles from './LinkedinAuthButton.module.scss'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLittleCard?: boolean
}

export const LinkedinAuthButton: React.FC<Props> = ({
  children,
  isLittleCard,
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(
        styles.container,
        isLittleCard && styles.littleContainer,
        className
      )}
      {...props}
    >
      <LinkedinLogoIcon />
      {children}
    </button>
  )
}
