import { useEffect } from "react";

const defaultOptions = {
  when: true,
  eventTypes: ["keydown"]
};

interface Options {
  when?: boolean,
  eventTypes?: KeyboardEvent["key"][],
  target?: React.RefObject<any>
}


export const useKey = (keyList: string[] = [], handler: (e?: KeyboardEvent) => void, opts?: Options) => {
  const options = { ...defaultOptions, ...opts};
  const { when, eventTypes } = options;
  const { target } = options;
  let targetNode: any;
  if (typeof window !== "undefined") targetNode = window;
  if (target?.current) targetNode = target.current;

  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if (keyList.includes(e.key)) {
        handler(e);
      }
    }
    if (when) {
      eventTypes.forEach(eventType => {
        targetNode.addEventListener(eventType, handle);
      });
      return () => {
        eventTypes.forEach(eventType => {
          targetNode.removeEventListener(eventType, handle);
        });
      };
    }
  }, [when, eventTypes, keyList, targetNode, handler]);
}

export const useGlobalKey = (key: KeyboardEvent["key"], handler?: (e?: KeyboardEvent) => void ) => {
  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if(key === e.key && handler != null) handler(e);
    }
    window.addEventListener('keyup', handle);
    return () => {
      window.removeEventListener('keyup', handle);
    }
  }, [key, handler])
}