import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useUpdateNamespaceTrpc } from '../../trpcHooks/namespace/useUpdateNamespaceTrpc'
import { allowedImageTypes, blobToBase64 } from '../../utils'
import { ServerPublicNamespace, trpc } from '../../utils/trpc'
import Button from '../button/button'
import { Input, Textarea } from '../input/input'
import { InputFile } from '../InputFile/InputFile'
import { Input as InputV3 } from '../inputV3/input'
import styles from './settings.module.scss'

interface SettingsProps {
  namespace?: ServerPublicNamespace
}

export const Settings: React.FC<SettingsProps> = ({ namespace }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Button tagName={Link} to="/logout">
          Logout
        </Button>

        {namespace && <NamespaceConfig namespace={namespace} />}
      </div>
    </div>
  )
}

export default Settings

interface NamespaceConfigDetailsProps {
  namespace: ServerPublicNamespace
}

const NamespaceConfigDetails: React.FC<NamespaceConfigDetailsProps> = ({ namespace }) => {
  const namespaceId = namespace.id
  const namespaceAddLogoMutation = trpc.namespace.addLogo.useMutation()

  const updateNamespace = useUpdateNamespaceTrpc()

  const {
    getValues,
    watch,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: {
      global: '',
      customDomain: '',
      suggestColors: [],
      ...namespace,
    },
  })

  React.useEffect(() => {
    const handleChange = async () => {
      if (namespaceId == null) return
      try {
        const file: any | undefined = getValues('logo') //TODO: check
        if (file != null && file instanceof File) {
          const base64Logo = await blobToBase64(file)
          if (base64Logo) {
            await namespaceAddLogoMutation.mutateAsync({ id: namespaceId, base64Logo })
          }
        } else {
          const {
            name,
            shortDescription,
            description,
            customDomain,
            primaryColor,
            secondaryColor,
            maxCharacters,
          } = getValues()

          updateNamespace.mutateAsync({
            id: namespaceId,
            data: {
              name,
              shortDescription,
              description,
              customDomain,
              primaryColor,
              secondaryColor,
              maxCharacters,
            },
          })
        }
      } catch (error: any) {
        if (error?.validationErrors?.body) {
          const newErrors: any = {}
          if (error?.validationErrors?.body?.customDomain) {
            newErrors.customDomain = error?.validationErrors?.body?.customDomain.message
          }
          setError('global', { message: newErrors })
        }
      }
    }

    handleChange()
  }, [
    watch('name'),
    watch('logo'),
    watch('primaryColor'),
    watch('secondaryColor'),
    watch('shortDescription'),
    watch('description'),
    watch('customDomain'),
  ])

  return (
    <div className={styles.namespaceConfig}>
      <section className={styles.namespaceConfigContent}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Name"
              placeholder="Name"
              error={errors.name?.message}
              {...field}
              name="name"
              type="text"
            />
          )}
        />
        <Controller
          control={control}
          name="logo"
          render={({ field }) => (
            <InputFile
              className={styles.input}
              title="Logo"
              placeholder="logo"
              error={errors.logo}
              {...field}
              name="logo"
              allowedFileTypes={allowedImageTypes}
            />
          )}
        />
        <Controller
          control={control}
          name="primaryColor"
          render={({ field }) => (
            <InputV3
              className={styles.input}
              placeholder="Primary Color"
              {...field}
              error={errors.primaryColor?.message}
              suggestColors={getValues('suggestColors')}
              name="primaryColor"
              type="color"
            />
          )}
        />
        <Controller
          control={control}
          name="secondaryColor"
          render={({ field }) => (
            <InputV3
              className={styles.input}
              placeholder="Secondary Color"
              error={errors.secondaryColor?.message}
              {...field}
              suggestColors={getValues('suggestColors')}
              name="secondaryColor"
              type="color"
            />
          )}
        />
        <Controller
          control={control}
          name="shortDescription"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Short description"
              placeholder="short description"
              {...field}
              error={errors.shortDescription?.message}
              name="shortDescription"
              type="text"
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Textarea
              className={styles.input}
              title="Description"
              placeholder="description"
              error={errors.description?.message}
              {...field}
              name="description"
              type="text"
            />
          )}
        />

        <Controller
          control={control}
          name="customDomain"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Custom Domain"
              placeholder=""
              {...field}
              error={errors.customDomain?.message}
              name="customDomain"
              type="text"
            />
          )}
        />
      </section>
    </div>
  )
}

interface NamespaceConfigProps {
  namespace?: ServerPublicNamespace
}

const NamespaceConfig: React.FC<NamespaceConfigProps> = ({ namespace }) => {
  if (namespace == null) return null

  return <NamespaceConfigDetails namespace={namespace} />
}
