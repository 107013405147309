import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

type ContextState = {
  isMobileView: boolean,
  isLargeScreen: boolean
}

export const MediaQueryContext = React.createContext<ContextState>({
  isMobileView: false,
  isLargeScreen: false,
});

const mediaQueries = {
  mobile: '(max-width: 767px)',
  largeScreen: '(min-width: 1366px)'
};

export const MediaQueryProvider: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({children}) => {
  const isMobileView = useMediaQuery(mediaQueries.mobile);
  const isLargeScreen = useMediaQuery(mediaQueries.largeScreen);
  const value = React.useMemo(() => ({isMobileView, isLargeScreen}), [isMobileView, isLargeScreen]);

  return <MediaQueryContext.Provider value={value}>
    {children}
  </MediaQueryContext.Provider>
}

export default MediaQueryProvider;

export const useMediaQueryContext = () => React.useContext(MediaQueryContext)