import clsx from 'clsx'
import { sortBy } from 'lodash'
import React from 'react'
import { useSearch } from '../../hooks/useSearch'
import { PaginatorChevronLeft, PaginatorChevronRight } from '../../toneIcons'
import { isNotNull } from '../../utils'
import { SquareSelect } from '../SquareSelect/SquareSelect'
import styles from './TablePaginator.module.scss'

interface TablePaginatorProps extends React.HTMLAttributes<HTMLDivElement> {
  beforePageId?: string
  afterPageId?: string
  limit?: number
  defaultLimit: number
}

export const TablePaginator: React.FC<TablePaginatorProps> = ({
  limit,
  defaultLimit,
  beforePageId,
  afterPageId,
}) => {
  const [, setSearch] = useSearch()
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = Number.parseInt(e.target.value)

    setSearch((s) => ({
      ...s,
      limit: isNaN(limit) ? undefined : limit,
      beforePageId: undefined,
      afterPageId: undefined,
    }))
  }

  const selectArray = sortBy(
    Array.from(
      new Set(
        [
          ...Array.from({ length: 3 }, (_, i) => defaultLimit * (i + 1)),
          limit,
          defaultLimit,
        ].filter(isNotNull)
      )
    )
  )
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <span>SHOW</span>
        <SquareSelect value={limit} onChange={onChange}>
          {selectArray.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </SquareSelect>
      </div>

      {beforePageId && (
        <div
          className={clsx(styles.prev, styles.active)}
          onClick={() => setSearch((s) => ({ ...s, beforePageId, afterPageId: undefined }))}
        >
          <PaginatorChevronLeft />
          <span>PREV</span>
        </div>
      )}
      {!beforePageId && (
        <div className={styles.prev}>
          <PaginatorChevronLeft />
          <span>PREV</span>
        </div>
      )}

      {afterPageId && (
        <div
          className={clsx(styles.next, styles.active)}
          onClick={() => setSearch((s) => ({ ...s, afterPageId, beforePageId: undefined }))}
        >
          <span>NEXT</span>
          <PaginatorChevronRight />
        </div>
      )}

      {!afterPageId && (
        <div className={styles.next}>
          <span>NEXT</span>
          <PaginatorChevronRight />
        </div>
      )}
    </div>
  )
}
