import { getAuth, signOut } from 'firebase/auth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ServerPublicNamespace } from '../../utils/trpc'
import { LoadingContainer } from '../loading/loading'

interface LogoutProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerPublicNamespace
}

export const Logout: React.FC<LogoutProps> = ({ namespace }) => {
  const navigate = useNavigate()

  React.useEffect(() => {
    const fn = async () => {
      await signOut(getAuth())
      navigate('/login')
    }
    fn()
  }, [navigate])

  return <LoadingContainer namespace={namespace} />
}

export default Logout
