import clsx from 'clsx'
import React from 'react'
import { useMediaQueryContext } from '../../contexts'
import { useKey } from '../../hooks/useKey'
import styles from './shortcutAnswer.module.css'

interface ShortcutAnswerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  focus?: boolean
  shortcutKey: string
  activeId?: number
  setActiveId: React.Dispatch<React.SetStateAction<number | undefined>>
  
  selected: boolean
  onSelectAnswer?: (n: number) => void,
  value: number,
  label?: string,
}

const animationTimeMS = 500
export const ShortcutAnswer: React.FC<ShortcutAnswerProps> = ({
  value,
  label,
  focus,
  shortcutKey,
  selected,
  activeId,
  setActiveId,
  onSelectAnswer,
}) => {
  const { isMobileView } = useMediaQueryContext()

  const answerId = value
  const isActive = activeId === answerId
  // React.useEffect(() => {
  //   if(isActive) {
  //     console.log("focux", focus, selected)
  //   }
  //   if (isActive && focus) {
  //     const timeout = setTimeout(() => onSelectAnswer?.(answerId), animationTimeMS + 200)
  //     return () => clearTimeout(timeout)
  //   }
  // }, [isActive, answerId, focus, onSelectAnswer])

  useKey(
    [`${shortcutKey}`],
    () => {
      if (focus && !isMobileView) {
        setActiveId(isActive ? undefined : answerId)
        setTimeout(() => onSelectAnswer?.(answerId), animationTimeMS)
      }
    },
    { when: shortcutKey != null && focus && !isMobileView }
  )

  const style: any = {
    '--animation-time': `${animationTimeMS}ms`,
  }

  const className = clsx(
    styles.multiAnswer,
    isActive || selected ? styles.selected : null,
    !isActive && selected ? styles.deselecting : null
  )

  const onButtonClick = () => {
    setActiveId(isActive ? undefined : answerId)
    setTimeout(() => onSelectAnswer?.(answerId), animationTimeMS)
  }

  return (
    <button
      className={className}
      style={style}
      onClick={onButtonClick}
    >
      {!isMobileView && (
        <span className={styles.answerKeyWrap}>
          <span className={styles.answerKey}>Key</span>
          <span className={styles.answerShortcutKey}>{shortcutKey}</span>
        </span>
      )}
      <span>{label}</span>
    </button>
  )
}

export default ShortcutAnswer
