import { motion } from 'framer-motion'
import React, { useRef } from 'react'
import { DelayIcon } from '../../../currentColorIcons'
import { ServerListeningPostSlide } from '../../../utils/trpc'
import { useUpdateListeningPostSlideTrpc } from '../../../trpcHooks/listeningPost/useUpdateListeningPostSlideTrpc'
import KeyDownInput from '../shared/KeyDownInput'
import pluralize from 'pluralize'
import styles from './SlideDuration.module.scss'
import clsx from 'clsx'

interface Props {
  slide: ServerListeningPostSlide
  namespaceId: string
  listeningPostId: string
}

export const SlideDuration: React.FC<Props> = ({ namespaceId, listeningPostId, slide }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [duration, setDuration] = React.useState(slide.duration)
  const updateSlideMutation = useUpdateListeningPostSlideTrpc()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === "") {
      setDuration(undefined)
    } else {
      const duration = Number.parseInt(e.target.value)
      setDuration( isNaN(duration) ? undefined : duration )
    }
  }

  const durationString = duration != null ? `${duration}` : ""
  const classList = clsx(
    styles.container,
    duration == null && styles.empty
  )
  return (
    <motion.label className={classList} exit={{ height: 0, opacity: 0 }}>
      <DelayIcon className={styles.icon} />
      <KeyDownInput
        ref={inputRef}
        value={duration ?? "0"}
        type="number"
        inputMode="numeric"
        min={1}
        style={{ width: durationString.toString().length + 'ch' }}
        onChangeDebounced={(stringDuration) => {
          const duration = stringDuration === "" ? null : Number.parseInt(stringDuration)
          updateSlideMutation.mutate({
            namespaceId,
            listeningPostId,
            slideId: slide.id,
            data: { duration },
          })
        }}
        onChange={onChange}
      />
      <span className={styles.seconds}>{pluralize('seconds', duration)}</span>
    </motion.label>
  )
}

export default SlideDuration
