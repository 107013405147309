import React from 'react'
import styles from './characterCircle.module.scss'

interface CircleProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  filledRatio: number,
}

const Circle: React.FC<CircleProps> = ({filledRatio}) => {
  const R = 14;
  const circumference = 2*R*Math.PI;
  return <svg viewBox="0 0 30 30">
    <circle cx="50%" cy="50%" r={R} className={styles.circlePath} />
    <circle cx="50%" cy="50%" r={R} className={styles.activeCirclePath} strokeDasharray={circumference} strokeDashoffset={circumference*(1 - filledRatio)} />
  </svg>
}

interface CharacterCircleProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  used: number,
  max: number
}

export const CharacterCircle: React.FC<CharacterCircleProps> = ({used, max}) => {
  const left = max - used;
  let classNameList = [styles.container];
  if(left <= -10) {
    classNameList.push(styles.giveUp)
  } else if(left <= 0) {
    classNameList.push(styles.error)
  } else if(left <= 20) {
    classNameList.push(styles.warning)
  }
  return <div className={styles.outerContainer}>
    <div className={classNameList.join(' ')}>
      <Circle filledRatio={used/max} />
      {<div className={styles.text}>{left}</div>}
    </div>
    <div className={styles.textSmall}>characters</div>
  </div>
}

export default CharacterCircle
