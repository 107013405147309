import clsx from 'clsx'
import React from 'react'
import { namespaceStyle } from '../../utils'
import { ServerPublicNamespace } from '../../utils/trpc'
import styles from './loading.module.css'

export const GridSpinner: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = ({
  className,
}) => {
  const SIZE = 105
  const R = 12.5
  return (
    <svg
      width={SIZE}
      height={SIZE}
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      className={clsx(styles.grid, className)}
    >
      <circle cx={R} cy={R} r={R} />
      <circle cx={SIZE / 2} cy={R} r={R} />
      <circle cx={SIZE - R} cy={R} r={R} />

      <circle cx={R} cy={SIZE / 2} r={R} />
      <circle cx={SIZE / 2} cy={SIZE / 2} r={R} />
      <circle cx={SIZE - R} cy={SIZE / 2} r={R} />

      <circle cx={R} cy={SIZE - R} r={R} />
      <circle cx={SIZE / 2} cy={SIZE - R} r={R} />
      <circle cx={SIZE - R} cy={SIZE - R} r={R} />
    </svg>
  )
}

export const Loading: React.FC<React.HtmlHTMLAttributes<HTMLElement>> = ({
  className,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <GridSpinner />
    </div>
  )
}

interface LoadingContainerProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerPublicNamespace
}

export const LoadingContainer: React.FC<LoadingContainerProps> = ({
  className,
  namespace,
  style,
}) => {
  const _style = namespaceStyle(namespace, style)
  return (
    <div className={clsx(styles.fullContainer, className)} style={_style}>
      <div className={styles.content}>
        <GridSpinner />
      </div>
    </div>
  )
}

interface SpinnerProps extends React.SVGAttributes<HTMLOrSVGElement> {
  color: string
}

export const Spinner: React.FC<SpinnerProps> = React.memo(
  ({ className, color = 'white' }) => {
    return (
      <div className={clsx(styles.spinnerContainer, className)}>
        <svg className={styles.spinner} viewBox="0 0 50 50">
          <circle
            stroke={color}
            className={styles.path}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="3"
          />
        </svg>
      </div>
    )
  }
)

export const Spinner2: React.FC<SpinnerProps> = React.memo(
  ({ className, color = 'white' }) => {
    return (
      <svg className={clsx(styles.spinner2, className)} viewBox="0 0 50 50">
        <circle
          stroke={color}
          className={styles.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="3"
        />
      </svg>
    )
  }
)

export default Loading
