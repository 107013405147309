import React from 'react'
import { SlideTitleInput } from '../../shared/slide-card/SlideTitleInput/SlideTitleInput'

interface Props {
  defaultValue?: string
  onUpdate?: (text: string) => void
  placeholder?: string
  onDelete?: () => void,
}

export const Text: React.FC<Props> = ({ defaultValue, onUpdate, onDelete }) => {
  const [text, setText] = React.useState(defaultValue || '')

  const onChange = (text: string) => {
    setText(text)
    onUpdate?.(text)
  }

  return <SlideTitleInput defaultValue={text} onUpdate={onChange} placeholder="subtitle" onDelete={onDelete} />
}
