import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { PillEye, TrashIcon } from '../../currentColorIcons'
import { useUpdateNamespaceTrpc } from '../../trpcHooks/namespace/useUpdateNamespaceTrpc'
import { allowedImageTypes, blobToBase64, createBaseUrl, namespaceStyle } from '../../utils'
import { ServerNamespace, trpc } from '../../utils/trpc'
import { FullIconAnchor, FullIconButton } from '../button/button'
import DeletePopup from '../../components2/DeletePopup/DeletePopup'
import { DetailHeader } from '../../components2/DetailHeader/DetailHeader'
import { Input, Textarea } from '../input/input'
import { InputFile } from '../InputFile/InputFile'
import { Input as InputV3 } from '../inputV3/input'
import { Scrollable } from '../../components2/Scrollable/Scrollable'
import styles from './namespaceDetail.module.scss'
import { Tooltip } from '../Tooltip'

interface NamespaceDetailProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerNamespace
  onClose?: () => void
}

export const NamespaceDetail: React.FC<NamespaceDetailProps> = ({ namespace, onClose }) => {
  const [namespaceIdToBeDeleted, setNamespaceIdToBeDeleted] = React.useState<string>()
  const configQuery = trpc.config.info.useQuery()
  const utils = trpc.useContext()
  const namespaceDeleteMutation = trpc.namespace.delete.useMutation({
    onSuccess: () => {
      utils.namespace.all.invalidate()
    },
  })

  const deleteConfirmationHandler = async () => {
    if (namespaceIdToBeDeleted == null) return

    setNamespaceIdToBeDeleted(undefined)
    const namespaceId = namespace?.id
    if (namespaceId != null) {
      namespaceDeleteMutation.mutateAsync({ id: namespaceId })
      onClose?.()
    }
  }
  const portalAdminPath = createBaseUrl(configQuery.data, namespace?.id, '/admin')
  return (
    <>
      <DetailHeader title="Edit Namespace" onClose={onClose}>
        <Tooltip label="Portals">
          <FullIconAnchor icon={<PillEye />} href={portalAdminPath} />
        </Tooltip>
        <Tooltip label="Delete Namespace">
          <FullIconButton
            icon={<TrashIcon />}
            onClick={() => setNamespaceIdToBeDeleted(namespace?.id)}
            buttonType="delete"
          />
        </Tooltip>
      </DetailHeader>
      {namespace && <NamespaceDetailWithData namespace={namespace} />}

      {namespaceIdToBeDeleted && (
        <DeletePopup
          onSubmit={deleteConfirmationHandler}
          onClose={() => setNamespaceIdToBeDeleted(undefined)}
        />
      )}
    </>
  )
}

interface NamespaceDetailWithDataProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerNamespace
}

const NamespaceDetailWithData: React.FC<NamespaceDetailWithDataProps> = ({ namespace }) => {
  const namespaceId = namespace.id
  const namespaceAddLogoMutation = trpc.namespace.addLogo.useMutation()
  const updateNamespace = useUpdateNamespaceTrpc()

  const {
    getValues,
    watch,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: {
      global: '',
      customDomain: '',
      suggestColors: [],
      ...namespace,
    },
  })

  React.useEffect(() => {
    const handleChange = async () => {
      try {
        const file: any | undefined = getValues('logo') //TODO: check
        if (file && file instanceof File) {
          const base64Logo = await blobToBase64(file)
          if (base64Logo) {
            await namespaceAddLogoMutation.mutateAsync({ id: namespaceId, base64Logo })
          }
        } else {
          const {
            name,
            shortDescription,
            description,
            customDomain,
            primaryColor,
            secondaryColor,
            maxCharacters,
          } = getValues()

          updateNamespace.mutateAsync({
            id: namespaceId,
            data: {
              name,
              shortDescription,
              description,
              customDomain,
              primaryColor,
              secondaryColor,
              maxCharacters,
            },
          })
        }
      } catch (error: any) {
        if (error?.validationErrors?.body) {
          const newErrors: any = {}
          if (error?.validationErrors?.body?.customDomain) {
            newErrors.customDomain = error?.validationErrors?.body?.customDomain.message
          }
          setError('global', { message: newErrors })
        }
      }
    }
    handleChange()
  }, [
    watch('name'),
    watch('logo'),
    watch('primaryColor'),
    watch('secondaryColor'),
    watch('shortDescription'),
    watch('description'),
    watch('customDomain'),
    watch('maxCharacters'),
  ])

  const style = namespaceStyle(namespace)

  return (
    <div className={styles.container} style={style}>
      <Scrollable innerClassName={styles.content}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Name"
              placeholder="Name"
              error={errors.name?.message}
              {...field}
              name="name"
              type="text"
            />
          )}
        />
        <Controller
          control={control}
          name="logo"
          render={({ field }) => (
            <InputFile
              className={styles.input}
              title="Logo"
              placeholder="logo"
              error={errors.logo?.message}
              {...field}
              name="logo"
              allowedFileTypes={allowedImageTypes}
              showDelete
            />
          )}
        />
        <Controller
          control={control}
          name="primaryColor"
          render={({ field }) => (
            <InputV3
              className={styles.input}
              placeholder="Primary Color"
              {...field}
              suggestColors={getValues('suggestColors')}
              name="primaryColor"
              type="color"
            />
          )}
        />
        <Controller
          control={control}
          name="secondaryColor"
          render={({ field }) => (
            <InputV3
              className={styles.input}
              placeholder="Secondary Color"
              error={errors.secondaryColor?.message}
              {...field}
              suggestColors={getValues('suggestColors')}
              name="secondaryColor"
              type="color"
            />
          )}
        />
        <Controller
          control={control}
          name="shortDescription"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Short description"
              placeholder="short description"
              {...field}
              error={errors.shortDescription?.message}
              name="shortDescription"
              type="text"
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Textarea
              className={styles.input}
              title="Description"
              placeholder="description"
              error={errors.description?.message}
              {...field}
              name="description"
              type="text"
            />
          )}
        />

        <Controller
          control={control}
          name="customDomain"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Custom Domain"
              placeholder=""
              {...field}
              error={errors.customDomain?.message}
              name="customDomain"
              type="text"
            />
          )}
        />

        <Controller
          control={control}
          name="maxCharacters"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="Max Characters"
              placeholder=""
              {...field}
              error={errors.maxCharacters?.message}
              name="maxCharacters"
              type="number"
            />
          )}
        />
      </Scrollable>
    </div>
  )
}

export default NamespaceDetail
