import _ from 'lodash'
import { parse as queryParse, stringify as queryStringify } from 'query-string'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import superjson from 'superjson'
import z from 'zod'

const strToInt = (str: string, ctx: z.RefinementCtx) => {
  try {
    return parseInt(str, 10)
  } catch (e) {
    ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'not an integer text' })
    return z.NEVER
  }
}

const strToBool = (str: string, ctx: z.RefinementCtx) => {
  try {
    return Boolean(str)
  } catch (e) {
    ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'not a boolean text' })
    return z.NEVER
  }
}

const searchParamsSchema = z.object({
  limit: z.string().transform(strToInt).optional(),
  perPage: z.string().transform(strToInt).optional(),
  query: z.string().optional(),
  afterPageId: z.string().optional(),
  beforePageId: z.string().optional(),
  id: z.string().optional(),
  name: z.string().optional(),
  emailPhone: z.string().optional(),
  ogEdit: z.string().transform(strToBool).optional(),
  tab: z.string().optional(),
  tags: z.string().optional(),
  sentiment: z.string().transform(strToInt).optional(),
  untagged: z.string().transform(strToBool).optional(),
  code: z.string().optional(),
})

export type SearchParams = z.infer<typeof searchParamsSchema>
export type SetSearchParams = SearchParams | ((old: SearchParams) => SearchParams)
export type SetSearchFn = (dataOrFn: SetSearchParams) => void

export const useSearch = (): [SearchParams, SetSearchFn] => {
  const location = useLocation()
  const navigate = useNavigate()
  const locationPathname = location?.pathname
  const locationSearch = location?.search

  const search = React.useMemo(() => {
    if (locationSearch) {
      const parsed = queryParse(locationSearch)

      // if parsed has extras, unstringify it
      const withHandledExtras =
        parsed.extras != null && _.isString(parsed.extras)
          ? { ...parsed, extras: superjson.parse(parsed.extras) }
          : parsed

      const validated = searchParamsSchema.safeParse(withHandledExtras)
      if (validated.success === false) {
        console.error('failed to parse location string', validated.error)
        return {}
      }

      return validated.data
    } else return {}
  }, [locationSearch])

  const setSearch = React.useCallback(
    (dataOrFn: SetSearchParams) => {
      if (navigate == null) return

      const data = typeof dataOrFn === 'function' ? dataOrFn(search) : dataOrFn
      const dataAdapted: any = data
      // dataAdapted.tags = data.tags?.join(',')

      const newSearch = queryStringify(dataAdapted)
      if (newSearch) navigate(`${locationPathname}?${newSearch}`)
      else navigate(locationPathname)
    },
    [locationPathname, search, navigate]
  )

  return [search, setSearch]
}