import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SlidesDisplayProvider } from '../components/DisplayListeningPortal/state/slides-display.context'
import { SlidesDesignerProvider } from '../DesignListeningPortal/state/slides-designer.context'
import { theme } from '../mui-theme'
import { AuthProvider } from './auth.context'
import { MediaQueryProvider } from './mediaQuery'

export { useAuthContext } from './auth.context'
export { useMediaQueryContext } from './mediaQuery'

export const AppProviders = ({ children }: any) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: false,
      },
    },
  })

  return (
    <AuthProvider>
      <MediaQueryProvider>
        <SlidesDesignerProvider>
          <QueryClientProvider client={queryClient}>
            <SlidesDisplayProvider>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </SlidesDisplayProvider>
          </QueryClientProvider>
        </SlidesDesignerProvider>
      </MediaQueryProvider>
    </AuthProvider>
  )
}
