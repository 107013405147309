import React from 'react'
import clsx from 'clsx'
import styles from './OpenEndTextarea.module.scss'

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isLittleCard?: boolean
}

export const OpenEndTextarea: React.FC<Props> = ({ className, isLittleCard, ...props }) => {
  const [, setWidth] = React.useState<number>()

  const ref = React.useRef<HTMLTextAreaElement>(null)

  React.useLayoutEffect(() => {
    if (ref.current == null) return

    const measure = () => {
      window.requestAnimationFrame(() => setWidth(document.documentElement.clientWidth))
    }
    measure()

    window.addEventListener('resize', measure)
    window.addEventListener('scroll', measure)

    return () => {
      window.removeEventListener('resize', measure)
      window.removeEventListener('scroll', measure)
    }
  }, [ref])

  return (
    <textarea
      ref={ref}
      className={clsx(isLittleCard ? styles.textareaLittle : styles.container, className)}
      rows={3}
      {...props}
    />
  )
}
