import clsx from 'clsx'
import React from 'react'
import BackgroundPickerTrigger from '../../../../../components/BackgroundPicker/BackgroundPickerTrigger'
import {
  ServerListeningPostSlide,
  ServerListeningPostSlideBackground,
  ServerNamespace,
} from '../../../../../utils/trpc'
import styles from './SlideCoverButton.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  slide: ServerListeningPostSlide
  hasCover: boolean
  namespace: ServerNamespace
  onSelectBg: (bg: ServerListeningPostSlideBackground) => void
  onDeleteBg?: () => void
}

export const SlideCoverButton: React.FC<Props> = ({
  onSelectBg,
  onDeleteBg,
  className,
  hasCover,
  namespace,
  slide,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <BackgroundPickerTrigger
        onSelect={onSelectBg}
        onDelete={onDeleteBg}
        namespace={namespace}
        backgroundType={slide.background?.type}
      >
        {(handleClick) => (
          <button className={styles.button} onClick={handleClick}>
            {hasCover ? 'Change cover' : 'Add cover'}
          </button>
        )}
      </BackgroundPickerTrigger>
    </div>
  )
}
