import { ServerListeningPostUpdate, trpc } from '../../utils/trpc'

export const useUpdateListeningPostTrpc = () => {
  const utils = trpc.useContext()
  return trpc.listeningPost.update.useMutation({
    onMutate: ({ namespaceId, id, data }) => {
      utils.listeningPost.id.setData({ namespaceId, id }, (listeningPost) => {
        const updateObj: ServerListeningPostUpdate = {}
        if (data.ogSlideId !== undefined) {
          updateObj.ogSlideId = data.ogSlideId
        }
        if (data.title !== undefined) {
          updateObj.title = data.title
        }
        if (data.publicName !== undefined) {
          updateObj.publicName = data.publicName
        }
        
        return Object.assign({}, listeningPost, updateObj)
      })
    },

    onSuccess: (_, { namespaceId, id, data }) => {
      utils.listeningPost.id.invalidate({ namespaceId, id })
      if (data?.url !== undefined) {
        utils.namespace.id.invalidate({ id: namespaceId })
      }
    },
    onError: (_, { namespaceId, id, data }) => {
      utils.listeningPost.id.invalidate({ namespaceId, id })
      if (data?.url !== undefined) {
        utils.namespace.id.invalidate({ id: namespaceId })
      }
    },
  })
}
