import { TRPCClientError } from '@trpc/client'
import clsx from 'clsx'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { isArray } from 'lodash'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { namespaceStyle } from '../../utils'
import { ServerPublicNamespace, trpc } from '../../utils/trpc'
import { Input } from '../input/input'
import styles from './auth.module.scss'

interface SignupProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerPublicNamespace
}

const defaultValues = {
  global: '',
  email: '',
  password: '',
  password2: '',
}

export const Signup: React.FC<SignupProps> = ({ namespace }) => {
  const {
    watch,
    getValues,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    clearErrors,
    control,
  } = useForm({
    defaultValues,
  })

  const signupMutation = trpc.user.signup.useMutation()

  const isDisabled = !watch('email') || !watch('password') || !watch('password2')

  const onSubmit = async () => {
    try {
      const email = getValues('email').trim()
      const password = getValues('password')
      const password2 = getValues('password2')

      if (password !== password2) {
        setError('password2', { type: 'custom', message: 'Passwords need to match' })
        return
      }
      await signupMutation.mutateAsync({ email, password })
      await signInWithEmailAndPassword(getAuth(), email, password)
    } catch (error: unknown) {
      if (error instanceof TRPCClientError) {
        switch (error.message) {
          case 'auth/email-already-exists': {
            setError('email', { type: 'custom', message: 'Email is invalid or exists' })
            break
          }
          case 'auth/invalid-email': {
            setError('email', { type: 'custom', message: 'Email is invalid or exists' })
            break
          }
          case 'auth/admin-restricted-operation': {
            setError('global', { type: 'custom', message: 'Unable to sign up at the moment' })
            break
          }
          case 'auth/invalid-password': {
            setError('password', {
              type: 'custom',
              message: 'Password must have at least 6 characters',
            })
            break
          }
        }
      }
    }
  }

  return (
    <form
      className={clsx(styles.container, isSubmitting ? styles.loading : null)}
      style={namespaceStyle(namespace)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.content}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="EMAIL ADDRESS"
              placeholder="email address"
              error={errors.email?.message}
              {...field}
              autoCapitalize="off"
              type="text"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="PASSWORD"
              placeholder="password"
              error={errors.password?.message}
              {...field}
              autoCapitalize="off"
              type="password"
            />
          )}
        />
        <Controller
          control={control}
          name="password2"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="CONFIRM PASSWORD"
              placeholder="retype password"
              error={errors.password2?.message}
              {...field}
              autoCapitalize="off"
              name="password2"
              type="password"
            />
          )}
        />
        {errors.global && (
          <div className={styles.globalError}>
            {!isArray(errors.global.message)
              ? errors.global.message
              : errors.global.message.map((error: string, key: number) => (
                  <p key={key}>{`* ${error}`}</p>
                ))}
          </div>
        )}
      </div>
      <button
        className={styles.button}
        onClick={() => clearErrors('global')}
        disabled={isDisabled}
        type="submit"
      >
        Sign up
      </button>
      <div className={styles.linkRow}>
        <Link to="../login">Have an account? Log in</Link>
      </div>
    </form>
  )
}

export default Signup
