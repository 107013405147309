import React from 'react';
import { Link } from "react-router-dom";
import styles from './CreateLink.module.scss';


interface CreateLinkProps {
  to: string
}

export const CreateLink: React.FC<CreateLinkProps> = ({to, children}) => {
  return (
    <Link className={styles.create} to={to}>
      + {children ? children : "Create"}
    </Link>
  );
}

export const CreateButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({onClick, children}) => {
  return (
    <button className={styles.create} onClick={onClick}>
      + {children ? children : "Create"}
    </button>
  );
}