import { TinyColor } from '@ctrl/tinycolor'
import { TRPCClientError } from '@trpc/client'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { allowedImageTypes, blobToBase64, getVibrantColors } from '../../utils'
import { ServerNamespaceCreate, trpc } from '../../utils/trpc'
import { InputFile } from '../InputFile/InputFile'
import { Input } from '../input/input'
import { Input as InputV3 } from '../inputV3/input'
import Popup from '../popup/popup'
import styles from './createNamespacePopup.module.scss'

interface CreateNamespacePopupProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  onClose: () => void
}

interface FormFields {
  global: string
  id: string
  name: string
  primaryColor: string
  secondaryColor: string
  suggestColors: string[]
  idSuggest?: string
  logo?: FileList | string
}

export const CreateNamespacePopup: React.FC<CreateNamespacePopupProps> = ({ onClose }) => {
  const utils = trpc.useContext()
  //not doing optimistic update because pagination messes it up
  const createNamespace = trpc.namespace.create.useMutation({
    onSuccess: async () => {
      utils.namespace.all.invalidate()
    },
  })
  const namespaceAddLogoMutation = trpc.namespace.addLogo.useMutation()

  const {
    watch,
    getValues,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },

    control,
  } = useForm<FormFields>({
    defaultValues: {
      global: '',
      id: '',
      name: '',
      primaryColor: '',
      secondaryColor: '',
      suggestColors: [],
    },
  })

  const isDisabled =
    !watch('name') ||
    !watch('id') ||
    (!watch('primaryColor') && !(watch('suggestColors')?.length > 0)) ||
    (!watch('secondaryColor') && !(watch('suggestColors')?.length > 0))

  const logoUrl = watch('logo')
  const primaryColor = watch('primaryColor')
  const secondaryColor = watch('secondaryColor')

  React.useEffect(() => {
    if (logoUrl === undefined) return

    const isString = typeof logoUrl === 'string'
    const isAllowedFile = logoUrl instanceof File && allowedImageTypes.indexOf(logoUrl.type) >= 0

    if (isString) {
      if (logoUrl?.startsWith(window.location.origin)) {
        getVibrantColors(logoUrl, [primaryColor, secondaryColor]).then((suggestColors) => {
          setValue('suggestColors', suggestColors)
        })
      }
    } else if (isAllowedFile) {
      const img = new Image()
      img.src = URL.createObjectURL(logoUrl)
      img.addEventListener('load', () => {
        getVibrantColors(img, [primaryColor, secondaryColor]).then((suggestColors) => {
          setValue('suggestColors', suggestColors)
        })
      })
    } else {
      setValue('suggestColors', [])
    }
  }, [logoUrl, primaryColor, secondaryColor])

  React.useEffect(() => {
    const nameValue = getValues('name')
    const idValue = nameValue?.replace(/\s+/g, '-').substr(0, 20)

    setValue('id', idValue)
  }, [watch('name')])

  const submit = async () => {
    const id = getValues('id')
    const name = getValues('name')
    if (id !== '') {
      const valuesToSubmit: ServerNamespaceCreate = { id, name }
      try {
        const color = new TinyColor(getValues('primaryColor') || getValues('suggestColors')?.[0])
        valuesToSubmit.primaryColor = color.toHexString()
      } catch (error) {}
      try {
        const color = new TinyColor(getValues('secondaryColor') || getValues('suggestColors')?.[1])
        valuesToSubmit.secondaryColor = color.toHexString()
      } catch (error) {}

      try {
        const namespace = await createNamespace.mutateAsync(valuesToSubmit)
        const file = getValues('logo')

        if (file && file instanceof File && namespace?.id) {
          const base64Logo = await blobToBase64(file)
          if (base64Logo) {
            await namespaceAddLogoMutation.mutateAsync({ id: namespace.id, base64Logo })
          }
          onClose()
        } else if (namespace?.id) {
          onClose()
        }
      } catch (error: unknown) {
        if (error instanceof TRPCClientError)
          return setError('global', { type: 'custom', message: 'Invalid' })
        // setError('global', { type: 'custom', message: error.message })
      }
    }
  }

  return (
    <Popup onClose={onClose} onSubmit={handleSubmit(submit)}>
      <h4>Add a new brand</h4>

      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <Input
            className={styles.input}
            title="Name"
            placeholder="name"
            error={errors.name?.message}
            {...field}
            autoCapitalize="off"
            name="name"
            type="text"
          />
        )}
      />
      <Controller
        control={control}
        name="id"
        render={({ field }) => (
          <Input className={styles.input} title="URL Prefix" placeholder="URL Prefix" {...field} />
        )}
      />
      <Controller
        control={control}
        name="logo"
        render={({ field }) => (
          <InputFile
            className={styles.input}
            title="Logo"
            placeholder="Browse for logo..."
            {...field}
            error={errors.logo?.message}
            name="logo"
            allowedFileTypes={allowedImageTypes}
            showDelete
          />
        )}
      />

      <Controller
        control={control}
        name="primaryColor"
        render={({ field }) => (
          <InputV3
            className={styles.input}
            placeholder="Primary color"
            suggestColors={getValues('suggestColors')}
            error={errors.primaryColor?.message}
            {...field}
            name="primaryColor"
            type="color"
          />
        )}
      />
      <Controller
        control={control}
        name="secondaryColor"
        render={({ field }) => (
          <InputV3
            className={styles.input}
            placeholder="Secondary Color"
            suggestColors={getValues('suggestColors')}
            error={errors.secondaryColor?.message}
            {...field}
            name="secondaryColor"
            type="color"
          />
        )}
      />

      {errors.global && <div className={styles.globalErrors}>{errors.global.message}</div>}
      <button className={styles.button} disabled={isDisabled} type="submit">
        Create new brand
      </button>
    </Popup>
  )
}

export default CreateNamespacePopup
