import clsx from 'clsx'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { isArray } from 'lodash'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { namespaceStyle } from '../../utils'
import { ServerPublicNamespace } from '../../utils/trpc'
import { Input } from '../input/input'
import { GridSpinner } from '../loading/loading'
import styles from './auth.module.scss'

interface PasswordResetProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerPublicNamespace
}

export const PasswordReset: React.FC<PasswordResetProps> = ({ namespace }) => {
  const [success, setSuccess] = React.useState(false)
  const {
    watch,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    clearErrors,
    control,
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      global: '',
    },
  })

  const isDisabled = !watch('email')

  const onSubmit = async () => {
    try {
      const email = getValues('email').trim()

      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      setSuccess(true)
    } catch (error: any) {
      setError('global', { type: 'custom', message: error.message }) //TODO: divide by certain errors
    }
  }

  const namespaceLogoUrl = namespace?.logo

  return (
    <form
      className={clsx(styles.container, isSubmitting ? styles.loading : null)}
      onSubmit={handleSubmit(onSubmit)}
      style={namespaceStyle(namespace)}
    >
      {namespaceLogoUrl && (
        <div className={styles.logoContainer}>
          <img src={namespaceLogoUrl} alt={namespace?.name} />
        </div>
      )}
      <div className={styles.content}>
        {namespace && <GridSpinner className={styles.namespaceLoading} />}
        {!success && (
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                className={styles.input}
                title="EMAIL ADDRESS"
                placeholder="email address"
                error={errors.email?.message}
                {...field}
                autoCapitalize="off"
                name="email"
                type="text"
              />
            )}
          />
        )}
        {isSubmitSuccessful && (
          <div className={styles.check}>
            <p>Password reset link sent</p>
            <p>Please check your email ({getValues('email')})</p>
          </div>
        )}
        {errors.global && (
          <div className={styles.globalError}>
            {!isArray(errors.global.message)
              ? errors.global.message
              : errors.global.message.map((error: string) => <p>{`* ${error}`}</p>)}
          </div>
        )}
      </div>
      <button
        className={styles.button}
        onClick={() => clearErrors('global')}
        disabled={isDisabled}
        type="submit"
      >
        Reset Password
      </button>
      <div className={styles.linkRow}>
        <Link to="../login">Remember your password? Login!</Link>
      </div>
      <div className={styles.linkRow}>
        <Link to="../signup">Sign up instead?</Link>
      </div>
    </form>
  )
}

export default PasswordReset
