import React from 'react'
import { Cell } from '../Cell/Cell'
import { ColorPill, CreatePill, DeletablePill, EditPill, Pill } from '../../components/pills/pills'
import { Tooltip } from '../../components/Tooltip'
import { useMediaQueryContext } from '../../contexts'
import { PillEye } from '../../currentColorIcons'
import { useSearch } from '../../hooks/useSearch'
import { ServerNamespace } from '../../utils/trpc'

interface NamespaceCellsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerNamespace
  baseDomain?: string
  protocol?: string
  index: number
  onEditAdmins: () => void
}

export const NamespaceCells: React.FC<NamespaceCellsProps> = ({
  namespace,
  protocol,
  baseDomain,
  index,
  onEditAdmins,
}) => {
  const { isLargeScreen } = useMediaQueryContext()
  const [search, setSearch] = useSearch()
  const selectedNamespaceId = search.id

  const onClick = () => setSearch(s => ({...s, id: namespace.id}))
  const active = selectedNamespaceId != null && selectedNamespaceId === namespace.id

  const dataHasAdmins = (namespace.admins?.length || 0) > 0
  const firstAdmins = namespace.admins?.slice(0, 2)
  const remainingAdmins = namespace.admins?.slice(2)
  const remainingAdminsContent = remainingAdmins && (
    <div>
      {remainingAdmins.map((admin, index) => (
        <div key={index}>{admin}</div>
      ))}
    </div>
  )

  const windowPort =
    window.location.port != null && window.location.port.length > 0
      ? `:${window.location.port}`
      : ''

  const baseUrl = `${protocol}://${namespace.id}.${baseDomain}`
  const portalAdminPath = `${baseUrl}${windowPort}/admin`

  return (
    <>
      <Cell active={active} index={index} onClick={onClick} sticky>
        <span>{namespace.name}</span>
      </Cell>
      <Cell active={active} index={index} onClick={onClick}>
        {namespace.primaryColor && <ColorPill color={namespace.primaryColor} />}
      </Cell>
      <Cell active={active} index={index} onClick={onClick}>
        {namespace.secondaryColor && <ColorPill color={namespace.secondaryColor} />}
      </Cell>
      <Cell active={active} index={index} onClick={onClick}>
        {dataHasAdmins && (
          <>
            <EditPill onClick={onEditAdmins ? () => onEditAdmins() : undefined} />
            {firstAdmins?.map((email, index) => (
              <DeletablePill text={email} key={index} />
            ))}
            {(remainingAdmins?.length || 0) > 0 && (
              <Tooltip label={remainingAdminsContent}>
                <div>
                  <DeletablePill text={`${remainingAdmins?.length} more`} />
                </div>
              </Tooltip>
            )}
          </>
        )}
        {!dataHasAdmins && (
          <CreatePill
            text="invite via email"
            onClick={onEditAdmins ? () => onEditAdmins() : undefined}
          />
        )}
      </Cell>
      <Cell active={active} index={index} onClick={onClick}>
        <Pill
          icon={<PillEye />}
          text="Portals"
          withTooltip={!isLargeScreen}
          tagName="a"
          href={portalAdminPath}
        />
      </Cell>
    </>
  )
}
