import React from "react";
import { ColorTile } from "../Tile/Tile";
import { colorBackgroundsService } from "../color-backgrounds.service";
import styles from './ColorPicker.module.scss';
import { ServerListeningPostSlideBackground } from "../../../utils/trpc";

interface ColorPickerProps {
  onSelect: (bg: ServerListeningPostSlideBackground) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onSelect }) => {
  const [backgrounds, setBackgrounds] = React.useState<ServerListeningPostSlideBackground[]>([]);

  React.useEffect(() => {
    colorBackgroundsService.getAll().then((backgrounds) => {
      setBackgrounds(backgrounds);
    });
  }, []);

  return <div className={styles.colorPicker}>
    {backgrounds?.map((bg, i) => <ColorTile background={bg} onClick={() => onSelect(bg)} key={i} />)}
  </div>
};

export default ColorPicker;
