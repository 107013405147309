import { Popover } from '@mui/material'
import React from 'react'
import { ServerListeningPost } from '../../utils/trpc'
import PreviewBackgroundPicker from './PreviewBackgroundPicker/PreviewBackgroundPicker'

interface Props {
  listeningPost: ServerListeningPost
  generateImage: (slideId: string) => void
  children: (handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void) => JSX.Element
}

const PreviewBackgroundPickerTrigger: React.FC<Props> = ({
  children,
  generateImage,
  listeningPost,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        componentsProps={{
          backdrop: {
            invisible: false,
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <PreviewBackgroundPicker
          generateImage={(slideId) => {
            generateImage(slideId)
            handleClose()
          }}
          listeningPost={listeningPost}
        />
      </Popover>

      {children(handleClick)}
    </React.Fragment>
  )
}

export default PreviewBackgroundPickerTrigger
