import React from 'react'
import { ThinArrow } from '../../toneIcons'
import { namespaceStyle } from '../../utils'
import { BeatingHeart } from '../BeatingHeart/BeatingHeart'
import styles from './Error404.module.scss'

interface Error404Props extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const Error404: React.FC<Error404Props> = () => {
  const style = namespaceStyle()
  return (
    <div className={styles.container} style={style}>
      <BeatingHeart className={styles.heartBeating} />

      <h1>404</h1>
      <p className={styles.content}>
        This listening portal doesn't exist, or you may have reached this in error. If you are the
        admin or creator of this listening portal, visit your admin page or{' '}
        <a href="mailto:team@harmonize.ai">contact support.</a>
      </p>

      <a className={styles.button} href="https://listens.in">
        Go back to <strong>ListensIn</strong> by Harmonize <ThinArrow className={styles.arrow} />
      </a>
    </div>
  )
}

export default Error404
