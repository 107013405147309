import React from 'react'
import { useInView } from 'react-intersection-observer'
import { ServerListeningPostSlide } from '../../utils/trpc'
import { useSlidesDesignerContext } from '../../DesignListeningPortal/state/slides-designer.context'

export const useUiInteractions = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  slide: ServerListeningPostSlide & {scrollTo?: any}
) => {
  const [, dispatch] = useSlidesDesignerContext()
  const slideId = slide.id
  const sectionId = slide.section

  const { ref: inViewRef, inView } = useInView({ threshold: 0.8, delay: 200 })

  /**
   *  To combine useRef and inViewRef
   */
  const setRefs = React.useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function
      // that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef]
  )

  /**
   * Handles highlighting of left nav section title based
   * on currently visible slide (of any section)
   */
  React.useEffect(() => {
    if (inView) {
      dispatch({
        type: 'SET_VISIBLE_SLIDE',
        payload: {
          visibleSlideId: slideId,
          visibleSectionId: sectionId,
        },
      })
    }
  }, [inView, slideId, sectionId])

  /**
   * When a new slide is added to dom, scroll to that slide automatically.
   */
  const allowScrollTo = slide.scrollTo || false
  React.useEffect(() => {
    if (allowScrollTo) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [allowScrollTo, ref])

  return setRefs
}
