import { useLocalStorage } from '@rehooks/local-storage'
import clsx from 'clsx'
import React from 'react'
import { useMediaQueryContext } from '../../contexts'
import { ListeningPosts, ListensInLogo, Overview, TogglePanel, User } from '../../toneIcons'
import { ServerPublicNamespace, trpc } from '../../utils/trpc'
import { Spacer } from '../Spacer'
import styles from './LeftNav.module.scss'
import { LinkRow } from './LinkRow'

export const SuperadminLeftNav = () => {
  const { isMobileView } = useMediaQueryContext()
  const meQuery = trpc.user.me.useQuery()
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false)
  if (!meQuery.data) return null

  const isOpened = !(navMinimized || isMobileView)
  return (
    <div className={clsx(styles.container, isOpened ? styles.opened : null)}>
      <div className={styles.row}>
        <div className={styles.logo}>
          <ListensInLogo />
        </div>
        <div className={styles.rowText}>Listens.in</div>
      </div>
      <div className={styles.content}>
        <LinkRow icon={<Overview />} text="Brands" to="/admin" end />
      </div>

      <div className={styles.footer}>
        {meQuery.data?.email && (
          <LinkRow icon={<User />} text={meQuery.data.email} to="/admin/settings" />
        )}
        {!isMobileView && (
          <div className={styles.togglePanel} onClick={() => setNavMinimized(!navMinimized)}>
            <TogglePanel />
          </div>
        )}
      </div>
    </div>
  )
}

interface LeftNavProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
}

export const LeftNav: React.FC<LeftNavProps> = ({ namespace }) => {
  const { isMobileView } = useMediaQueryContext()
  const meQuery = trpc.user.me.useQuery()
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false)

  if (!meQuery.data) return null
  if (!namespace) return null

  const splitHost = window.location.host?.split('.')
  const adminHost = splitHost.slice(1).join('.')

  const isOpened = !(navMinimized || isMobileView)
  return (
    <div className={clsx(styles.container, isOpened ? styles.opened : null)}>
      <div className={styles.row}>
        {namespace?.logo && (
          <div className={styles.logo}>
            <img src={namespace.logo} alt={namespace.name} />
          </div>
        )}
        <div className={styles.rowText}>{namespace?.name}</div>
      </div>

      <div className={styles.content}>
        {meQuery.data?.isAdmin && (
          <a href={'//' + adminHost + '/admin'} className={styles.row}>
            <div className={styles.rowIcon}>
              <ListensInLogo />
            </div>
            <div className={styles.rowText}>Admin</div>
          </a>
        )}
        <LinkRow icon={<ListeningPosts />} text="Portals" to="/admin" end />
      </div>

      <Spacer />

      <div className={styles.footer}>
        {meQuery.data?.email && (
          <LinkRow icon={<User />} text={meQuery.data.email} to="/admin/settings" />
        )}
        {!isMobileView && (
          <div className={styles.togglePanel} onClick={() => setNavMinimized(!navMinimized)}>
            <TogglePanel />
          </div>
        )}
      </div>
    </div>
  )
}

export default LeftNav
