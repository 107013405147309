import { TinyColor } from '@ctrl/tinycolor'
import { pickBy, sortBy } from 'lodash'
import Vibrant from 'node-vibrant'
import React from 'react'
import { ServerConfigInfo, ServerListeningPost, ServerPublicNamespace } from './utils/trpc'

export const timeout = async (ms: number) => await new Promise((resolve) => setTimeout(resolve, ms))

export const namespaceStyle = (
  namespace?: { primaryColor?: string; secondaryColor?: string },
  initial: React.CSSProperties | undefined = {}
) => {
  const primaryColor = namespace?.primaryColor || '#524f9f'
  const secondaryColor = namespace?.secondaryColor || '#634592'
  return {
    ...initial,
    ...colorStyle(primaryColor, 'primary'),
    ...colorStyle(secondaryColor, 'secondary'),
  }
}

export const isDark = (color: TinyColor) => {
  const { r, g, b } = color.toRgb()
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq < 128
}

export const colorStyle = (color: string, prefix: string, initial = {}) => {
  const style: { [key: string]: string } = initial

  const onWhite = new TinyColor('#f9f9f9')
  const onBlack = new TinyColor('#1E1E1E')

  style[`--${prefix}-color`] = color
  try {
    style[`--${prefix}-w98-color`] = new TinyColor(color).mix(onWhite, 98).toHexString()
    style[`--${prefix}-b98-color`] = new TinyColor(color).mix(onBlack, 98).toHexString()
    style[`--${prefix}-w98-text-color`] = isDark(new TinyColor(color).mix(onWhite, 98))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b98-text-color`] = isDark(new TinyColor(color).mix(onBlack, 98))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w95-color`] = new TinyColor(color).mix(onWhite, 95).toHexString()
    style[`--${prefix}-b95-color`] = new TinyColor(color).mix(onBlack, 95).toHexString()
    style[`--${prefix}-w95-text-color`] = isDark(new TinyColor(color).mix(onWhite, 95))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b95-text-color`] = isDark(new TinyColor(color).mix(onBlack, 95))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w90-color`] = new TinyColor(color).mix(onWhite, 90).toHexString()
    style[`--${prefix}-b90-color`] = new TinyColor(color).mix(onBlack, 90).toHexString()
    style[`--${prefix}-w90-text-color`] = isDark(new TinyColor(color).mix(onWhite, 90))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b90-text-color`] = isDark(new TinyColor(color).mix(onBlack, 90))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w80-color`] = new TinyColor(color).mix(onWhite, 80).toHexString()
    style[`--${prefix}-b80-color`] = new TinyColor(color).mix(onBlack, 80).toHexString()
    style[`--${prefix}-w80-text-color`] = isDark(new TinyColor(color).mix(onWhite, 80))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b80-text-color`] = isDark(new TinyColor(color).mix(onBlack, 80))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w70-color`] = new TinyColor(color).mix(onWhite, 70).toHexString()
    style[`--${prefix}-b70-color`] = new TinyColor(color).mix(onBlack, 70).toHexString()
    style[`--${prefix}-w70-text-color`] = isDark(new TinyColor(color).mix(onWhite, 70))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b70-text-color`] = isDark(new TinyColor(color).mix(onBlack, 70))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w60-color`] = new TinyColor(color).mix(onWhite, 60).toHexString()
    style[`--${prefix}-b60-color`] = new TinyColor(color).mix(onBlack, 60).toHexString()
    style[`--${prefix}-w60-text-color`] = isDark(new TinyColor(color).mix(onWhite, 60))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b60-text-color`] = isDark(new TinyColor(color).mix(onBlack, 60))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w50-color`] = new TinyColor(color).mix(onWhite, 50).toHexString()
    style[`--${prefix}-b50-color`] = new TinyColor(color).mix(onBlack, 50).toHexString()
    style[`--${prefix}-w50-text-color`] = isDark(new TinyColor(color).mix(onWhite, 50))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b50-text-color`] = isDark(new TinyColor(color).mix(onBlack, 50))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w40-color`] = new TinyColor(color).mix(onWhite, 40).toHexString()
    style[`--${prefix}-b40-color`] = new TinyColor(color).mix(onBlack, 40).toHexString()
    style[`--${prefix}-w40-text-color`] = isDark(new TinyColor(color).mix(onWhite, 40))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b40-text-color`] = isDark(new TinyColor(color).mix(onBlack, 40))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w30-color`] = new TinyColor(color).mix(onWhite, 30).toHexString()
    style[`--${prefix}-b30-color`] = new TinyColor(color).mix(onBlack, 30).toHexString()
    style[`--${prefix}-w30-text-color`] = isDark(new TinyColor(color).mix(onWhite, 30))
      ? '#fefefe'
      : '#333'
    style[`--${prefix}-b30-text-color`] = isDark(new TinyColor(color).mix(onBlack, 30))
      ? '#fefefe'
      : '#333'

    style[`--${prefix}-w20-color`] = new TinyColor(color).mix(onWhite, 20).toHexString()
    style[`--${prefix}-b20-color`] = new TinyColor(color).mix(onBlack, 20).toHexString()
    style[`--${prefix}-w20-text-color`] = isDark(new TinyColor(color).mix(onWhite, 20))
      ? '#fefefe'
      : '#fefefe'
    style[`--${prefix}-b20-text-color`] = isDark(new TinyColor(color).mix(onBlack, 20))
      ? '#fefefe'
      : '#fefefe'

    style[`--${prefix}-a80-color`] = new TinyColor(color).setAlpha(0.8).toString()
    style[`--${prefix}-a70-color`] = new TinyColor(color).setAlpha(0.7).toString()

    style[`--${prefix}-text-color`] = isDark(new TinyColor(color)) ? '#fefefe' : '#333'
  } catch (error) {}
  return style
}

/** returns a random int min,max are BOTH inclusive */
export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getScore = (avg: number | undefined) => {
  if (avg == null) return undefined
  return Math.round((avg + 1) * 50)
}

export const getRangeColor = (value: number | undefined, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef'

  const val = value / outOf
  if (val <= 0.2) return '#EC3E23' // red
  else if (val <= 0.4) return '#fff5b9' // orange
  else if (val <= 0.6) return '#efdf80' // yellow
  else if (val <= 0.8) return '#ACB601' // green
  else return '#5C9E02' // deep green
}

export const isMac = () => navigator.userAgent.indexOf('Mac OS X') !== -1

export const sentimentWords: { [key: number]: string } = {
  1: 'terrible',
  2: 'bad',
  3: 'ok',
  4: 'good',
  5: 'great',
}

export const scoreToFeedbackColor = (score: number) => {
  if (score <= -0.6) return '#FF5F69'
  else if (score <= -0.2) return '#F98964'
  else if (score <= 0.2) return '#F7CC3F'
  else if (score <= 0.6) return '#83C956'
  else if (score > 0.6) return '#4EA051'
  else return null
}

export const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml']

export const getVibrantColors = async (url: HTMLImageElement | string, extraColors: string[]) => {
  const palette = await Vibrant.from(url).quality(1).clearFilters().getPalette()
  const colors = new Set(
    [
      palette.Vibrant?.hex,
      palette.LightVibrant?.hex,
      palette.DarkVibrant?.hex,
      palette.Muted?.hex,
      palette.LightMuted?.hex,
      palette.DarkMuted?.hex,
    ].filter(isNotNull)
  )
  if (extraColors) extraColors.forEach((c) => colors.add(c))
  return Array.from(colors)
}

export const DEFAULT_PER_PAGE = 50

export const getTitle = (namespace: ServerPublicNamespace, listeningPost?: ServerListeningPost) => {
  if (listeningPost?.title) return listeningPost?.title
  else if (listeningPost?.publicName) return listeningPost?.publicName
  else if (listeningPost?.name && namespace.name) {
    return [namespace.name, listeningPost?.name, 'Listens'].filter(isNotNull).join(' ')
  } else {
    return 'Listens'
  }
}

export const getDescription = (
  namespace: ServerPublicNamespace,
  listeningPost?: ServerListeningPost
) => {
  if (listeningPost?.description) return listeningPost?.description
  else if (namespace.description) return namespace.description
  else
    return 'ListensIn is a customer engagement platform designed to help people vocalize their needs'
}

export const getShortDescription = (
  namespace: ServerPublicNamespace,
  listeningPost?: ServerListeningPost
) => {
  if (listeningPost?.shortDescription) return listeningPost?.shortDescription
  else if (namespace.shortDescription) return namespace.shortDescription
  else return 'Helping people vocalize their needs'
}

export const getOgImages = (listeningPost?: ServerListeningPost) => {
  const ogSlideId = listeningPost?.ogSlideId
  if (ogSlideId) {
    const ogSlide = listeningPost?.slides?.[ogSlideId]
    return {
      og: ogSlide?.og,
      ogSquare: ogSlide?.ogSquare,
    }
  }

  const slides = sortBy(Object.values(listeningPost?.slides || {}), 'order')
    .filter((s) => s.og != null || s.ogSquare != null)
    .filter(isNotNull)

  return slides[0]
}

export const getMetaTags = (
  namespace: ServerPublicNamespace,
  listeningPost: ServerListeningPost
) => {
  const title = getTitle(namespace, listeningPost)
  const description = getDescription(namespace, listeningPost)
  const shortDescription = getShortDescription(namespace, listeningPost)
  const titleWithShortDescription = [title, shortDescription].filter(isNotNull).join(' - ')
  const imageWithOg = getOgImages(listeningPost)
  const ogSquare = imageWithOg?.ogSquare || imageWithOg?.og
  const ogImage = imageWithOg?.og || imageWithOg?.ogSquare

  return {
    title,
    description,
    shortDescription,
    titleWithShortDescription,
    ogSquare,
    ogImage,
  }
}

export const getOgSlide = (listeningPost: ServerListeningPost) => {
  const ogSlideId = listeningPost.ogSlideId
  return ogSlideId ? listeningPost.slides?.[ogSlideId] : undefined
}

export function isNotNull<T>(arg: T | undefined | null): arg is T {
  return !(arg === null || arg === undefined)
}

export function isStringNotEmpty(arg: string | undefined | null): arg is string {
  return isNotNull(arg) && arg.length > 0
}

export type Query = {
  status?: string
  query?: string
}

export type Paginated = {
  perPage?: number
  prevPageId?: string
  nextPageId?: string
}

export type NumericalPaginated = {
  perPage?: number
  nbPages?: number
  page?: number
}
export interface Pagination extends Paginated {
  queryPrevPageId?: string
  queryNextPageId?: string
}

export interface NumericalPagination extends NumericalPaginated {
  queryPage?: number
}

export interface ApiStatus {
  fetching?: boolean
  deleting?: boolean
  updating?: boolean
  error?: string
  ssr?: boolean
}

export interface Meta<T> {
  data?: T
  meta?: ApiStatus & Pagination & NumericalPagination
}

export interface DefinedMeta<T> {
  data: T
  meta?: ApiStatus & Pagination & NumericalPagination
}

export interface QueryMeta<T, S> {
  data?: T
  meta?: ApiStatus & Pagination & NumericalPagination
  query?: S
}

export function shouldRefetch<T>(obj: Meta<T> | undefined) {
  if (obj == null) return true
  else if (obj.meta?.fetching) return false
  else if (obj.meta?.error) return false
  else if (obj.data != null) return false
  else return true
}

export function isLoading<T>(obj: Meta<T> | undefined) {
  if (obj == null) return true
  return obj.meta?.fetching
}
export function hasError<T>(obj: Meta<T> | undefined) {
  if (obj == null) return false
  return !obj.meta?.fetching && obj.meta?.error != null
}
export function hasData<T>(obj: Meta<T> | undefined) {
  if (obj == null) return false
  if (obj?.data instanceof Array) {
    return !obj.meta?.fetching && obj.meta?.error == null && obj.data.length > 0
  } else {
    return !obj.meta?.fetching && obj.meta?.error == null && obj.data != null
  }
}

export const getSearchParams = (
  existingParams: URLSearchParams,
  newParams: { [key: string]: string | null | undefined }
) => {
  const entries = {
    ...Object.fromEntries(existingParams.entries()),
    ...newParams,
  }
  return pickBy(entries, isNotNull)
}

export const defineMeta = <T>(meta?: Meta<T>) => {
  if (meta?.data != null) return meta as DefinedMeta<T>
  else return undefined
}

export const getRangeColor2 = (value?: number, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef'

  const val = value / outOf
  if (val <= 0.2) return '#EC3E23' // red
  else if (val <= 0.4) return '#EA8F14' // orange
  else if (val <= 0.6) return '#E7C601' // yellow
  else if (val <= 0.8) return '#ACB601' // green
  else return '#5C9E02' // deep green
}

export const getHighlightRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef'

  const val = value / outOf
  if (val <= 0.2) return '#FBD8D3' // red
  else if (val <= 0.4) return '#FBE9D0' // orange
  else if (val <= 0.6) return '#FAF4CC' // yellow
  else if (val <= 0.8) return '#EEF0CC' // green
  else return '#DEECCC' // deep green
}
export const getFadedHighlightRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef'

  const val = value / outOf
  if (val <= 0.2) return '#FEF5F4' // red
  else if (val <= 0.4) return '#FEF9F3' // orange
  else if (val <= 0.6) return '#FEFCF2' // yellow
  else if (val <= 0.8) return '#FBFBF2' // green
  else return '#F7FAF2' // deep green
}

export const getHoverHighlightRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef'

  const val = value / outOf
  if (val <= 0.2) return '#F59E91' // red
  else if (val <= 0.4) return '#F4C789' // orange
  else if (val <= 0.6) return '#F3E280' // yellow
  else if (val <= 0.8) return '#D5DA80' // green
  else return '#ADCE80' // deep green
}

export const getRangeString = (value?: number, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return undefined
  const val = value / outOf
  if (val <= 0.2) return 'poor'
  else if (val <= 0.4) return 'bad'
  else if (val <= 0.6) return 'ok'
  else if (val <= 0.8) return 'good'
  else return 'great'
}

export const getNumberValue = (maybeString: string | string[] | undefined) => {
  if (maybeString == null) return undefined
  else if (Array.isArray(maybeString)) return undefined
  else return Number.parseInt(maybeString)
}

export const createBaseUrl = (
  data: ServerConfigInfo | undefined,
  namespaceId: string | undefined,
  path: string | undefined
) => {
  if (namespaceId == null) return undefined
  const windowPort =
    window.location.port != null && window.location.port.length > 0
      ? `:${window.location.port}`
      : ''

  if (data == null) return undefined
  const baseUrl = `${data.protocol}://${namespaceId}.${data.baseDomain}`

  return `${baseUrl}${windowPort}${path}`
}

export const blobToBase64 = (blob: File) => {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  }).then((data) => {
    if (typeof data == 'string') {
      return data
    } else return undefined
  })
}
