import React from 'react'
import clsx from 'clsx'
import styles from './Indicators.module.scss'
import { SlidesDisplayAction } from '../../components/DisplayListeningPortal/state/slides-display.reducer'

interface IndicatorsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  times: number[]
  index: number
  dispatch: React.Dispatch<SlidesDisplayAction>
}

export const Indicators: React.FC<IndicatorsProps> = ({ times, dispatch, index }) => {
  React.useEffect(() => {
    const time = times[index]
    if (time == null) return
    if (time <= 0) return
    if (index >= times.length - 1) return

    const setTimeoutId = setTimeout(() => {
      dispatch({ type: 'NEXT_SLIDE' })
    }, time)
    return () => clearTimeout(setTimeoutId)
  }, [index, times, dispatch])

  return (
    <div className={styles.indicators}>
      {times.map((time, i) => {
        const classList = clsx(
          styles.indicatorOverlay,
          i < index ? styles.animated : null,
          i === index ? styles.animating : ''
        )
        return (
          <div className={styles.indicator} key={i}>
            <div style={{ animationDuration: `${time}ms` }} className={classList} />
          </div>
        )
      })}
    </div>
  )
}
