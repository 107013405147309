import clsx from 'clsx'
import { isArray } from 'lodash'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { fetchUrl } from '../../contexts/utils'
import { useSearch } from '../../hooks/useSearch'
import { namespaceStyle } from '../../utils'
import { ServerPublicNamespace } from '../../utils/trpc'
import { Input } from '../input/input'
import { GridSpinner } from '../loading/loading'
import styles from './auth.module.scss'

interface PasswordConfirmProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerPublicNamespace
}

const defaultValues = {
  email: '',
  password: '',
  password2: '',
  global: '',
}
export const PasswordConfirm: React.FC<PasswordConfirmProps> = ({ namespace }) => {
  const navigate = useNavigate()
  const {
    watch,
    getValues,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    clearErrors,
    control,
  } = useForm({
    defaultValues,
  })

  const [search] = useSearch()

  const isDisabled = !watch('password') || !watch('password2')

  const onSubmit = async () => {
    try {
      const password = getValues('password')
      const password2 = getValues('password2')
      const code = search.code || ''

      await fetchUrl('POST', `/api/v1/confirmPassword`, {
        body: { password, password2, code },
      })
      navigate('./login')
    } catch (error: any) {
      setError('global', { type: 'custom', message: error.message }) //TODO: divide by certain errors
    }
  }

  const namespaceLogoUrl = namespace?.logo

  return (
    <form
      className={clsx(styles.container, isSubmitting ? styles.loading : null)}
      onSubmit={handleSubmit(onSubmit)}
      style={namespaceStyle(namespace)}
    >
      {namespaceLogoUrl && (
        <div className={styles.logoContainer}>
          <img src={namespaceLogoUrl} alt={namespace?.name} />
        </div>
      )}
      <div className={styles.content}>
        {namespace && <GridSpinner className={styles.namespaceLoading} />}
        {/* {!namespace && <div className={styles.circle}>
        <ListensInLogo className={styles.logo} />
      </div>} */}
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="PASSWORD"
              placeholder="password"
              error={errors.password?.message}
              {...field}
              autoCapitalize="off"
              type="password"
            />
          )}
        />
        <Controller
          control={control}
          name="password2"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="CONFIRM PASSWORD"
              placeholder="retype password"
              error={errors.password2?.message}
              {...field}
              autoCapitalize="off"
              type="password"
            />
          )}
        />
        {errors.global && (
          <div className={styles.globalError}>
            {!isArray(errors.global.message)
              ? errors.global.message
              : errors.global.message.map((error: string, key: number) => (
                  <p key={key}>{`* ${error}`}</p>
                ))}
          </div>
        )}
      </div>
      <button
        className={styles.button}
        onClick={() => clearErrors('global')}
        disabled={isDisabled}
        type="submit"
      >
        Reset Password
      </button>
    </form>
  )
}

export default PasswordConfirm
