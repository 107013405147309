import React from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Cell } from '../Cell/Cell'
import { Pill } from '../../components/pills/pills'
import { useMediaQueryContext } from '../../contexts'
import { PillBarChart } from '../../currentColorIcons'
import { useSearch } from '../../hooks/useSearch'
import { isNotNull } from '../../utils'
import { ServerListeningPost } from '../../utils/trpc'
import { SlideImageTiny } from '../SlideImageTiny/SlideImageTiny'
import styles from './ListeningPosts.module.scss'

interface ListeningPostCellsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  listeningPost: ServerListeningPost
  index: number
  isDefault: boolean
}

export const ListeningPostCells: React.FC<ListeningPostCellsProps> = ({ listeningPost, index }) => {
  const { isLargeScreen } = useMediaQueryContext()
  const navigate = useNavigate()
  const [search] = useSearch()
  const activeListeningPostId = search.id

  const active = activeListeningPostId ? activeListeningPostId === listeningPost.id : false
  const onClick = () => {
    navigate(`/admin/edit/${listeningPost.id}`)
  }

  const slides = listeningPost.sections
    ?.map((section) =>
      listeningPost.groupedSlideIds?.[section]?.map((id) => listeningPost.slides?.[id])
    )
    .flat()
    .filter(isNotNull)

  return (
    <>
      <Cell sticky index={index} active={active} onClick={onClick}>
        <span>{listeningPost.name}</span>
      </Cell>
      <Cell index={index} active={active} onClick={onClick}>
        {slides?.map((slide) => (
          <SlideImageTiny key={slide.id} className={styles.image} slide={slide} />
        ))}
      </Cell>
      <Cell index={index} active={active} onClick={onClick}>
        <Pill
          icon={<PillBarChart />}
          text="Analysis"
          withTooltip={!isLargeScreen}
          onClick={(e) => {
            e.stopPropagation()
          }}
          tagName={Link}
          to={`./${listeningPost.id}`}
        />
      </Cell>
    </>
  )
}
