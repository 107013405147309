import React from 'react'
import { useParams } from 'react-router-dom'
import Error404 from '../../components2/Error404/Error404'
import { namespaceStyle } from '../../utils'
import { ServerListeningPost, ServerPublicNamespace, trpc } from '../../utils/trpc'
import DisplayListeningPortal from '../DisplayListeningPortal/DisplayListeningPortal'
import { LoadingContainer } from '../loading/loading'

interface ListeningPostWithParamsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
}
export const ListeningPostWithParams: React.FC<ListeningPostWithParamsProps> = ({ namespace }) => {
  const params = useParams<string>()
  const listeningPostId = params.listeningPostId

  if (listeningPostId == null) return <Error404 />
  return <ListeningPost namespace={namespace} listeningPostId={listeningPostId} />
}

interface ListeningPostWithIdProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
  listeningPostId: string
}

export const ListeningPost: React.FC<ListeningPostWithIdProps> = ({
  namespace,
  listeningPostId,
}) => {
  const namespaceId = namespace.id
  const listeningPostQuery = trpc.listeningPost.publicId.useQuery({
    namespaceId,
    id: listeningPostId,
  })

  if (listeningPostQuery.isLoading)
    return <LoadingContainer namespace={namespace} style={namespaceStyle(namespace)} />
  if (listeningPostQuery.isError) {
    // show error page for pages that do not exist
    return <Error404 />
  }

  if (listeningPostQuery.data == null) return <Error404 />
  return <ListeningPostWithData namespace={namespace} listeningPost={listeningPostQuery.data} />
}

interface ListeningPostWithDataProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
  listeningPost: ServerListeningPost
}
const ListeningPostWithData: React.FC<ListeningPostWithDataProps> = ({
  namespace,
  listeningPost,
}) => {
  let sections: string[] = []

  const hasIntro = (listeningPost.groupedSlideIds['intro']?.length ?? 0) > 0
  const hasMain = (listeningPost.groupedSlideIds['main']?.length ?? 0) > 0
  const hasOutro = (listeningPost.groupedSlideIds['outro']?.length ?? 0) > 0

  if (hasIntro) sections.push('intro')
  if (hasMain) sections.push('main')
  if (hasOutro) sections.push('outro')

  return <DisplayListeningPortal listeningPost={listeningPost} namespace={namespace} />
}
