import React from 'react'
import { LinkedinAuthButton } from '../shared/LinkedinAuthButton/LinkedinAuthButton'

interface Props {
  isLittleCard?: boolean
}

export const LinkedinField: React.FC<Props> = ({ isLittleCard }) => {
  return (
    <LinkedinAuthButton isLittleCard={isLittleCard}>Login</LinkedinAuthButton>
  )
}
