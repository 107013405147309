import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { ServerFeedbackCreate } from '../../../utils/trpc'

interface Props {
  onChange: (data: ServerFeedbackCreate) => void
}

const RecaptchaSlide: React.FC<Props> = ({ onChange }) => {
  const handleOnChange = React.useCallback(
    async (token) => {
      if (!token) return

      onChange({ kind: 'recaptcha', token })
    },
    [onChange]
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {RECAPTCHA_SITE_KEY && <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleOnChange} />}
    </div>
  )
}

export default RecaptchaSlide

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY
