import React from 'react'
import { SlideIcon } from '../../DesignListeningPortal/components/shared/slide-card/SlideIcon'
import {
  ServerFeedback,
  ServerFeedbackAnswerCsat,
  ServerFeedbackAnswerDemographics,
  ServerFeedbackAnswerGoldenQuestion,
  ServerFeedbackAnswerLikert,
  ServerFeedbackAnswerLikert7,
  ServerFeedbackAnswerLinkedin,
  ServerFeedbackAnswerMultipleChoice,
  ServerFeedbackAnswerOpenEnd,
  ServerFeedbackAnswerRecaptcha,
  ServerFeedbackSlideAnswer,
  ServerListeningPost,
  ServerListeningPostSlide
} from '../../utils/trpc'
import styles from './Answer.module.scss'
import { Highlight } from './Highlight'

interface AnswerProps {
  feedback: ServerFeedback
  slideId: string
  data: ServerFeedbackSlideAnswer
  listeningPost: ServerListeningPost
}

export const Answer: React.FC<AnswerProps> = ({ feedback, data, slideId, listeningPost }) => {
  const slide = listeningPost.slides?.[slideId]
  if(slide == null) return null;
  switch (data.kind) {
    case 'openEnd':
      return <OpenEndQuestionAnswer answer={data} slide={slide} />
    case 'goldenQuestion':
      return <GoldenQuestionQuestionAnswer feedback={feedback} slide={slide} answer={data} />
    case 'multiple':
      return <MultipleQuestionAnswer answer={data} slide={slide} listeningPost={listeningPost} />
    case 'likert':
      return <LikertQuestionAnswer answer={data} slide={slide} />
    case 'likert7':
      return <Likert7QuestionAnswer answer={data} slide={slide} />
    case 'linkedin':
      return <LinkedinQuestionAnswer answer={data} slide={slide} />
    case 'recaptcha':
      return <RecaptchaQuestionAnswer answer={data} slide={slide} />
    case 'demographics':
      return <DemographicsQuestionAnswer answer={data} slide={slide} />
    case 'csat':
      return <CsatQuestionAnswer answer={data} slide={slide} />

    default: return null;
  }
}

interface OpenEndQuestionAnswerProps {
  answer: ServerFeedbackAnswerOpenEnd
  slide: ServerListeningPostSlide
}

const OpenEndQuestionAnswer: React.FC<OpenEndQuestionAnswerProps> = ({ answer, slide }) => {
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        {answer.text && <Highlight
          className={styles.text}
          text={answer.text}
          phrases={answer.phrases}
          highlightedText={answer.text}
        />}
      </div>
    </div>
  )
}

interface GoldenQuestionQuestionAnswerProps {
  answer: ServerFeedbackAnswerGoldenQuestion
  feedback: ServerFeedback
  slide: ServerListeningPostSlide
}
const GoldenQuestionQuestionAnswer: React.FC<GoldenQuestionQuestionAnswerProps> = ({
  answer,
  feedback,
}) => {
  const highlightedText = feedback.text || ''
  return (
    <div className={styles.answer}>
      <Highlight
        className={styles.text}
        text={highlightedText}
        phrases={feedback.phrases}
        highlightedText={highlightedText}
      />
    </div>
  )
}

interface MultipleQuestionAnswerProps {
  answer: ServerFeedbackAnswerMultipleChoice
  listeningPost: ServerListeningPost
  slide: ServerListeningPostSlide
}

const MultipleQuestionAnswer: React.FC<MultipleQuestionAnswerProps> = ({
  answer,
  slide,
  listeningPost,
}) => {
  const feedbackAnswer = answer.value ? listeningPost.slides?.[slide.id]?.answerOptions?.[answer.value] : undefined

  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>{feedbackAnswer?.label}</div>
      </div>
    </div>
  )
}

interface LikertQuestionAnswerProps {
  answer: ServerFeedbackAnswerLikert
  slide: ServerListeningPostSlide
}

const LikertQuestionAnswer: React.FC<LikertQuestionAnswerProps> = ({ answer, slide }) => {
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>{answer.value}</div>
      </div>
    </div>
  )
}

interface Likert7QuestionAnswerProps {
  answer: ServerFeedbackAnswerLikert7
  slide: ServerListeningPostSlide
}

const Likert7QuestionAnswer: React.FC<Likert7QuestionAnswerProps> = ({ answer, slide }) => {
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>{answer.value}</div>
      </div>
    </div>
  )
}

interface CsatQuestionAnswerProps {
  answer: ServerFeedbackAnswerCsat
  slide: ServerListeningPostSlide
}

const CsatQuestionAnswer: React.FC<CsatQuestionAnswerProps> = ({ answer, slide }) => {
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>
          {answer.value === 5 && 'Very Satisfied'}
          {answer.value === 4 && 'Satisfied'}
          {answer.value === 3 && 'Neutral'}
          {answer.value === 2 && 'Unsatisfied'}
          {answer.value === 1 && 'Very Unsatisfied'}
        </div>
      </div>
    </div>
  )
}

interface LinkedinQuestionAnswerProps {
  answer: ServerFeedbackAnswerLinkedin
  slide: ServerListeningPostSlide
}

const LinkedinQuestionAnswer: React.FC<LinkedinQuestionAnswerProps> = ({ answer, slide }) => {
  console.log("LinkedinQuestionAnswer", LinkedinQuestionAnswer)
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>{answer.code}</div>
      </div>
    </div>
  )
}

interface DemographicsQuestionAnswerProps {
  answer: ServerFeedbackAnswerDemographics
  slide: ServerListeningPostSlide
}

const DemographicsQuestionAnswer: React.FC<DemographicsQuestionAnswerProps> = ({ answer, slide }) => {
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>name: {answer.name}</div>
        <div className={styles.answerTitle}>email or phone: {answer.emailPhoneRaw}</div>
      </div>
    </div>
  )
}

interface RecaptchaQuestionAnswerProps {
  answer: ServerFeedbackAnswerRecaptcha
  slide: ServerListeningPostSlide
}

const RecaptchaQuestionAnswer: React.FC<RecaptchaQuestionAnswerProps> = ({ answer, slide }) => {
  return (
    <div className={styles.answer}>
      <SlideIcon kind={slide.kind} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.answerTitle}>{answer.token}</div>
      </div>
    </div>
  )
}
