import React from "react";
import { LoadingContainer } from "../loading/loading";
import { slidesSession } from "../../models/services/session-storage.service";

export const LinkedinRedirect: React.FC = () => {
  React.useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const baseUrl = url.protocol + "//" + url.host;

    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const errorDescription = searchParams.get("error_description");

    const authParams = slidesSession.getParsedItem("LINKEDIN_AUTH_PARAMS");
    console.log("authParams", authParams)
    const redirectPath = baseUrl + "/" + authParams?.listeningPostId;

    slidesSession.setStringyItem("LINKEDIN_AUTH_RESPONSE", {
      code,
      state,
      errorDescription,
    });

    window.location.href = redirectPath;
  }, []);

  return <LoadingContainer />;
};