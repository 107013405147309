import React from "react";
import clsx from 'clsx'
import styles from './Input.module.scss'
import { XmarkCircleFill } from "../../currentColorIcons";


interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error?: string,
  value?: string,
  leftIcon?: React.ReactNode,
  rightIcon?: React.ReactNode,
  onClear?: () => void,
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const BorderlessInput: React.FC<InputProps> = ({className, placeholder, type = "text", name, error, leftIcon, rightIcon, value, disabled, onChange, autoFocus, onClear, ...props}) => {
  const hasRightIcon = error != null || rightIcon != null || (type === 'password' && (`${value}`?.length ?? 0) > 0);
  const hasLeftIcon = leftIcon != null;
  const classList = clsx(
    className,
    error ? styles.error : undefined,
    styles.container,
    (hasRightIcon || onClear != null) ? styles.hasRightIcon : undefined,
    hasLeftIcon ? styles.hasLeftIcon : undefined,
    disabled ? styles.disabled : undefined,
  )
  
  return <label className={classList}>
    <input name={name} type={type} placeholder={placeholder} onChange={onChange} value={value || ""} className={styles.input} disabled={disabled} autoFocus={autoFocus} {...props} />
    <div className={styles.complications}>
      {onClear && <div className={clsx(styles.rightIcon, styles.clickable)} onClick={onClear}>
        <XmarkCircleFill />
      </div>}
      {hasLeftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
    </div>
  </label>
}