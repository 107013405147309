import clsx from 'clsx'
import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ListeningPostResponses } from '../../components/listeningPostResponses/listeningPostResponses'
import Settings from '../../components/settings/settings'
import { DesignListeningPortal } from '../../DesignListeningPortal/DesignListeningPortal/DesignListeningPortal'
import { ServerPublicNamespace } from '../../utils/trpc'
import LeftNav from '../LeftNav/LeftNav'
import { ListeningPosts } from '../ListeningPosts/ListeningPosts'
import styles from './ListeningPostsAdmin.module.scss'

interface ListeningPostsAdminProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
}

export const ListeningPostsAdmin: React.FC<ListeningPostsAdminProps> = ({ namespace }) => {
  const { pathname } = useLocation()
  const showLeftNav = !pathname.startsWith('/admin/v2')

  return (
    <>
      {showLeftNav && <LeftNav namespace={namespace} />}
      <div className={clsx(styles.container, showLeftNav && styles.withLeftNav)}>
        <Routes>
          <Route path="settings" element={<Settings namespace={namespace} />} />
          <Route
            path="edit/:listeningPostId"
            element={<DesignListeningPortal namespace={namespace} />}
          />
          <Route
            path=":listeningPostId"
            element={<ListeningPostResponses namespace={namespace} />}
          />
          <Route path="/" element={<ListeningPosts namespace={namespace} />} />
        </Routes>
      </div>
    </>
  )
}
