import React from 'react'
import clsx from 'clsx'
import Loading from '../loading/loading'

import styles from './button.module.scss'

interface ButtonProps extends React.HtmlHTMLAttributes<HTMLOrSVGElement> {
  tagName?: any
  disabled?: boolean
  loading?: boolean
  to?: string
  href?: string
  download?: boolean
}

export const Button: React.FC<ButtonProps> = ({tagName, disabled, onClick, className, children, loading, ...props}) => {
  const Tag = tagName || "button";
  const extraProps: any = {}
  if(Tag === 'button') {
    extraProps['type'] = "submit"
  }
  return <Tag className={clsx(styles.button, className, loading ? styles.loading : null)} {...extraProps} disabled={disabled || loading} onClick={onClick} {...props}>
    <Loading className={clsx(styles.loading, loading ? styles.active : null)} />
    {!loading && children}
  </Tag>
}



interface FullIconButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: React.ReactNode,
  buttonType?: "delete" | "active"
}



export const FullIconButton = React.forwardRef<HTMLButtonElement, FullIconButtonProps>( ({children, className, icon, buttonType, ...props}, ref) => {
  const classList = clsx(
    styles.iconButton,
    className,
    buttonType ? styles[buttonType] : undefined,
  )
  return <button className={classList} {...props} ref={ref}>
    {icon}
    {children != null && <span>{children}</span>}
  </button>
})

interface FullIconAnchorProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  icon: React.ReactNode,
}

export const FullIconAnchor = React.forwardRef<HTMLAnchorElement, FullIconAnchorProps>(({children, className, icon, ...props}, ref) => {
  const classList = clsx(
    styles.iconButton,
    className,
  )
  return <a className={classList} {...props} ref={ref}>
    {icon}
    {children != null && <span>{children}</span>}
  </a>
})
FullIconAnchor.displayName = "FullIconAnchor"

export default Button