import clsx from 'clsx'
import { FirebaseError } from 'firebase/app'
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { isArray } from 'lodash'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { namespaceStyle } from '../../utils'
import { ServerPublicNamespace } from '../../utils/trpc'
import { Input } from '../input/input'
import styles from './auth.module.scss'

interface LoginProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace?: ServerPublicNamespace
}

const defaultValues = {
  email: '',
  password: '',
  global: '',
}

export const Login: React.FC<LoginProps> = ({ namespace }) => {
  const {
    getValues,
    watch,
    setError,
    formState: { errors, isSubmitting },
    clearErrors,
    control,
    handleSubmit,
  } = useForm({ mode: 'all', defaultValues })

  const isDisabled = !watch('email') || !watch('password')

  const onSubmit = async () => {
    try {
      const email = getValues('email').trim()
      const password = getValues('password')

      
      const auth = getAuth()
      setPersistence(auth, browserLocalPersistence)
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/wrong-password') {
          setError('password', { type: 'custom', message: 'Invalid' })
        } else if (error.code === 'auth/invalid-email') {
          setError('email', { message: 'Invalid' })
        } else if (error.code === 'auth/user-not-found') {
          setError('global', {
            type: 'custom',
            message: 'Invalid login - Are you sure you have an account?',
          })
        }
      }
    }
  }

  return (
    <form
      className={clsx(styles.container, isSubmitting ? styles.loading : null)}
      style={namespaceStyle(namespace)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.content}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="EMAIL ADDRESS"
              placeholder="email address"
              error={errors.email?.message}
              {...field}
              autoCapitalize="off"
              type="text"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input
              className={styles.input}
              title="PASSWORD"
              placeholder="password"
              error={errors.password?.message}
              {...field}
              autoCapitalize="off"
              type="password"
            />
          )}
        />
        {errors.global && (
          <div className={styles.globalError}>
            {!isArray(errors.global.message)
              ? errors.global.message
              : errors.global.message.map((error: string, key: number) => (
                  <p key={key}>{`* ${error}`}</p>
                ))}
          </div>
        )}
      </div>
      <button
        className={styles.button}
        onClick={() => clearErrors('global')}
        disabled={isDisabled}
        type="submit"
      >
        Login
      </button>
      <div className={styles.linkRow}>
        <Link to="../signup">Don't have an account? Sign up!</Link>
      </div>
      <div className={styles.linkRow}>
        <Link to="../passwordReset">Forgot your password? Reset it!</Link>
      </div>
    </form>
  )
}

export default Login
