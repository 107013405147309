import clsx from 'clsx'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { ListeningPostUpdate } from '../../../../../server-nest/src/_trpc/models/listeningPost.model'
import { Tooltip } from '../../../components/Tooltip'
import {
  DocOnDoc,
  HiddenIcon,
  HomeIcon,
  PillBarChart,
  ShareIcon,
  TrashIcon,
  VisibleIcon
} from '../../../currentColorIcons'
import { ServerListeningPost } from '../../../utils/trpc'
import { NavItemLink } from '../left-nav/NavItem/NavItem'
import KeyDownInput from '../shared/KeyDownInput'
import styles from './PostHeader.module.scss'

interface PostHeaderProps extends React.HTMLProps<HTMLDivElement> {
  previewUrl?: string
  onUpdate: (data: ListeningPostUpdate) => void
  onChangeDefault: (isDefault: boolean) => void
  onDeletePost?: () => void
  onClone?: () => void
  isDefault?: boolean
  namespaceId: string
  listeningPost: ServerListeningPost,
}

export const PostHeader: React.FC<PostHeaderProps> = ({
  namespaceId,
  className,
  previewUrl,
  isDefault,
  onClone,
  onUpdate,
  onDeletePost,
  onChangeDefault,
  listeningPost
}) => {
  const primaryColor = listeningPost.primaryColor
  const secondaryColor = listeningPost.secondaryColor
  // const listeningPostId = listeningPost.id
  const hidden = listeningPost.hidden
  // const utils = trpc.useContext()
  const [value, setValue] = React.useState(listeningPost.name || 'Untitled post')
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className={clsx(styles.container, className)}>
      <NavItemLink to="/admin" icon={<HomeIcon />} className={styles.home} />

      <KeyDownInput
        onChange={(e) => setValue(e.target.value)}
        onChangeDebounced={(name) => onUpdate({ name })}
        value={value}
        ref={inputRef}
        className={styles.title}
      />

      <div className={styles.buttons}>
        <Tooltip label="View Responses for this portal">
          <Link className={styles.button} to={`../${listeningPost.id}`}>
            <PillBarChart />
          </Link>
        </Tooltip>
        <Tooltip label="Change primary color" autoPlace={false} offsetValue={0}>
          <label className={styles.button} style={{ color: primaryColor }}>
            <input
              type="color"
              value={primaryColor || ''}
              onChange={(e) => onUpdate({ primaryColor: e.target.value })}
            />
            <div className={styles.color} />
          </label>
        </Tooltip>
        <Tooltip label="Change secondary color" autoPlace={false} offsetValue={0}>
          <label className={styles.button} style={{ color: secondaryColor }}>
            <input
              type="color"
              value={secondaryColor || ''}
              onChange={(e) => onUpdate({ secondaryColor: e.target.value })}
            />
            <div className={styles.color} />
          </label>
        </Tooltip>
        <Tooltip
          label={hidden ? 'Allow public to view portal' : 'Hide portal from public'}
          autoPlace={false}
          offsetValue={0}
        >
          <button
            className={styles.button}
            onClick={() => {
              onUpdate({ hidden: !hidden })
            }}
          >
            {hidden ? <HiddenIcon /> : <VisibleIcon />}
          </button>
        </Tooltip>
        {previewUrl != null && (
          <Tooltip label="Preview this portal as a user" autoPlace={false} offsetValue={0}>
            <a className={styles.button} href={previewUrl} target="_blank" rel="noreferrer">
              <ShareIcon />
            </a>
          </Tooltip>
        )}

        <Tooltip label="Clone listening post" autoPlace={false} offsetValue={0}>
          <button className={clsx(styles.button)} onClick={onClone}>
            <DocOnDoc />
          </button>
        </Tooltip>

        {onDeletePost && (
          <Tooltip label="Delete" autoPlace={false} background="rgb(239, 68, 68)" offsetValue={0}>
            <button onClick={onDeletePost} className={clsx(styles.button, styles.trash)}>
              <TrashIcon />
            </button>
          </Tooltip>
        )}

        <ToggleDefaultButton
          namespaceId={namespaceId}
          isDefault={isDefault}
          onChangeDefault={onChangeDefault}
        />
      </div>
    </div>
  )
}

interface ToggleDefaultButtonProps {
  namespaceId: string
  isDefault?: boolean
  onChangeDefault: (isDefault: boolean) => void
}
const ToggleDefaultButton: React.FC<ToggleDefaultButtonProps> = ({
  namespaceId,
  isDefault,
  onChangeDefault,
}) => {
  let label = (
    <>
      Make this post the default post for <strong>{namespaceId}.listens.in</strong>
    </>
  )
  if (isDefault) {
    label = (
      <>
        Remove default post for <strong>{namespaceId}.listens.in</strong>
      </>
    )
  }
  return (
    <Tooltip label={label}>
      <label className={clsx(styles.button, isDefault && styles.active)}>
        <input
          checked={isDefault}
          onChange={(e) => onChangeDefault(e.target.checked)}
          type="checkbox"
        />
        <div className={styles.defaultText}>{isDefault ? 'default' : 'not default'}</div>
      </label>
    </Tooltip>
  )
}
