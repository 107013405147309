import React from 'react'
import { ServerListeningPostSlide } from '../utils/trpc'

export const getSlideBgStyles = (slide: ServerListeningPostSlide): React.CSSProperties => {
  switch(slide.background?.type) {
    case "file": {
      if(slide.webpUrl) {
        return { backgroundImage: `url(${slide.webpUrl})`}
      } else if(slide.background.data){
        return { backgroundImage: `url(${slide.background.data})` }
      } else {
        return { background: 'white' }
      }
    }
    case "fileRef": {
      if(slide.webpUrl) {
        return { backgroundImage: `url(${slide.webpUrl})`}
      } else if(slide.background.data){
        return { backgroundImage: `url(${slide.background.data})` }
      } else {
        return { background: 'white' }
      }
    }
    case "unsplash": {
      if(slide.webpUrl) {
        return { backgroundImage: `url(${slide.webpUrl})` }
      } else {
        return { backgroundImage: `url("${slide.background.data.urls.full}")` }
      }
    }
    case "solid": return { backgroundColor: slide.background.data }
    case "gradient": return { backgroundImage: slide.background.data }
    default: return { background: 'white' }
  }
}
