import { User } from 'firebase/auth'
import { AuthAxiosService } from './authAxios.service'
class ListeningPostService extends AuthAxiosService {
  basePath = 'api/v1/namespaces/'

  async exportCSV(user: User, namespaceId: string, listeningPostId: string) {
    await this.post<any>(`${namespaceId}/listeningPosts/${listeningPostId}/csvExport`, user)
  }
}

export const listeningPostService = new ListeningPostService()
