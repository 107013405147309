import { trpc } from "../../utils/trpc"
import { groupSlideIds } from "./helpers"

export const useReorderSlidesTrpc = () => {
  const utils = trpc.useContext()
  return trpc.listeningPostSlide.reorderSlides.useMutation({
    onMutate: ({namespaceId, listeningPostId, slideOrders}) => {
      utils.listeningPost.id.setData({namespaceId, id: listeningPostId}, (listeningPost) => {
        const slides = Object.assign({}, listeningPost?.slides)
        Object.entries(slideOrders).forEach(([section, ids]) => {
          ids.forEach((id, order) => {
            slides[id] = Object.assign({}, slides[id], { order, section})
          })
        })

        const groupedSlideIds = groupSlideIds(slides)

        return Object.assign({}, listeningPost, { groupedSlideIds, slides })
      })
    },
    onSuccess: (_, {namespaceId, listeningPostId}) => {
      utils.listeningPost.id.invalidate({namespaceId, id: listeningPostId})
    },
    onError: (_, {namespaceId, listeningPostId}) => {
      utils.listeningPost.id.invalidate({namespaceId, id: listeningPostId})
    }
  })
}