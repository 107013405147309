import clsx from 'clsx'
import React from 'react'
import useFitText from 'use-fit-text'
import styles from './SlideTitle.module.scss'

interface Props {
  className?: string
  autoAdjust?: boolean
}

const SlideTitle: React.FC<Props> = ({ children, className, autoAdjust = true }) => {
  const { fontSize, ref } = useFitText()

  return (
    <div className={clsx(styles.container, className)}>
      <div
        ref={ref as any}
        style={autoAdjust ? { fontSize } : undefined}
        className={styles.content}
      >
        {children}
      </div>
    </div>
  )
}

export default SlideTitle
