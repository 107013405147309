import { sortBy } from 'lodash'
import React from 'react'
import { ServerFeedback, ServerListeningPost, ServerTag } from '../../utils/trpc'
import { Answer } from './Answer'
import styles from './FeedbackView.module.scss'
import { Highlight } from './Highlight'

interface FeedbackViewProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespaceId: string
  feedback: ServerFeedback
  listeningPost: ServerListeningPost
  onDelete: (id?: string) => void
  tagById: Record<string, ServerTag>
}

export const FeedbackView: React.FC<FeedbackViewProps> = ({
  listeningPost,
  feedback,
  onDelete,
  tagById,
}) => {
  const answers = feedback.answers
    ? sortBy(Object.entries(feedback.answers), ([key, a]) => a.order)
    : undefined

  return (
    <div className={styles.feedback}>
      <div className={styles.content}>

        {answers?.map(([slideId, answer]) => (
          <Answer
            data={answer}
            key={slideId}
            feedback={feedback}
            slideId={slideId}
            listeningPost={listeningPost}
          />
        ))}

        {(answers?.length ?? 0) === 0 && feedback.text != null && (
          <Highlight
            className={styles.text}
            text={feedback.text}
            phrases={feedback.phrases}
            highlightedText={feedback.text}
          />
        )}
        {feedback.linkedin && <div className={styles.linkedin}>
          <img src={feedback.linkedin?.profilePicture} alt="linkedin profile" />
          <div className={styles.name}>{feedback.linkedin?.firstName} {feedback.linkedin?.lastName}</div>
        </div>}

        <div className={styles.actionsContainer}>
          <div className={styles.tags}>
            {feedback.tags?.map(tagId => <div key={tagId} className={styles.tag}>{tagById?.[tagId]?.name}</div>)}
          </div>
          
          <button className={styles.deleteButton} onClick={() => onDelete(feedback.id)}>
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}
