import clsx from 'clsx';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import CharacterCircle from '../characterCircle/characterCircle';
import { GridSpinner } from '../loading/loading';
import styles from './feedbackBox2.module.scss';

interface FeedbackBoxProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  defaultValue?: string
  onSubmit?: any,
  placeholder: string,
  autoFocus: any,
  disabled: boolean,
  maxCharacters: number
}

export const FeedbackBox: React.FC<FeedbackBoxProps> = ({className, onSubmit, placeholder, autoFocus, disabled, maxCharacters, defaultValue}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue ||"");
  const submitDisabled = disabled || isSubmitting || value.length <= 0 || value.length > maxCharacters;


  const _onSubmit = async (e?: any) => {
    e && e.preventDefault();
    setIsSubmitting(true);
    if(onSubmit) {
      await onSubmit(value);
    }
    setIsSubmitting(false);
  };

  return <div className={clsx(styles.container, className)}>
    <div className={styles.scrollable}>
      <div className={clsx(styles.content, styles.highlights)}>
        <span>{value.slice(0, maxCharacters)}</span>
        <mark>{value.slice(maxCharacters)}</mark>
      </div>
      <TextareaAutosize className={styles.textarea} onChange={e => setValue(e.target.value)}
                        placeholder={placeholder} value={value} autoFocus={autoFocus} disabled={disabled} />
    </div>
    
    <footer className={clsx(styles.headsUpfooter, value.length === 0 ? null : styles.hidden)}>
      <div className={styles.tipBoxText}>
        <span>Just a heads up, please limit your answer to </span>
        <strong> {maxCharacters} </strong>
        <span> characters</span>
      </div>
    </footer>
    <footer className={clsx(styles.footer, value.length <= 0 ? styles.hidden : null)}>
      <CharacterCircle max={maxCharacters} used={value.length} />
      {
        onSubmit && <button className={clsx(styles.submit, !disabled ? styles.active : null, isSubmitting ? styles.loading: null)} disabled={submitDisabled} type="submit" onClick={_onSubmit}>
          <span>Submit</span>
          <GridSpinner />
        </button>
      }
    </footer>
  </div>
}

export default FeedbackBox;
