
import clsx from 'clsx';
import { ServerListeningPostSlideBackground } from '../../../utils/trpc';
import { UnsplashPhoto } from '../unsplash.model';
import styles from './Tile.module.scss';

export const LoadingTile: React.FC = () => {
  return <div className={clsx(styles.tile, styles.loading)} />
};


interface ColorTileProps {
  onClick: () => void;
  background: ServerListeningPostSlideBackground;
}

export const ColorTile: React.FC<ColorTileProps> = ({ onClick, background }) => {
  
  const style = (background.type === 'solid' || background.type === 'gradient') ? { background: background.data } : undefined
  return <div onClick={onClick} style={style} className={styles.tile}>
    <div className={styles.tileContent} />
  </div>
};


interface PhotoTileProps {
  photo: UnsplashPhoto;
  onSelect: (photo: UnsplashPhoto) => void;
}

export const PhotoTile: React.FC<PhotoTileProps> = ({photo, onSelect}) => {
  return <div className={styles.tile} style={{ backgroundImage: `url("${photo.urls.regular}")`}} onClick={() => onSelect(photo)}>
    <div className={styles.tileContent}>
      <span className={styles.text}>{photo.user.name}</span>
    </div>
  </div>
};