import { motion } from 'framer-motion'
import React, { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import {
  ServerListeningPost,
  ServerListeningPostSlide,
  ServerListeningPostSlideAnswerOption,
  ServerListeningPostSlideBackground,
  ServerListeningPostSlideKind,
  ServerListeningPostSlideUpdate,
  ServerNamespace,
  trpc,
} from '../../../../../utils/trpc'
import { useDeleteListeningPostAnswerOptionTrpc } from '../../../../../trpcHooks/listeningPost/useDeleteListeningPostAnswerOptionTrpc'
import { useUiInteractions } from '../../../../../trpcHooks/listeningPost/useUiInteractions'
import { useUpdateListeningPostSlideTrpc } from '../../../../../trpcHooks/listeningPost/useUpdateListeningPostSlideTrpc'
import { getSlideBgStyles } from '../../../../utils'
import { CTAInput } from '../../../CTAInput/CTAInput'
import { DemographicQuestionField } from '../../../slide-extra-field/DemographicQuestionField/DemographicQuestionField'
import { Likert } from '../../../slide-extra-field/Likert/Likert'
import { Likert7 } from '../../../slide-extra-field/Likert7/Likert7'
import { LinkedinField } from '../../../slide-extra-field/LinkedinField'
import { MultipleChoiceField } from '../../../slide-extra-field/MultipleChoiceField/MultipleChoiceField'
import { OpenEnd } from '../../../slide-extra-field/OpenEnd/OpenEnd'
import { Recaptcha } from '../../../slide-extra-field/Recaptcha/Recaptcha'
import { Text } from '../../../slide-extra-field/Text/Text'
import { SlideActions } from '../SlideActions/SlideActions'
import { SlideCoverButton } from '../SlideCoverButton/SlideCoverButton'
import { SlideTitleInput } from '../SlideTitleInput/SlideTitleInput'

import styles from './SlideCard.module.scss'

interface Props {
  slideIndex: number
  slide: ServerListeningPostSlide & { scrollTo?: any }
  namespaceId: string
  namespace: ServerNamespace
  listeningPost: ServerListeningPost
}

const SlideCard: React.FC<Props> = ({
  slide,
  slideIndex,
  namespaceId,
  listeningPost,
  namespace,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const setRefs = useUiInteractions(ref, slide)
  const utils = trpc.useContext()
  const listeningPostId = listeningPost.id
  const slideId = slide.id
  const { mutate: deleteAnswerOptionMutate } = useDeleteListeningPostAnswerOptionTrpc()
  const { mutate: updateSlideMutate } = useUpdateListeningPostSlideTrpc()
  const deleteSlideMutation = trpc.listeningPostSlide.deleteSlide.useMutation({
    onSuccess: () => {
      utils.listeningPost.id.invalidate({ namespaceId, id: listeningPostId })
    },
  })

  const updateSlideDebounced = useDebouncedCallback(
    (slideId: string, data: ServerListeningPostSlideUpdate) =>
      updateSlideMutate({ namespaceId, listeningPostId, slideId, data }),
    2000
  )

  const updateAnswerOptionsDebounced = useCallback(
    (answerOptions: Record<string, ServerListeningPostSlideAnswerOption>) => {
      updateSlideDebounced(slideId, { answerOptions })
    },
    [updateSlideDebounced, slideId]
  )

  const deleteAnswerOption = useCallback(
    (optionId: string) => {
      deleteAnswerOptionMutate({ namespaceId, listeningPostId, slideId, optionId })
    },
    [deleteAnswerOptionMutate, namespaceId, listeningPostId, slideId]
  )

  const handleOnUpdateKind = (kind: ServerListeningPostSlideKind) => {
    updateSlideMutate({ namespaceId, listeningPostId, slideId, data: { kind } })
  }

  const onDeleteBg = () => {
    updateSlideMutate({
      namespaceId,
      listeningPostId,
      slideId,
      data: { background: null },
    })
  }
  const onDeleteSlide = () => {
    deleteSlideMutation.mutate({ namespaceId, listeningPostId, slideId })
  }

  const setBackground = (slideId: string, background: ServerListeningPostSlideBackground) => {
    updateSlideMutate({ namespaceId, listeningPostId, slideId, data: { background } })
  }

  const hasWebP = slide.webpUrl != null
  const isOgSlide = listeningPost.ogSlideId === slideId

  return (
    <motion.div
      id={slide.id}
      ref={setRefs}
      style={getSlideBgStyles(slide)}
      className={styles.card}
      exit={{ height: 0, opacity: 0 }}
    >
      <div className={styles.cardContent}>
        <div className={styles.top}>
          <SlideTitleInput
            key={slide.title}
            placeholder={`Slide ${slideIndex}`}
            defaultValue={slide.title || ''}
            onUpdate={(title) => updateSlideDebounced(slide.id, { title })}
          />
        </div>
        <SlideCardBottom
          namespaceId={namespaceId}
          listeningPostId={listeningPost.id}
          slide={slide}
          updateAnswerOptionsDebounced={updateAnswerOptionsDebounced}
          deleteAnswerOption={deleteAnswerOption}
        />

        <SlideCoverButton
          className={styles.appearFromBottom}
          slide={slide}
          namespace={namespace}
          hasCover={Boolean(slide.background)}
          onSelectBg={(b) => setBackground(slide.id, b)}
          onDeleteBg={slide.background ? onDeleteBg : undefined}
        />
        <SlideActions
          slide={slide}
          onSelectType={handleOnUpdateKind}
          className={styles.appearFromRight}
          onDelete={onDeleteSlide}
        />
        {hasWebP && <div className={styles.webP}>WEBP</div>}
        {isOgSlide && <div className={styles.ogSlide}>OG</div>}
      </div>
    </motion.div>
  )
}

interface SlideCardBottomProps {
  namespaceId: string
  listeningPostId: string
  slide: ServerListeningPostSlide & { scrollTo?: any }
  updateAnswerOptionsDebounced: (
    answerOptions: Record<string, ServerListeningPostSlideAnswerOption>
  ) => void
  deleteAnswerOption: (optionId: string) => void
}
const SlideCardBottom: React.FC<SlideCardBottomProps> = ({
  namespaceId,
  listeningPostId,
  slide,
  updateAnswerOptionsDebounced,
  deleteAnswerOption,
}) => {
  const { mutate: updateSlideMutate } = useUpdateListeningPostSlideTrpc()
  const updateSlideDebounced = useDebouncedCallback(
    (slideId: string, data: ServerListeningPostSlideUpdate) =>
      updateSlideMutate({ namespaceId, listeningPostId, slideId, data }),
    2000
  )

  const addSubtitle = () => {
    updateSlideMutate({ namespaceId, listeningPostId, slideId: slide.id, data: { subtitle: '' } })
  }
  const addCTA = () => {
    updateSlideMutate({
      namespaceId,
      listeningPostId,
      slideId: slide.id,
      data: { ctaText: 'CTA button', ctaLink: '' },
    })
  }

  switch (slide.kind) {
    case 'multipleChoice':
      return (
        <div className={styles.bottom}>
          <MultipleChoiceField
            scrollTo={slide.scrollTo}
            defaultOptions={slide.answerOptions}
            onUpdate={updateAnswerOptionsDebounced}
            onDelete={deleteAnswerOption}
          />
        </div>
      )
    case 'linkedinAuth':
      return (
        <div className={styles.bottom}>
          <LinkedinField />
        </div>
      )
    case 'recaptcha':
      return (
        <div className={styles.bottom}>
          <Recaptcha />
        </div>
      )
    case 'likert':
      return (
        <div className={styles.bottom}>
          <Likert />
        </div>
      )
    case 'likert7':
      return (
        <div className={styles.bottom} style={{fontSize: '0.6em'}}>
          <Likert7 />
        </div>
      )
    case 'text':
      const hasCTA = slide.ctaLink != null || slide.ctaText != null
      const showButtons = slide.subtitle == null || slide.ctaLink == null || slide.ctaText == null
      return (
        <>
          {(hasCTA || slide.subtitle != null) && (
            <div className={styles.bottom}>
              {slide.subtitle != null && (
                <Text
                  defaultValue={slide.subtitle}
                  onDelete={() =>
                    updateSlideMutate({
                      namespaceId,
                      listeningPostId,
                      slideId: slide.id,
                      data: { subtitle: null },
                    })
                  }
                  onUpdate={(subtitle) => updateSlideDebounced(slide.id, { subtitle })}
                />
              )}
              {hasCTA && (
                <CTAInput
                  defaultCtaLink={slide.ctaLink}
                  defaultCtaText={slide.ctaText}
                  onUpdate={(data) => {
                    updateSlideDebounced(slide.id, data)
                  }}
                  onDelete={() =>
                    updateSlideMutate({
                      namespaceId,
                      listeningPostId,
                      slideId: slide.id,
                      data: { ctaLink: null, ctaText: null },
                    })
                  }
                />
              )}
            </div>
          )}
          {showButtons && (
            <div className={styles.addButtons}>
              {slide.subtitle == null && (
                <div className={styles.addButton} onClick={addSubtitle}>
                  Add subtitle
                </div>
              )}
              {(slide.ctaLink == null || slide.ctaText == null) && (
                <div className={styles.addButton} onClick={addCTA}>
                  Add CTA
                </div>
              )}
            </div>
          )}
        </>
      )
    case 'openEnd':
      return (
        <div className={styles.bottom}>
          <OpenEnd
            defaultValue={slide.subtitle}
            onUpdate={(subtitle) => {
              updateSlideDebounced(slide.id, { subtitle })
            }}
          />
        </div>
      )
    case 'demographics':
      return (
        <div className={styles.bottom}>
          <DemographicQuestionField />
        </div>
      )
    default:
      return null
  }
}

export default SlideCard
