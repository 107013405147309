import clsx from 'clsx'
import React from 'react'
import Geocode from 'react-geocode'
import { LocationArrow } from '../../toneIcons'
import styles from './largeInput.module.scss'

interface InputProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
  title?: string
  name?: string
  value?: string
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  autoComplete?: 'on' | 'off'
  required?: boolean
  isLittleCard?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      title,
      isLittleCard,
      placeholder,
      onChange,
      name,
      value,
      autoComplete,
      autoFocus,
      required,
      disabled,
    },
    ref
  ) => {
    return (
      <div className={clsx(isLittleCard ? styles.little : undefined, styles.container)}>
        <div className={styles.title}>
          {title}
          {required ? '*' : undefined}
        </div>
        <div className={styles.input}>
          <input
            ref={ref}
            placeholder={placeholder}
            name={name}
            onChange={!isLittleCard ? onChange : undefined}
            value={onChange ? value : undefined}
            defaultValue={!onChange ? value : undefined}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
)

interface LocationInputProps extends InputProps {
  autoGetLocation: any
}

export const LocationInput: React.FC<LocationInputProps> = ({
  title,
  placeholder,
  onChange,
  name,
  value,
  autoComplete,
  autoGetLocation,
  autoFocus,
  disabled,
}) => {
  const [gettingLocation, setGettingLocation] = React.useState(false)
  const [gettingLocationError, setGettingLocationError] = React.useState(null)

  const showPosition = async (position: any) => {
    if (position?.coords) {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      const response = await Geocode.fromLatLng(lat, lng)
      const filteredResutls = response.results.filter((r: any) => {
        return (
          r.types.indexOf('neighborhood') >= 0 ||
          r.types.indexOf('postal_code') >= 0 ||
          r.types.indexOf('locality') >= 0
        )
      })
      const location = filteredResutls[0]
      const address =
        location &&
        location.address_components &&
        location.address_components[0] &&
        location.address_components[0].long_name

      const obj: any = { lat, lng }
      if (name) obj[name] = address

      autoGetLocation(obj)
      setGettingLocation(false)
    }
  }
  const error = (error: any) => {
    console.log(error)
    setGettingLocation(false)
    setGettingLocationError(error)
  }

  const getLocation = () => {
    setGettingLocation(true)
    setGettingLocationError(null)
    navigator.geolocation.getCurrentPosition(showPosition, error, {
      timeout: 5000,
      maximumAge: 0,
    })
  }

  return (
    <div className={clsx(styles.container, gettingLocationError ? styles.error : null)}>
      <div className={styles.title}>
        <div>{title}</div>
        {gettingLocationError && <div className={styles.errorMessage}>Failed to get location</div>}
      </div>
      <div className={styles.inputWrap}>
        {navigator.geolocation && (
          <div
            className={clsx(styles.location, gettingLocation ? styles.animating : null)}
            onClick={getLocation}
          >
            <LocationArrow />
          </div>
        )}
        <div className={styles.input}>
          <input
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}

export default Input
