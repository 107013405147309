import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'
import { DeleteIcon } from '../../../currentColorIcons'
import { blobToBase64 } from '../../../utils'
import {
  ServerListeningPostSlideBackground,
  ServerListeningPostSlideBackgroundType,
  ServerNamespace,
} from '../../../utils/trpc'
import ColorPicker from '../ColorPicker/ColorPicker'
import ImagePicker from '../ImagePicker/ImagePicker'
import { UnsplashPhoto } from '../unsplash.model'
import UnsplashPhotoFinder from '../UnsplashPhotoFinder/UnsplashPhotoFinder'
import styles from './BackgroundPicker.module.scss'

interface Props {
  backgroundType?: ServerListeningPostSlideBackgroundType
  namespace: ServerNamespace
  onSelect: (data: ServerListeningPostSlideBackground) => void
  onDelete?: () => void
}

const getTabId = (backgroundType?: ServerListeningPostSlideBackgroundType) => {
  if (backgroundType === 'unsplash') return 0
  else if (backgroundType === 'file') return 1
  else if (backgroundType === 'solid' || backgroundType === 'gradient') return 2
  else return 0
}

const BackgroundPicker: React.FC<Props> = ({ onSelect, onDelete, backgroundType, namespace }) => {
  const [selectedTabId, setSelectedTabId] = React.useState(getTabId(backgroundType))

  const handleOnSelectUnsplash = (photo: UnsplashPhoto) => {
    onSelect({
      type: 'unsplash',
      data: {
        urls: {
          full: photo.urls.full,
          regular: photo.urls.regular,
          small: photo.urls.small,
          thumb: photo.urls.thumb,
        },
        author: {
          name: photo.user.name,
        },
      },
    })
  }

  const handleOnSelectFile = async (file: File) => {
    const base64File = await blobToBase64(file)
    if (base64File) {
      onSelect({ type: 'file', data: base64File })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Tabs
          value={selectedTabId}
          onChange={(event, newValue) => setSelectedTabId(newValue)}
          aria-label="tabs"
        >
          <Tab label="Unsplash" />
          <Tab label="Upload" />
          <Tab label="Background" />
        </Tabs>
        {onDelete && (
          <button onClick={onDelete} className={styles.trash}>
            <DeleteIcon />
          </button>
        )}
      </div>
      <div className={styles.content}>
        {selectedTabId === 0 && (
          <UnsplashPhotoFinder namespace={namespace} onSelect={handleOnSelectUnsplash} />
        )}
        {selectedTabId === 1 && <ImagePicker onSelect={handleOnSelectFile} />}
        {selectedTabId === 2 && <ColorPicker onSelect={onSelect} />}
      </div>
    </div>
  )
}

export default BackgroundPicker
