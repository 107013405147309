import React from 'react'
import { Likert } from '../../../DesignListeningPortal/components/slide-extra-field/Likert/Likert'
import { ServerFeedbackCreate } from '../../../utils/trpc'

interface Props {
  onSelect: (data: ServerFeedbackCreate) => void
}

const LikertSlide: React.FC<Props> = ({ onSelect }) => {
  const [value, setValue] = React.useState(0)

  const handleSelect = React.useCallback(
    (value: number) => {
      setValue(value)
      onSelect({ kind: 'likert', value })
    },
    [onSelect]
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Likert value={value} onSelect={handleSelect} />
    </div>
  )
}

export default LikertSlide
