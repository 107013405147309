import clsx from 'clsx'
import { AspectRatio } from '../../../../../components2/AspectRatio/AspectRatio'
import PreviewBackgroundPickerTrigger from '../../../../../components/PreviewBackgroundPicker/PreviewBackgroundPickerTrigger'
import { getOgSlide } from '../../../../../utils'
import { ServerListeningPost, ServerPublicNamespace } from '../../../../../utils/trpc'
import styles from './Card.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  isPreview: boolean
  listeningPost: ServerListeningPost
  namespace: ServerPublicNamespace
  getValues: (e: string) => string
  setValue?: any
  defaultURL?: string

  handelMakeImageWithText?: (slideId: string) => void
}

const Card: React.FC<Props> = ({
  handelMakeImageWithText,
  getValues,
  setValue,
  isPreview,
  listeningPost,
  className,
  namespace,
  defaultURL,
}) => {
  const listeningPostUrl = namespace.namespaceIdToPublicId?.[listeningPost.id]
  const ogSlide = getOgSlide(listeningPost)

  return (
    <div className={clsx(styles.container, className)}>
      <AspectRatio aspectWH={16 / 9} className={styles.image}>
        <PreviewBackgroundPickerTrigger
          generateImage={(slideId) => handelMakeImageWithText?.(slideId)}
          listeningPost={listeningPost}
        >
          {(handleClick) => (
            <button className={styles.appearFromTop} onClick={handleClick}>
              Change image
            </button>
          )}
        </PreviewBackgroundPickerTrigger>
        <input
          className={styles.input}
          defaultValue={getValues('title')}
          onChange={(e) => setValue('title', e.target.value)}
        />
        <div className={styles.imageOverlay} />
        <img
          src={isPreview ? ogSlide?.og : ogSlide?.webpUrl}
          alt="og preview"
          className={styles.image}
        />
      </AspectRatio>
      <form className={styles.content}>
        <label className={styles.host} htmlFor="url-link">
          {defaultURL}
          <input
            id="url-link"
            type="text"
            name="url"
            placeholder={!listeningPostUrl ? listeningPost.id : ''}
            defaultValue={getValues('url')}
            onChange={(e) => setValue('url', e.target.value)}
          />
        </label>
        <input
          className={styles.shortDescription}
          type="text"
          disabled={isPreview}
          defaultValue={getValues('shortDescription')}
          onChange={(e) => setValue('shortDescription', e.target.value)}
        />

        <input
          className={styles.description}
          type="text"
          disabled={isPreview}
          defaultValue={getValues('description')}
          onChange={(e) => setValue('description', e.target.value)}
        />
      </form>
    </div>
  )
}

export default Card
