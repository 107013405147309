import React from 'react'
import { FullIconButton } from '../../components/button/button'
import CreateNamespacePopup from '../../components/createNamespacePopup/createNamespacePopup'
import EditAdminsPopup from '../EditAdminsPopup/EditAdminsPopup'
import Loading from '../../components/loading/loading'
import NamespaceDetail from '../../components/namespaceDetail/namespaceDetail'
import { SidebarContainer } from '../../components/SidebarContainer/SidebarContainer'
import { useSearch } from '../../hooks/useSearch'
import { Plus } from '../../toneIcons'
import { trpc } from '../../utils/trpc'
import { CellHeader } from '../CellHeader/CellHeader'
import { Spacer } from '../Spacer'
import Table from '../Table/Table'
import { TableHeader } from '../TableHeader/TableHeader'
import { TablePaginator } from '../TablePaginator/TablePaginator'
import { NamespaceCells } from './NamespaceCells'
import styles from './NamespacesDashboard.module.scss'

interface NamespacesDashboardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const NamespacesDashboard: React.FC<NamespacesDashboardProps> = () => {
  const [showCreatePopup, setShowCreatePopup] = React.useState<boolean>(false)
  const [showEditAdminsPopupId, setShowEditAdminsPopupId] = React.useState<string | undefined>(
    undefined
  )

  const [search, setSearch] = useSearch()
  const configQuery = trpc.config.info.useQuery()
  const searchId = search.id
  const afterPageId = search.afterPageId
  const beforePageId = search.beforePageId
  const limit = search.limit

  const pagination = { afterPageId, beforePageId, limit }

  const namespacesQuery = trpc.namespace.all.useQuery({ pagination })
  const namespaceQuery = trpc.namespace.id.useQuery(
    { id: searchId || '' },
    { enabled: Boolean(searchId) }
  )

  const closeEditAdminsPopup = async () => {
    setShowEditAdminsPopupId(undefined)
  }

  const style = {
    '--sidepanel-width': namespaceQuery.data ? 'var(--sidepanel-width-opened)' : 0,
  } as React.CSSProperties

  const columns = 'max-content max-content max-content minmax(min-content, 1fr) max-content'

  const onClose = () => {
    setSearch(s => ({...s, id: undefined}))
  }

  return (
    <div className={styles.container} style={style}>
      <TableHeader className={styles.header}>
        <h2>Brands</h2>
        <Spacer />
        <FullIconButton onClick={() => setShowCreatePopup(true)} icon={<Plus />}>
          Create new
        </FullIconButton>
        <TablePaginator
          afterPageId={namespacesQuery.data?.lastPageId}
          beforePageId={namespacesQuery.data?.firstPageId}
          limit={limit}
          defaultLimit={50}
        />
      </TableHeader>

      <Table columns={columns}>
        <TableHeaderCells />

        {namespacesQuery.isFetching && (
          <div className={styles.loadingRow}>
            <Loading />
          </div>
        )}
        {namespacesQuery.data?.namespaces.map((namespace, index) => {
          return (
            <NamespaceCells
              baseDomain={configQuery.data?.baseDomain}
              protocol={configQuery.data?.protocol}
              namespace={namespace}
              key={namespace.id}
              index={index}
              onEditAdmins={() => setShowEditAdminsPopupId(namespace.id)}
            />
          )
        })}
      </Table>

      {!!showCreatePopup && <CreateNamespacePopup onClose={() => setShowCreatePopup(false)} />}
      {!!showEditAdminsPopupId && namespaceQuery.data != null && (
        <EditAdminsPopup onClose={() => closeEditAdminsPopup()} namespace={namespaceQuery.data} />
      )}
      {searchId && (
        <SidebarContainer key={searchId}>
          <NamespaceDetail namespace={namespaceQuery.data} key={searchId} onClose={onClose} />
        </SidebarContainer>
      )}
    </div>
  )
}

const TableHeaderCells = () => {
  return (
    <>
      <CellHeader sticky>Name</CellHeader>
      <CellHeader>Primary Color</CellHeader>
      <CellHeader>Secondary Color</CellHeader>
      <CellHeader>Admins</CellHeader>
      <CellHeader>Actions</CellHeader>
    </>
  )
}
