import { ServerFeedbackCreate, trpc } from '../../utils/trpc'

export const useFeedbackUpsert = () => {
  const createFeedback = trpc.feedback.create.useMutation()
  const updateFeedback = trpc.feedback.update.useMutation()

  const createFeedbackHandle = async (
    namespaceId: string,
    listeningPostId: string,
    slideId: string,
    data: ServerFeedbackCreate
  ) => {
    return await createFeedback.mutateAsync({
      namespaceId,
      listeningPostId,
      slideId,
      data,
    })
  }

  const updateFeedbackHandle = async (
    feedbackId: string,
    namespaceId: string,
    listeningPostId: string,
    slideId: string,
    data: ServerFeedbackCreate
  ) => {
    return await updateFeedback.mutateAsync({
      feedbackId,
      namespaceId,
      listeningPostId,
      slideId,
      data,
    })
  }

  return { createFeedbackHandle, updateFeedbackHandle }
}

export default useFeedbackUpsert
