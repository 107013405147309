import { createTRPCReact } from '@trpc/react-query'
import type {
  AppRouter,
  RouterOutput as RouterOutputServer,
  RouterInput as RouterInputServer,
} from '../../../server-nest/src/_trpc/router/index'
export const trpc = createTRPCReact<AppRouter>()

export type RouterOutput = RouterOutputServer
export type RouterInput = RouterInputServer

export type ServerUser = RouterOutput['user']['me']

export type ServerNamespace = RouterOutput['namespace']['all']['namespaces'][number]
export type ServerPublicNamespace = RouterOutput['namespace']['publicId']
export type ServerListeningPost = RouterOutput['listeningPost']['id']
export type ServerPublicListeningPost = RouterOutput['listeningPost']['publicId']
export type ServerListeningPostimage = NonNullable<ServerListeningPost['images']>[string]
export type ServerListeningPostSlide = NonNullable<ServerListeningPost['slides']>[string]
export type ServerListeningPostSlideAnswerOption = NonNullable<
  ServerListeningPostSlide['answerOptions']
>[string]

export type ServerListeningPostSlideBackground = NonNullable<ServerListeningPostSlide['background']>
export type ServerListeningPostSlideBackgroundType = ServerListeningPostSlideBackground['type']

export type ServerListeningPostSlideKind = NonNullable<ServerListeningPostSlide['kind']>
export type ServerConfigInfo = RouterOutput['config']['info']

export type ServerNamespaceCreate = RouterInput['namespace']['create']
export type ServerListeningPostUpdate = RouterInput['listeningPost']['update']['data']
export type ServerListeningPostSlideUpdate =
  RouterInput['listeningPostSlide']['updateSlide']['data']

export type ServerFeedback = NonNullable<RouterOutput['feedback']['id']>
export type ServerFeedbackSlideAnswer = NonNullable<ServerFeedback['answers']>[string]
export type ServerFeedbackCreate = RouterInput['feedback']['create']['data']


export type ServerTag = RouterOutput['tags']['all']['tags'][number]

type SplitKind<T extends { kind: unknown }, KindVal extends T['kind']> = T extends { kind: KindVal }
  ? T
  : never

export type ServerFeedbackAnswerMultipleChoice = SplitKind<ServerFeedbackSlideAnswer, 'multiple'>
export type ServerFeedbackAnswerOpenEnd = SplitKind<ServerFeedbackSlideAnswer, 'openEnd'>
export type ServerFeedbackAnswerGoldenQuestion = SplitKind<ServerFeedbackSlideAnswer, 'goldenQuestion'>
export type ServerFeedbackAnswerLikert = SplitKind<ServerFeedbackSlideAnswer, 'likert'>
export type ServerFeedbackAnswerLikert7 = SplitKind<ServerFeedbackSlideAnswer, 'likert7'>
export type ServerFeedbackAnswerCsat = SplitKind<ServerFeedbackSlideAnswer, 'csat'>
export type ServerFeedbackAnswerLinkedin = SplitKind<ServerFeedbackSlideAnswer, 'linkedin'>
export type ServerFeedbackAnswerRecaptcha = SplitKind<ServerFeedbackSlideAnswer, 'recaptcha'>
export type ServerFeedbackAnswerDemographics = SplitKind<ServerFeedbackSlideAnswer, 'demographics'>
