import clsx from 'clsx'
import React from 'react'
import { OpenEndTextarea } from '../../shared/OpenEndTextarea/OpenEndTextarea'
import styles from './OpenEnd.module.scss'

interface Props {
  defaultValue?: string
  onUpdate?: (text: string) => void
  placeholder?: string
  isLittleCard?: boolean
}

export const OpenEnd: React.FC<Props> = ({
  defaultValue,
  isLittleCard,
  onUpdate,
}) => {
  const [text, setText] = React.useState(defaultValue || '')
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
    onUpdate?.(e.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.placeholderTitle}>
        Change placeholder text below
      </div>
      <OpenEndTextarea
        isLittleCard={isLittleCard}
        onChange={onUpdate ? handleOnChange : undefined}
        value={text}
        disabled={!onUpdate}
        placeholder="Share your thoughts"
        className={clsx(styles.textarea)}
      />
    </div>
  )
}
