import React, { CSSProperties } from 'react'
import { useParams } from 'react-router-dom'
import DeletePopup from '../../components2/DeletePopup/DeletePopup'
import { FeedbackView } from '../../components2/FeedbackView/FeedbackView'
import Filters from '../../components2/Filters/Filters'
import { Scrollable } from '../../components2/Scrollable/Scrollable'
import { TableHeader } from '../../components2/TableHeader/TableHeader'
import { TablePaginator } from '../../components2/TablePaginator/TablePaginator'
import { useAuthContext } from '../../contexts'
import { useSearch } from '../../hooks/useSearch'
import { listeningPostService } from '../../models/services/listeningPost.service'
import { useFeedbackDelete } from '../../trpcHooks/feedback/useFeedbackDelete'
import { isNotNull } from '../../utils'
import { ServerListeningPost, ServerPublicNamespace, trpc } from '../../utils/trpc'
import Button from '../button/button'
import FeedbackSelectedTags from '../feedbackSelectedTags/feedbackSelectedTags'
import { Search } from '../InputV2/Search'
import Loading, { LoadingContainer } from '../loading/loading'
import styles from './listeningPostResponses.module.scss'

interface ListeningPostResponsesWithDataProps extends React.HTMLProps<HTMLDivElement> {
  namespace: ServerPublicNamespace
  listeningPost: ServerListeningPost
}
export const ListeningPostResponsesWithData: React.FC<ListeningPostResponsesWithDataProps> = ({
  namespace,
  listeningPost,
}) => {
  const [showDeletePopupId, setShowDeletePopupId] = React.useState<string | undefined>()
  const feedbackDeleteMutate = useFeedbackDelete()
  const [isExporting, setIsExporting] = React.useState(false)
  const namespaceId = namespace.id
  const listeningPostId = listeningPost.id
  const { user } = useAuthContext()
  const [search, setSearch] = useSearch()

  const exportCsv = React.useCallback(
    async (namespaceId, id) => {
      if (user == null || namespaceId == null || id == null) return
      try {
        await listeningPostService.exportCSV(user, namespaceId, id)
      } catch (e) {
        console.log(e)
      }
    },
    [user]
  )

  const pagination = {
    limit: search.limit,
    afterPageId: search.afterPageId,
    beforePageId: search.beforePageId,
  }

  const feedbacksQuery = trpc.feedback.all.useQuery({
    namespaceId,
    listeningPostId,
    pagination,
    tagId: search.untagged ? null : search.tags,
  })

  const { data: listeningPostQuery } = trpc.listeningPost.id.useQuery({
    id: listeningPostId,
    namespaceId,
  })

  const style = { '--filters-width': '275px' } as CSSProperties

  const tagsQuery = trpc.tags.ids.useQuery(
    { namespaceId, data: [search.tags].filter(isNotNull) },
    { enabled: (search.tags?.length ?? 0) > 0 }
  )

  const [localSearch, setLocalSearch] = React.useState(search.query || '')

  const exportedCSV = listeningPost.exportedCSV
  const exportCSVExpiresOn = exportedCSV?.csv?.expiresOn
  const showCsvDownload = exportCSVExpiresOn != null && Date.now() < exportCSVExpiresOn.toMillis()

  const closeDeletePopup = async (deleteId?: string) => {
    if (deleteId && namespaceId) {
      await feedbackDeleteMutate.mutateAsync({ namespaceId, id: deleteId })
    }
    setShowDeletePopupId(undefined)
  }

  const onExport = () => {
    if (listeningPostId) {
      setIsExporting(true)
      exportCsv(namespaceId, listeningPostId)
    }
  }
  const tags = tagsQuery.data
  return (
    <div className={styles.container} style={style}>
      <Filters namespaceId={namespace.id} />

      <div className={styles.content}>
        <TableHeader>
          <Search
            initialValue={localSearch}
            onChangeValue={(v) => setLocalSearch(v)}
            onSearchChange={(query) => setSearch((s) => ({ ...s, query }))}
            placeholder="Search feedback"
            autoFocus
            className={styles.search}
          />
          {showCsvDownload && exportedCSV?.csv?.url && (
            <Button tagName="a" href={exportedCSV.csv.url} className={styles.export} download>
              Download csv
            </Button>
          )}
          <Button loading={isExporting} className={styles.export} onClick={onExport}>
            Export as csv
          </Button>
          <TablePaginator
            afterPageId={feedbacksQuery.data?.lastPageId}
            beforePageId={feedbacksQuery.data?.firstPageId}
            limit={search.limit}
            defaultLimit={50}
          />
        </TableHeader>
        <FeedbackSelectedTags tags={tags} />
        {feedbacksQuery.isLoading && (
          <div className={styles.largeLoadingRow}>
            <Loading />
          </div>
        )}
        {!feedbacksQuery.isLoading && (feedbacksQuery.data?.feedbacks.length ?? 0) <= 0 && (
          <div className={styles.emptyState}>
            <div>No feedback yet</div>
          </div>
        )}

        <Scrollable>
          {listeningPostQuery &&
            feedbacksQuery.data?.feedbacks.map((feedback) => {
              const tagById = feedbacksQuery.data?.tagById
              return (
                <FeedbackView
                  key={feedback.id}
                  listeningPost={listeningPostQuery}
                  feedback={feedback}
                  namespaceId={namespaceId}
                  tagById={tagById}
                  onDelete={setShowDeletePopupId}
                />
              )
            })}
        </Scrollable>
      </div>

      {showDeletePopupId != null && (
        <DeletePopup
          onClose={() => closeDeletePopup()}
          onSubmit={() => closeDeletePopup(showDeletePopupId)}
        >
          <p>
            You are about to <strong>DELETE</strong> this feedback permanently.
          </p>
          <p>
            Please make sure you know what you're doing before proceeding, because this can't be
            undone
          </p>
        </DeletePopup>
      )}
    </div>
  )
}

interface ListeningPostResponsesDataProps {
  listeningPostId: string
  namespace: ServerPublicNamespace
}

const ListeningPostResponsesData: React.FC<ListeningPostResponsesDataProps> = ({
  listeningPostId,
  namespace,
}) => {
  const listeningPostQuery = trpc.listeningPost.id.useQuery({
    namespaceId: namespace.id,
    id: listeningPostId,
  })
  const listeningPost = listeningPostQuery.data

  if (listeningPostQuery.isLoading) {
    return <LoadingContainer />
  }
  if (listeningPost == null) {
    return null
  }

  return <ListeningPostResponsesWithData namespace={namespace} listeningPost={listeningPost} />
}

interface ListeningPostResponsesProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
}

export const ListeningPostResponses: React.FC<ListeningPostResponsesProps> = ({ namespace }) => {
  const { listeningPostId } = useParams()
  if (listeningPostId == null) return null
  return <ListeningPostResponsesData namespace={namespace} listeningPostId={listeningPostId} />
}
