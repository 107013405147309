import React from 'react'
import clsx from 'clsx'
import styles from './AspectRatio.module.scss'

interface AspectRatioProps extends React.HTMLAttributes<HTMLDivElement> {
  aspectWH: number,
}

export const AspectRatio: React.FC<AspectRatioProps> = ({children, className, aspectWH, style}) => {
  const localStyle = {
    ...style,
    "--aspect-ratio": `${(100/aspectWH)}%`
  } as React.CSSProperties
  return <div className={clsx(styles.container, className)} style={localStyle}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
}