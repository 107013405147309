import React from "react";
import { DropIcon } from "../../../currentColorIcons";
import clsx from "clsx";
import styles from './ImagePicker.module.scss'

interface Props {
  onSelect: (file: File) => void;
}

const ImagePicker: React.FC<Props> = ({ onSelect }) => {
  const [dragHover, setDragHover] = React.useState(false);

  const handleOnDrop: React.DragEventHandler<HTMLDivElement> = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      const item = ev.dataTransfer.items[0];
      if (item?.kind === "file") {
        const file = item.getAsFile();
        if (file?.type?.startsWith("image/")) { onSelect(file); }
      }
    } else {
      const file = ev.dataTransfer.files[0];
      if (file?.type?.startsWith("image/")) { onSelect(file); }
    }
    setDragHover(false);
  };

  return (
    <label>
      <input
        type="file"
        name="input-name"
        className={styles.hidden}
        multiple={false}
        accept="image/*"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) { onSelect(file); }
        }}
      />
      <div
        className={clsx(styles.content, dragHover && styles.dragHover)}
        onDrop={handleOnDrop}
        onDragEnter={() => setDragHover(true)}
        onDragLeave={() => setDragHover(false)}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <DropIcon />
        <p className={styles.text}>
          <b>Drag and Drop</b> here or <b>Click to Upload</b>
        </p>
        <p className={styles.footer}>PNG, JPG, GIF upto 3MB</p>
      </div>
    </label>
  );
};

export default ImagePicker;
