import clsx from 'clsx'
import React from 'react'

import styles from './SquareSelect.module.scss'

export const SquareSelect: React.FC<React.SelectHTMLAttributes<HTMLSelectElement>> = ({
  onChange,
  value,
  children,
  className,
}) => {
  return (
    <div className={clsx(styles.select, className)}>
      <select onChange={onChange} value={value}>
        {children}
      </select>
    </div>
  )
}
