import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import { getAuth } from 'firebase/auth'
import { Timestamp } from 'firebase/firestore'
import React from 'react'
import Geocode from 'react-geocode'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import superjson from 'superjson'
import About from './components2/About/About'
import { LinkedinRedirect } from './components/DisplayListeningPortal/LinkedinRedirect'
import Home from './components2/Home/Home'
import Namespace from './components2/Namespace'
import SuperAdmin from './components/superAdmin/superAdmin'
import { AppProviders } from './contexts'
import useInnerHeight from './hooks/useInnerHeight'
import { trpc } from './utils/trpc'

const getBaseUrl = () => {
  if (typeof window !== 'undefined') return '' // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}` // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3001}` // dev SSR should use localhost
}

const env = process.env as any
Geocode.setApiKey(env.REACT_APP_GOOGLE_API_KEY)

const App = () => {
  useInnerHeight()

  const [queryClient] = React.useState(() => new QueryClient())
  const [trpcClient] = React.useState(() => {
    const url = `${getBaseUrl()}/api/trpc`

    superjson.registerCustom<Timestamp, number>(
      {
        isApplicable: (v): v is Timestamp => {
          return v instanceof Timestamp
        },
        serialize: (v) => {
          return v.toMillis()
        },
        deserialize: (v) => {
          return Timestamp.fromMillis(v)
        },
      },
      'timestamp'
    )

    return trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: url,
          // // optional
          headers: async () => {
            const authToken = await getAuth().currentUser?.getIdToken()
            if (!authToken) return {}
            return {
              authorization: `Bearer ${authToken}`,
            }
          },
        }),
      ],
    })
  })

  const subdomain = getNamespaceId()
  const shortDescription = 'Helping people vocalize their needs'
  const description =
    'ListensIn is a customer engagement platform to help people vocalize their needs'
  const titleWithShortDescription = `Listens - ${shortDescription}`

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AppProviders>
          <Helmet>
            <title>Listens</title>
            <meta name="description" content={titleWithShortDescription} />
            <meta property="og:image:alt" content={titleWithShortDescription} />
            <meta property="og:title" content={titleWithShortDescription} />
            <meta name="twitter:title" content={titleWithShortDescription} />
            <meta name="twitter:description" content={description} />
            {/* <meta property="og:image" content={imageWithOg?.og} /> */}
            {/* <meta name="twitter:image" content={imageWithOg?.ogSquare}></meta> */}
          </Helmet>

          <Router>
            <Routes>
              <Route path="/about" element={<About namespaceId={subdomain} />} />
              <Route path="/linkedin" element={<LinkedinRedirect />} />
              {subdomain && <Route path="/*" element={<Namespace namespaceId={subdomain} />} />}
              {!subdomain && (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/*" element={<SuperAdmin />} />
                </>
              )}
            </Routes>
          </Router>
        </AppProviders>
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export const getNamespaceId = () => {
  const splitHostname = window.location.hostname.split('.')
  if ((window as any).namespaceId) return (window as any).namespaceId as string
  else if (splitHostname.length >= 3 && splitHostname[0] !== 'www') {
    return splitHostname[0]
  } else {
    return undefined
  }
}

export default App
