import clsx from 'clsx'
import React from 'react'
import { SlideIcon } from "../../DesignListeningPortal/components/shared/slide-card/SlideIcon"
import { ServerListeningPostSlide } from '../../utils/trpc'
import styles from './SlideImageTiny.module.scss'

interface SlideImageTinyProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  slide: ServerListeningPostSlide
}

export const SlideImageTiny: React.FC<SlideImageTinyProps> = ({className, slide}) => {
  const url = slide.w112h64Url || slide.url;

  // const style = getSlideBgStyles(slide)

  return <div className={clsx(styles.tinyContainer, className)}>
    {url && <img src={url} alt="slide" />}
    <div className={styles.backgroundOverlay} />
    <div className={styles.icon}>
      <SlideIcon kind={slide.kind} />
    </div>
  </div>
}