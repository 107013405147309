import clsx from 'clsx'
import React from 'react'
import { AddIcon } from '../../../currentColorIcons'
import { useAddSlideTrpc } from '../../../trpcHooks/listeningPost/useAddSlideTrpc'
import { useSlidesDesignerContext } from '../../state/slides-designer.context'
import styles from './AddSlideCard.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  namespaceId: string
  listeningPostId: string
  hasSlides: boolean
}

const AddSlideCard: React.FC<Props> = ({ className, namespaceId, listeningPostId, hasSlides }) => {
  const [{ visibleSectionId }] = useSlidesDesignerContext()
  const addSlideMutation = useAddSlideTrpc()

  const onClick = () => {
    const sectionId = visibleSectionId || 'intro'
    addSlideMutation.mutate({ namespaceId, listeningPostId, sectionId })
  }

  return (
    <div
      className={clsx(styles.container, !hasSlides && styles.hasNoSlides, className)}
      onClick={onClick}
    >
      <div className={styles.content}>
        <AddIcon />
        {hasSlides && <span>Add another slide</span>}
        {!hasSlides && <span>Add a slide</span>}
      </div>
    </div>
  )
}

export default AddSlideCard
