import { OgFacebookLogo, OgLinkedinLogo, OgTwitterLogo } from '../../../../../currentColorIcons'
import { createBaseUrl } from '../../../../../utils'
import { ServerListeningPost, ServerPublicNamespace, trpc } from '../../../../../utils/trpc'
import CardPreview from '../CardPreview/CardPreview'
import styles from './OgButton.module.scss'

interface Props {
  onOpen: () => void
  listeningPost: ServerListeningPost
  namespace: ServerPublicNamespace
}

const OgButton: React.FC<Props> = ({ onOpen, listeningPost, namespace }) => {
  const configQuery = trpc.config.info.useQuery()
  const portalDefaultURLPath = createBaseUrl(configQuery.data, namespace.id, '/')
  const listeningPostUrl = namespace.namespaceIdToPublicId?.[listeningPost.id]

  return (
    <div className={styles.container} onClick={onOpen}>
      <div className={styles.contentLeft}>
        <CardPreview
          defaultURL={portalDefaultURLPath}
          listeningPost={listeningPost}
          listeningPostUrl={listeningPostUrl}
          className={styles.containerPreviewCardMenu}
        />
      </div>
      <div className={styles.contentRight}>
        <OgTwitterLogo className={styles.icon} />
        <OgLinkedinLogo className={styles.icon} />
        <OgFacebookLogo className={styles.icon} />
      </div>
    </div>
  )
}

export default OgButton
