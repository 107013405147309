import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearch } from '../../hooks/useSearch'
import { ServerPublicNamespace, trpc } from '../../utils/trpc'
import { CellHeader } from '../CellHeader/CellHeader'
import { CreateButton } from '../CreateLink/CreateLink'
import { Spacer } from '../Spacer'
import Table from '../Table/Table'
import { TableHeader } from '../TableHeader/TableHeader'
import { TablePaginator } from '../TablePaginator/TablePaginator'
import { ListeningPostCells } from './ListeningPostCells'
import styles from './ListeningPosts.module.scss'

interface ListeningPostsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespace: ServerPublicNamespace
}

export const ListeningPosts: React.FC<ListeningPostsProps> = ({ namespace }) => {
  const [search] = useSearch()
  const navigate = useNavigate()
  const listeningPostCreateMutation = trpc.listeningPost.create.useMutation()
  const namespaceId = namespace.id

  const afterPageId = search.afterPageId
  const beforePageId = search.beforePageId
  const limit = search.limit

  const pagination = { afterPageId, beforePageId, limit }
  const listeningPostsQuery = trpc.listeningPost.all.useQuery({ namespaceId, pagination })

  const selectedListeningPostId = search.id
  const columns = 'max-content minmax(min-content, 1fr) max-content'

  const onCreate = async() => {

    const id = await listeningPostCreateMutation.mutateAsync({namespaceId})
    navigate(`/admin/edit/${id}`)
  }


  const style = {
    '--sidepanel-width': selectedListeningPostId ? 'var(--sidepanel-width-opened)' : 0,
  } as React.CSSProperties

  return (
    <div className={styles.container} style={style}>
      <TableHeader>
        <h2 className={styles.header}>Portals</h2>
        <CreateButton onClick={onCreate}>Create</CreateButton>
        <Spacer />
        <TablePaginator
          afterPageId={listeningPostsQuery.data?.lastPageId}
          beforePageId={listeningPostsQuery.data?.firstPageId}
          limit={limit}
          defaultLimit={50}
        />
      </TableHeader>

      <Table columns={columns}>
        <TableHeaderCells />

        {listeningPostsQuery.isLoading && (
          <div className={styles.emptyState}>
            <div>You have no Listening Portals.</div>
            <div>
              Click
              <span onClick={onCreate} className={styles.emptyStateAdd}>
                +Add
              </span>
              in the top right corner to create one now
            </div>
          </div>
        )}

        {listeningPostsQuery.data?.listeningPosts.map((listeningPost, index) => {
          return (
            <ListeningPostCells
              key={listeningPost.id}
              listeningPost={listeningPost}
              index={index}
              isDefault={namespace.defaultListeningPostId === listeningPost.id}
            />
          )
        })}
      </Table>
    </div>
  )
}

const TableHeaderCells = () => (
  <>
    <CellHeader sticky>Name</CellHeader>
    <CellHeader>Images</CellHeader>
    <CellHeader>Actions</CellHeader>
  </>
)
