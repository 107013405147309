import clsx from 'clsx'
import React from 'react'
import { GridSpinner } from '../components/loading/loading'
import styles from './slideSubmitButton.module.scss'

interface SlideSubmitButtonProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  disabled: boolean
  isSubmitting: boolean
  isLittleCard?: boolean
  onClick?: (e: any) => void
}

export const SlideSubmitButton: React.FC<SlideSubmitButtonProps> = ({
  disabled,
  isLittleCard,
  isSubmitting,
  onClick
}) => {
  const classList = clsx(
    isLittleCard ? styles.littleContainer : styles.container,
    !disabled ? styles.active : null,
    isSubmitting ? styles.loading : null
  )

  return (
    <button className={classList} disabled={disabled} type="submit" onClick={onClick}>
      <span>Submit</span>
      <GridSpinner />
    </button>
  )
}

export default SlideSubmitButton
