import React from 'react'
import { ServerFeedbackCreate, ServerListeningPostSlide } from '../../../utils/trpc'
import FeedbackBox from '../../feedbackBox2/feedbackBox2'

interface Props {
  slide: ServerListeningPostSlide
  onSubmit: (data: ServerFeedbackCreate) => void
}

const OpenEndSlide: React.FC<Props> = ({ slide, onSubmit }) => {
  const handleSubmit = React.useCallback(
    async (value: string) => {
      await onSubmit({ kind: 'openEnd', text: value })
    },
    [onSubmit]
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <FeedbackBox
        autoFocus={true}
        disabled={false}
        maxCharacters={280}
        onSubmit={handleSubmit}
        placeholder={slide.subtitle || 'Share your thoughts...'}
      />
    </div>
  )
}

export default OpenEndSlide
