import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSearch } from '../../../../hooks/useSearch'
import SlideSubmitButton from '../../../../slideSubmitButton/slideSubmitButton'
import { isStringNotEmpty } from '../../../../utils'
import { ServerFeedbackCreate, ServerListeningPostSlide } from '../../../../utils/trpc'
import Input from '../../../largeInput/largeInput'
import styles from './DemographicQuestionSlide.module.scss'

const phoneOrEmail = (value: string) => {
  if (value == null) return null
  if (value.length < 2) return null
  if (value.indexOf('@') > 0) return 'email'
  if (/^[\s\d+()-.]+$/.exec(value)) return 'phone'
  return null
}

interface DemographicQuestionSlideProps {
  slide: ServerListeningPostSlide
  visible: boolean

  onSubmit: (data: ServerFeedbackCreate) => void
}

export const DemographicQuestionSlide: React.FC<DemographicQuestionSlideProps> = ({
  onSubmit,
  slide,
}) => {
  const [search] = useSearch()
  const queryParamsName = search.name
  const queryParamsEmailPhone = search.emailPhone

  const {
    getValues,

    formState: { isSubmitting },
    control,
    // handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: queryParamsName || '',
      emailPhoneRaw: queryParamsEmailPhone || '',
      lat: null,
      lng: null,
      ...slide,
    },
  })

  const isPhoneOrEmail = phoneOrEmail(getValues('emailPhoneRaw'))
  let emailPhoneRawTitle = 'Email / Phone number'
  if (isPhoneOrEmail === 'phone') emailPhoneRawTitle = 'Phone number'
  else if (isPhoneOrEmail === 'email') emailPhoneRawTitle = 'Email'

  const hasValues =
    (getValues('name')?.length ?? 0) > 0 && (getValues('emailPhoneRaw')?.length ?? 0) > 0
  const disabled = (slide.required && !hasValues) ?? false

  const _onSubmit = async () => {
    const values = getValues()
    await onSubmit({ kind: 'demographics', name: values.name, emailPhoneRaw: values.emailPhoneRaw })
  }

  // const platform = window.navigator.platform

  // React.useEffect(() => {
  //   const keyDownHandler = (event: any) => {
  //     if (platform === 'Win32') {
  //       if (event.key === 'Enter' && event.shiftKey) {
  //         event.preventDefault()

  //         _onSubmit()
  //       }
  //     } else {
  //       if (event.key === 'Enter' && event.metaKey) {
  //         event.preventDefault()

  //         _onSubmit()
  //       }
  //     }
  //   }

  //   document.addEventListener('keydown', keyDownHandler)

  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler)
  //   }
  // }, [])

  // onSubmit={handleSubmit(_onSubmit)}
  return (
    <div className={styles.content}>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <Input
            className={styles.formInput}
            placeholder="Full name"
            title="Name"
            required={slide.required}
            {...field}
            autoComplete="off"
            disabled={isStringNotEmpty(queryParamsName)}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPhoneRaw"
        render={({ field }) => (
          <Input
            className={styles.formInput}
            placeholder="(123) 456-7890 or name@domain.com"
            title={emailPhoneRawTitle}
            {...field}
            required={slide.required}
            autoComplete="off"
            disabled={isStringNotEmpty(queryParamsEmailPhone)}
          />
        )}
      />

      <SlideSubmitButton isSubmitting={isSubmitting} disabled={disabled} onClick={_onSubmit} />
    </div>
  )
}
