import clsx from 'clsx'
import React from 'react'
import { getSlideBgStyles } from '../../../../DesignListeningPortal/utils'
import useDimensions from '../../../../hooks/useDimensions'
import useFeedbackUpsert from '../../../../trpcHooks/feedback/feedBackUpsert'
import {
  ServerFeedbackCreate,
  ServerListeningPost,
  ServerListeningPostSlide,
  ServerPublicNamespace,
  trpc
} from '../../../../utils/trpc'
import { useSlidesDisplayContext } from '../../state/slides-display.context'
import { SlidesDisplayAction } from '../../state/slides-display.reducer'
import { SlideContent } from './SlideContent'
import styles from './SlideContentContainer.module.scss'

interface SlideContentContainerProps {
  slide: ServerListeningPostSlide
  visible: boolean
  activeIndex: number
  namespace: ServerPublicNamespace
  listeningPost: ServerListeningPost
  feedbackId?: string
  setFeedbackId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const SlideContentContainer: React.FC<SlideContentContainerProps> = ({
  slide,
  namespace,
  listeningPost,
  visible,
  activeIndex,
  feedbackId,
  setFeedbackId,
}) => {
  const feedbackQuery = trpc.feedback.id.useQuery(
    { namespaceId: namespace.id, id: feedbackId || '' },
    { enabled: Boolean(feedbackId) }
  )

  const namespaceId = namespace.id
  const listeningPostId = listeningPost.id
  const slideId = slide.id
  const slideKind = slide.kind

  const { dispatch } = useSlidesDisplayContext()
  const { createFeedbackHandle, updateFeedbackHandle } = useFeedbackUpsert()
  const [isNecessarily, setIsNecessarily] = React.useState(false)
  const [ref, dimensions] = useDimensions<HTMLDivElement>()

  const handleSubmit = React.useCallback(
    async (data: ServerFeedbackCreate, passedFeedbackId?: string) => {
      const _feedbackId = passedFeedbackId || feedbackId

      if (_feedbackId != null) {
        const updatedFeedbackId = await updateFeedbackHandle(
          _feedbackId,
          namespaceId,
          listeningPostId,
          slideId,
          data
        )
        setFeedbackId(updatedFeedbackId)
      } else {
        const createdFeedbackId = await createFeedbackHandle(
          namespaceId,
          listeningPostId,
          slideId,
          data
        )
        setFeedbackId(createdFeedbackId)
      }

      dispatch({ type: 'NEXT_SLIDE' })
    },
    [feedbackId, dispatch, updateFeedbackHandle, createFeedbackHandle, setFeedbackId]
  )

  const handleNavigation = (dispatchAction: SlidesDisplayAction) => {
    const isVerified = feedbackQuery.data?.linkedin
    const isHuman = false //TODO: fix

    if (dispatchAction.type === 'NEXT_SLIDE') {
      if (slide.kind === 'linkedinAuth' && !isVerified) {
        console.log(123)
        setIsNecessarily(true)
        return false
      }
      if (slide.kind === 'recaptcha' && !isHuman) {
        setIsNecessarily(true)
        return false
      }
    }

    setIsNecessarily(false)
    dispatch(dispatchAction)
  }

  const classList = clsx(styles.section, visible ? styles.visible : undefined)

  const [styleFontSize, setStyleFontSize] = React.useState('')
  React.useEffect(() => {
    if (dimensions == null) { return }

    if (slideKind === 'likert' || slideKind === 'likert7') {
      const fontSize = (dimensions.width - 140 * 2) * 0.02
      setStyleFontSize(`${fontSize}px`)
    } else {
      const fontSize = Math.min(dimensions.width, dimensions.height) * 0.02
      setStyleFontSize(`${fontSize}px`)
    }
  }, [dimensions, slideKind])

  return (
    <section style={getSlideBgStyles(slide)} className={classList} ref={ref}>
      <div className={styles.content}>
        {dimensions && (
          <SlideContent
            visible={visible}
            activeIndex={activeIndex}
            slide={slide}
            listeningPost={listeningPost}
            feedbackQuery={feedbackQuery?.data}
            handleNavigation={handleNavigation}
            handleUpsert={handleSubmit}
            isNecessarily={isNecessarily}
            style={{ fontSize: styleFontSize }}
          />
        )}
      </div>
    </section>
  )
}
