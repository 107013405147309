import clsx from 'clsx'
import React from 'react'
import styles from './Table.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  columns: string
}

export const Table: React.FC<Props> = ({ children, columns, style, className }) => {
  const _style = {
    ...(style || {}),
    '--table-template-columns': columns,
  } as React.CSSProperties

  const classList = clsx(className, styles.tableContainer)

  return (
    <div className={classList} style={_style}>
      <div className={styles.table}>{children}</div>
    </div>
  )
}

export default Table
