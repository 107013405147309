import { useLocalStorage } from '@rehooks/local-storage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../components/auth/login'
import Logout from '../components/auth/Logout2'
import PasswordConfirm from '../components/auth/passwordConfirm'
import PasswordReset from '../components/auth/passwordReset'
import { RequireAdminAuth, RequireUnAuthedRoute } from '../components/auth/Routes2'
import Signup from '../components/auth/Signup2'
import Error404 from './Error404/Error404'
import { ListeningPost, ListeningPostWithParams } from '../components/listeningPost/listeningPost'
import { LoadingContainer } from '../components/loading/loading'
import { useMediaQueryContext } from '../contexts'
import { namespaceStyle } from '../utils'
import { trpc } from '../utils/trpc'
import { ListeningPostsAdmin } from './ListeningPostsAdmin/ListeningPostsAdmin'
import PageMeta from './PageMeta'

interface NamespaceProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  namespaceId: string
}

export const Namespace: React.FC<NamespaceProps> = ({ namespaceId }) => {
  const { isMobileView } = useMediaQueryContext()
  const [navMinimized] = useLocalStorage('navMinimized', false)

  const publicNamespaceQuery = trpc.namespace.publicId.useQuery({ id: namespaceId })

  if (publicNamespaceQuery.isError) {
    return <Error404 />
  }
  if (publicNamespaceQuery.data == null) return null

  const isOpened = !(navMinimized || isMobileView)
  const defaultListeningPostId = publicNamespaceQuery.data.defaultListeningPostId

  console.log("Namespace")

  const style = {
    '--nav-width': isOpened ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties

  return (
    <div style={namespaceStyle(publicNamespaceQuery.data, style)}>
      <PageMeta namespace={publicNamespaceQuery.data} />
      <Routes>
        <Route
          path="admin/*"
          element={
            <RequireAdminAuth namespaceId={namespaceId}>
              <ListeningPostsAdminWithoutData namespaceId={namespaceId} />
            </RequireAdminAuth>
          }
        />
        <Route
          path="login"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <Login namespace={publicNamespaceQuery.data} />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="signup"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <Signup namespace={publicNamespaceQuery.data} />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="passwordReset"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <PasswordReset namespace={publicNamespaceQuery.data} />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="passwordConfirm"
          element={
            <RequireUnAuthedRoute redirectTo="/admin">
              <PasswordConfirm namespace={publicNamespaceQuery.data} />
            </RequireUnAuthedRoute>
          }
        />
        <Route
          path="logout"
          element={
            <Logout namespace={publicNamespaceQuery.data} />
          }
        />

        <Route
          path=":listeningPostId/*"
          element={<ListeningPostWithParams namespace={publicNamespaceQuery.data} />}
        />

        {defaultListeningPostId != null && (
          <Route
            path="*"
            element={
              <ListeningPost
                namespace={publicNamespaceQuery.data}
                listeningPostId={defaultListeningPostId}
              />
            }
          />
        )}
        {!defaultListeningPostId && <Route path="*" element={<Error404 />} />}
      </Routes>
    </div>
  )
}

export default Namespace

interface ListeningPostsAdminWithoutDataProps {
  namespaceId: string
}

const ListeningPostsAdminWithoutData: React.FC<ListeningPostsAdminWithoutDataProps> = ({
  namespaceId,
}) => {
  const namespaceQuery = trpc.namespace.id.useQuery({ id: namespaceId })

  if (namespaceQuery.isLoading) return <LoadingContainer namespace={namespaceQuery.data} />
  if (namespaceQuery.isError) {
    return <Error404 />
  }

  return <ListeningPostsAdmin namespace={namespaceQuery.data} />
}
