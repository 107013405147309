import React from 'react'
import { Warning } from '../../toneIcons'
import Popup from '../../components/popup/popup'
import styles from './DeletePopup.module.scss'
interface DeletePopupProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  onClose: () => void
}

export const DeletePopup: React.FC<DeletePopupProps> = ({ onClose, onSubmit, children }) => {
  return (
    <Popup icon={<Warning className={styles.warning} />} onClose={onClose}>
      <h4>Delete Permanently</h4>
      {children && <div>{children}</div>}
      {!children && <p>This is CAN NOT be undone. Are you sure?</p>}
      {onSubmit != null && (
        <div className={styles.button} onClick={onSubmit}>
          Yes, delete it
        </div>
      )}
    </Popup>
  )
}

export default DeletePopup
