import React from 'react'
import { TinyColor } from '@ctrl/tinycolor'
import clsx from 'clsx'
import styles from './input.module.scss'
import { isDark, isNotNull } from '../../utils'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error?: string
  value?: string
  suggestColors?: string[]
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      placeholder,
      type = 'text',
      name,
      error,
      leftIcon,
      rightIcon,
      value,
      disabled,
      onChange,
      autoFocus,
      suggestColors,
      ...props
    },
    ref
  ) => {
    const hasLeftIcon = leftIcon != null
    const classList = clsx(
      className,
      error ? styles.error : undefined,
      styles.container,
      hasLeftIcon ? styles.hasLeftIcon : undefined,
      disabled ? styles.disabled : undefined
    )

    const onSelectSuggestColor = (color: string) => {
      onChange?.({ target: { value: color, name, type } } as any)
    }

    const title = [placeholder, error].filter(isNotNull).join(' \u2013 ')
    const titleColor =
      type === 'color' && value ? (new TinyColor(value).isLight() ? '#111' : '#eee') : undefined

    const filteredSuggestColors = suggestColors
      ?.filter((c) => c?.toLowerCase() !== value?.toLowerCase())
      .filter(isNotNull)

    return (
      <div className={classList}>
        <label>
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value || ''}
            className={styles.input}
            disabled={disabled}
            autoFocus={autoFocus}
            {...props}
            ref={ref}
          />

          <div className={styles.complications}>
            <div className={styles.title} style={{ color: titleColor }}>
              {title}
            </div>
            {hasLeftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
          </div>
        </label>

        {(filteredSuggestColors?.length || 0) > 0 && (
          <div className={styles.colors}>
            {filteredSuggestColors?.map((background, index) => {
              try {
                const color = isDark(new TinyColor(background)) ? '#fefefe' : '#333'
                return (
                  <div
                    key={index}
                    className={styles.colorBox}
                    style={{ background, color }}
                    onClick={() => onSelectSuggestColor(background)}
                  />
                )
              } catch (error) {
                return null
              }
            })}
          </div>
        )}
      </div>
    )
  }
)
