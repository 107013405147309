import { trpc } from "../../utils/trpc"

export const useFeedbackDelete = () => {
  const utils = trpc.useContext()
  return trpc.feedback.delete.useMutation({
    onSuccess: () => {
      utils.feedback.all.invalidate()
    },
  })
  
}