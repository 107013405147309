import { User } from 'firebase/auth'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Dictionary, isArray } from 'lodash'

export class HttpError extends Error {
  _response: AxiosResponse | undefined
  _body: any

  constructor(message: string, res: AxiosResponse | undefined) {
    super(message)

    this._response = res
  }

  get body() {
    return this._body
  }

  get response() {
    return this._response
  }

  static fromAxiosError(maybeAxiosError: any | AxiosError) {
    if ((maybeAxiosError as any | undefined).isAxiosError !== true) {
      // this is some other error, do not convert, return as is
      return maybeAxiosError
    }

    const axiosError = maybeAxiosError as AxiosError
    let message = axiosError?.message

    const res = axiosError?.response
    const errorData = res?.data
    const errorDataMessage = errorData?.message || errorData?.error
    const errorDateMessageStr = isArray(errorDataMessage)
      ? errorDataMessage[0]
      : errorDataMessage

    const visibleMessage = errorDateMessageStr || message

    const convertedError = new HttpError(visibleMessage, res)
    return convertedError
  }
}

export class AuthAxiosService {
  protected basePath?: string

  async get<T = any>(path: string, user: User, params: any = {}) {
    const headers = await this.headersFromUser(user)
    try {
      const res = await axios.get<T>(this.constructPath(path), {
        headers,
        params,
      })
      return res.data
    } catch (error: any) {
      throw HttpError.fromAxiosError(error)
    }
  }

  async _post<T = any>(path: string, headers: any, data?: any) {
    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data'
    }

    try {
      const res = await axios.post<T>(this.constructPath(path), data, {
        headers,
      })
      return res.data
    } catch (error: any) {
      throw HttpError.fromAxiosError(error)
    }
  }

  async post<T = any>(path: string, user: User, data?: any) {
    const headers = await this.headersFromUser(user)
    return this._post<T>(path, headers, data)
  }

  /**
   * basePath = "/cadences/"
   * path = "/1234/steps/1231/"
   *
   * returns cadences/1234/steps/1231
   * @param path
   * @private
   */
  private constructPath(path: string = '') {
    const pathWoStartSlash = (p: string) => {
      let path = p.endsWith('/') ? p.slice(0, -1) : p
      path = path.startsWith('/') ? path.slice(1) : path
      return path
    }

    let base = ''

    if (this.basePath) {
      base += `/${pathWoStartSlash(this.basePath)}`
    }

    if (path) {
      base += `/${pathWoStartSlash(path)}`
    }
    return base
  }

  private async headersFromUser(user: User) {
    const userIdToken = user != null && (await user.getIdToken())
    const headers: Dictionary<string> = {}
    if (userIdToken) headers['X-TOKEN'] = userIdToken
    return headers
  }
}

export const authAxiosService = new AuthAxiosService()
