
type MethodType = 'POST'
export const fetchUrl = async (
  method: MethodType,
  url: string,
  options?: any
) => {
  const body = options?.body
  
  const contentType = body instanceof FormData ? undefined : 'application/json'
  let headers: any = {}
  if (options?.headers) headers = { ...options.headers }
  if (contentType) headers['Content-Type'] = contentType

  const res = await fetch(url, {
    method,
    headers,
    body:
      body == null
        ? undefined
        : body instanceof FormData
        ? body
        : JSON.stringify(body),
  })
  if (res.ok) {
    return await res.json()
  } else if (res.status >= 400 && res.status < 600) {
    throw await res.json()
  }
  // throw new Error(`${method} with token failed`, url, body);
  throw new Error(`${method} with token failed`)
}