import { ServerListeningPostSlideBackground } from "../../utils/trpc";

class ColorBackgroundsService {
  getAll = async (): Promise<ServerListeningPostSlideBackground[]> => {
    return [
      {
        type: "solid",
        data: "gray",
      },
      {
        type: "gradient",
        data: "linear-gradient(to bottom right, red, blue)",
      },
      {
        type: "gradient",
        data: "linear-gradient(to bottom right, yellow, pink)",
      },
      {
        type: "solid",
        data: "skyblue",
      },
      {
        type: "solid",
        data: "gray",
      },
      {
        type: "solid",
        data: "red",
      },
      {
        type: "solid",
        data: "yellow",
      },
      {
        type: "solid",
        data: "green",
      },
      {
        type: "solid",
        data: "pink",
      },
    ];
  };
}

export const colorBackgroundsService = new ColorBackgroundsService();
