import clsx from 'clsx'
import { sortBy } from 'lodash'
import React from 'react'
import { NavigationArrow } from '../../../../currentColorIcons'
import { Warning } from '../../../../toneIcons'
import {
  ServerFeedback,
  ServerFeedbackCreate,
  ServerListeningPost,
  ServerListeningPostSlide,
} from '../../../../utils/trpc'
import { DemographicQuestionSlide } from '../../slide-kinds/DemographicQuestionSlide/DemographicQuestionSlide'
import Likert7Slide from '../../slide-kinds/Likert7Slide'
import LikertSlide from '../../slide-kinds/LikertSlide'
import LinkedinSlide from '../../slide-kinds/LinkedinSlide/LinkedinSlide'
import MultipleChoiceSlide from '../../slide-kinds/MultipleChoiceSlide/MultipleChoiceSlide'
import OpenEndSlide from '../../slide-kinds/OpenEndSlide'
import RecaptchaSlide from '../../slide-kinds/RecaptchaSlide'
import SlideTitle from '../../slide-kinds/SlideTitle/SlideTitle'
import { SlidesDisplayAction } from '../../state/slides-display.reducer'
import styles from './SlideContent.module.scss'

interface Props {
  listeningPost: ServerListeningPost
  slide: ServerListeningPostSlide
  visible: boolean
  isNecessarily: boolean
  style: { fontSize: string }
  activeIndex: number
  feedbackQuery?: ServerFeedback

  handleNavigation: (type: SlidesDisplayAction) => void
  handleUpsert: (data: ServerFeedbackCreate, passedFeedbackId?: string) => void
}

export const SlideContent: React.FC<Props> = ({
  slide,
  listeningPost,
  visible,
  isNecessarily,
  activeIndex,
  style,
  feedbackQuery,
  handleNavigation,
  handleUpsert,
}) => {
  const [currentSlideId, setCurrentSlideId] = React.useState<string>('')

  const slides = sortBy(Object.values(listeningPost.slides || {}), 'order')
  const firstSlidesId = slides[0]?.id
  const lastSlideId = slides.at(-1)?.id

  React.useEffect(() => {
    setCurrentSlideId(slides[activeIndex]?.id || '')
  }, [activeIndex, slides])

  const prevSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    handleNavigation({ type: 'PREV_SLIDE' })
  }

  const nextSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    handleNavigation({ type: 'NEXT_SLIDE' })
  }

  const canGoLeft = firstSlidesId !== currentSlideId
  const canGoRight = lastSlideId !== currentSlideId

  return (
    <div className={styles.slide}>
      {canGoLeft && <LeftArrow onClick={prevSlide} />}
      {canGoRight && <RightArrow onClick={nextSlide} />}

      <div className={styles.container}>
        <SlideTitle className={styles.title}>{slide.title}</SlideTitle>

        <Content
          slide={slide}
          listeningPost={listeningPost}
          visible={visible}
          feedbackQuery={feedbackQuery}
          style={style}
          handleUpsert={handleUpsert}
        />

        {isNecessarily && (
          <div className={styles.warning}>
            <Warning className={styles.warningImage} />
            <p className={styles.warningText}>This is a mandatory field, fill it out!</p>
          </div>
        )}
      </div>

      <div className={styles.bottomButtons}>
        {canGoLeft && <LeftArrow onClick={prevSlide} />}
        {!canGoLeft && <div />}
        {canGoRight && <RightArrow onClick={nextSlide} />}
        {!canGoRight && <div />}
      </div>
    </div>
  )
}

interface ContentProps {
  slide: ServerListeningPostSlide
  listeningPost: ServerListeningPost
  visible: boolean
  style: { fontSize: string }
  feedbackQuery?: ServerFeedback

  handleUpsert: (data: ServerFeedbackCreate, passedFeedbackId?: string) => void
}

const Content: React.FC<ContentProps> = ({
  slide,
  listeningPost,
  visible,
  feedbackQuery,
  style,
  handleUpsert,
}) => {
  switch (slide.kind) {
    case 'linkedinAuth':
      return (
        <div className={styles.content} style={style}>
          <LinkedinSlide
            onAuth={handleUpsert}
            listeningPost={listeningPost}
            feedback={feedbackQuery}
          />
        </div>
      )
    case 'recaptcha':
      return (
        <div className={styles.content} style={style}>
          <RecaptchaSlide onChange={handleUpsert} />
        </div>
      )
    case 'likert':
      return (
        <div className={styles.content} style={style}>
          <LikertSlide onSelect={handleUpsert} />
        </div>
      )
    case 'likert7':
      return (
        <div className={styles.content} style={style}>
          <Likert7Slide onSelect={handleUpsert} visible={visible} />
        </div>
      )
    case 'openEnd':
      return (
        <div className={styles.content} style={style}>
          <OpenEndSlide slide={slide} onSubmit={handleUpsert} />
        </div>
      )
    case 'multipleChoice':
      return (
        <div className={styles.content} style={style}>
          <MultipleChoiceSlide slide={slide} visible={visible} onSelect={handleUpsert} />
        </div>
      )
    case 'demographics':
      return (
        <div className={styles.content} style={style}>
          <DemographicQuestionSlide slide={slide} visible={visible} onSubmit={handleUpsert} />
        </div>
      )
    case 'text':
      const hasCTA = slide.ctaLink != null && slide.ctaText != null
      if (hasCTA || slide.subtitle != null) {
        return (
          <div className={styles.content} style={style}>
            {slide.subtitle != null && (
              <SlideTitle className={styles.title}>{slide.subtitle}</SlideTitle>
            )}
            {hasCTA && (
              <a
                className={styles.ctaButton}
                href={slide.ctaLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {slide.ctaText}
              </a>
            )}
          </div>
        )
      }
      return null
    default:
      return null
  }

  // {error && <span className={styles.error}>{error?.message}</span>}
}

interface ArrowProps extends React.HTMLProps<HTMLDivElement> {}
const LeftArrow: React.FC<ArrowProps> = ({ className, onClick }) => {
  return (
    <div className={clsx(styles.arrow, styles.left, className)} onClick={onClick}>
      <NavigationArrow />
    </div>
  )
}

const RightArrow: React.FC<ArrowProps> = ({ className, onClick }) => {
  return (
    <div className={clsx(styles.arrow, styles.right, className)} onClick={onClick}>
      <NavigationArrow />
    </div>
  )
}
