import React from 'react'
import clsx from 'clsx'
import styles from './SidebarContainer.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  updating?: boolean
}

export const SidebarContainer: React.FC<Props> = ({children, updating}) => {
  return <div className={styles.container}>
    <div className={clsx(styles.saving, updating ? styles.active : null)}>Saving...</div>
    <div className={styles.content}>
      {children}
    </div>
  </div>
}