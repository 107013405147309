import clsx from 'clsx'
import React from 'react'
import { Tooltip } from '../../../../../components/Tooltip'
import { TrashIcon } from '../../../../../currentColorIcons'
import { ServerListeningPostSlide, ServerListeningPostSlideKind } from '../../../../../utils/trpc'
import { SlideIcon } from '../SlideIcon'
import styles from './SlideActions.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  onDelete: () => void
  onSelectType: (kind: ServerListeningPostSlideKind) => void
  slide: ServerListeningPostSlide
}

export const SlideActions: React.FC<Props> = ({ className, onDelete, slide, onSelectType }) => {
  const kinds: ServerListeningPostSlideKind[] = [
    'multipleChoice',
    'text',
    'linkedinAuth',
    // 'recaptcha',
    'likert',
    'likert7',
    'openEnd',
    'demographics',
  ]

  return (
    <div className={clsx(styles.container, className)}>
      <Button className={styles.trash} onClick={onDelete}>
        <TrashIcon />
      </Button>

      <div className={styles.buttons}>
        {kinds.map((kind) => {
          return (
            <Button
              key={kind}
              onClick={() => onSelectType(kind)}
              selected={kind === slide.kind}
              label={slideKindPretty(kind)}
            >
              <SlideIcon kind={kind} />
            </Button>
          )
        })}
      </div>
    </div>
  )
}

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  selected?: boolean
}

const Button: React.FC<ButtonProps> = ({ className, children, selected, label, onClick }) => {
  const classList = clsx(className, styles.button, selected && styles.selected)
  if (!label) {
    return (
      <button className={classList} onClick={onClick}>
        {children}
      </button>
    )
  } else {
    return (
      <Tooltip label={label}>
        <button className={classList} onClick={onClick}>
          {children}
        </button>
      </Tooltip>
    )
  }
}

const slideKindPretty = (kind: ServerListeningPostSlideKind) => {
  switch (kind) {
    case 'multipleChoice':
      return 'Multiple Choice'
    case 'text':
      return 'Text'
    case 'linkedinAuth':
      return 'LinkedIn Auth'
    case 'recaptcha':
      return 'reCaptcha'
    case 'likert':
      return 'Likert'
    case 'likert7':
      return 'Likert 7 questions'
    case 'openEnd':
      return 'Open End'
    case 'demographics':
      return 'Demographics'
    default:
      return undefined
  }
}
