import { TRPCClientError } from '@trpc/client'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { namespaceStyle } from '../../utils'
import { ServerNamespace, trpc } from '../../utils/trpc'
import { Input } from '../../components/input/input'
import { DeletablePill } from '../../components/pills/pills'
import Popup from '../../components/popup/popup'
import styles from './EditAdminsPopup.module.scss'

interface EditAdminsPopupProps {
  namespace: ServerNamespace
  onClose: () => void
}

export const EditAdminsPopup: React.FC<EditAdminsPopupProps> = ({ namespace, onClose }) => {
  const namespaceId = namespace.id
  const utils = trpc.useContext()
  const namespaceRemoveAdminMutation = trpc.namespaceUsers.removeAdmin.useMutation({
    onSuccess: () => {
      utils.namespace.all.invalidate()
      utils.namespace.id.invalidate({ id: namespace.id })
      utils.namespace.publicId.invalidate({ id: namespace.id })
    },
  })
  const namespaceAddAdminMutation = trpc.namespaceUsers.inviteAdmins.useMutation({
    onSuccess: () => {
      utils.namespace.all.invalidate()
      utils.namespace.id.invalidate({ id: namespace.id })
      utils.namespace.publicId.invalidate({ id: namespace.id })
      onClose()
    },
  })

  const {
    getValues,
    watch,
    formState: { errors },
    control,
    setError,
    handleSubmit,
    clearErrors,
  } = useForm({
    defaultValues: {
      global: '',
      emails: '',
      namespaceId,
    },
  })

  const isDisabled = !watch('emails')

  const value = namespace.admins
  const hasValue = value != null && value.length > 0

  const onSubmit = () => {
    try {
      const emails = getValues('emails')
        .split(',')
        .map(e => e.trim())

      namespaceAddAdminMutation.mutate({ id: namespaceId, emails })
    } catch (error: unknown) {
      if (error instanceof TRPCClientError)
        return setError('global', { type: 'custom', message: 'Invalid' })
    }
  }

  return (
    <Popup onClose={onClose} onSubmit={handleSubmit(onSubmit)} style={namespaceStyle()}>
      <h4>Edit Admins for {namespace.name}</h4>
      {hasValue && (
        <div className={styles.values}>
          <div className={styles.header}>Existing Admins</div>
          {value?.map((email, key) => (
            <DeletablePill
              key={key}
              text={email}
              onDelete={() => namespaceRemoveAdminMutation.mutate({ id: namespaceId, email })}
            />
          ))}
        </div>
      )}
      <Controller
        control={control}
        name="emails"
        render={({ field }) => (
          <Input
            className={styles.input}
            title="Invite more admins"
            placeholder="comma separated list of emails"
            // error={errors.emails.message}
            {...field}
            name="emails"
            type="text"
          />
        )}
      />

      {errors.global && <div>{errors.global.message}</div>}
      <button
        className={styles.button}
        disabled={isDisabled}
        onClick={() => clearErrors()}
        type="submit"
      >
        Send invitations
      </button>
    </Popup>
  )
}

export default EditAdminsPopup
