import React from "react";
import { useDebouncedCallback } from "use-debounce";
import { MagnifyingGlassIcon } from "../../currentColorIcons";
import { BorderlessInput } from "./BorderlessInput";

interface SearchHeadingProps extends React.HTMLProps<HTMLDivElement> {
  rightContent?: React.ReactNode;
  border?: boolean;
  placeholder?: string;
  initialValue?: string | null;
  onSearchChange: (query: string) => void
  onChangeValue?: (query: string) => void
}
export const Search: React.FC<SearchHeadingProps> = ({className, placeholder, initialValue, onSearchChange, onChangeValue}) => {
  const debounced = useDebouncedCallback(onSearchChange, 400);
  const [search, setSearch] = React.useState<string>(initialValue || "")
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setSearch(query)
    debounced(query)
    onChangeValue?.(query)
  }

  const onClear = () => {
    setSearch("")
    debounced("")
    onChangeValue?.("")
  }

  return <BorderlessInput onChange={handleChange} placeholder={placeholder} value={search} leftIcon={<MagnifyingGlassIcon />} autoFocus className={className} onClear={search.length > 0 ? onClear : undefined} />
}