import React from 'react'
import {
  CheckIcon,
  DemographicsIcon,
  LikertIcon,
  Likert7Icon,
  OpenEndIcon,
  SlideTextIcon,
} from '../../../../currentColorIcons'
import { LinkedinIcon, RecaptchaIcon } from '../../../../toneIcons'
import { ServerListeningPostSlideKind } from '../../../../utils/trpc'

interface Props extends React.SVGProps<SVGElement> {
  kind?: ServerListeningPostSlideKind
}

export const SlideIcon: React.FC<Props> = ({ kind, className }) => {
  switch (kind) {
    case 'multipleChoice':
      return <CheckIcon className={className} />
    case 'text':
      return <SlideTextIcon className={className} />
    case 'recaptcha':
      return <RecaptchaIcon className={className} />
    case 'linkedinAuth':
      return <LinkedinIcon className={className} />
    case 'likert':
      return <LikertIcon className={className} />
    case 'likert7':
      return <Likert7Icon className={className} />
    case 'openEnd':
      return <OpenEndIcon className={className} />
    case 'demographics':
      return <DemographicsIcon className={className} />
    default:
      return null
  }
}
