import clsx from 'clsx'
import React from 'react'
import styles from './Cell.module.scss'

interface CellProps extends React.HTMLProps<HTMLDivElement> {
  sticky?: boolean
  active?: boolean
  index: number
}

export const Cell: React.FC<CellProps> = ({ index, sticky, className, active, onClick, children }) => {
  const classList = clsx(
    styles.cell,
    sticky ? styles.sticky : undefined,
    index % 2 === 0 ? styles.even : undefined,
    active ? styles.active : undefined,
    className
  )

  return <div className={classList} onClick={onClick}>{children}</div>
}