import React, { createContext, useReducer, Dispatch } from "react";
import { slidesDesignerReducer, SlidesDesignerAction, SlidesDesignerState } from "./slides-designer.reducers";

const initialState = {};


type ContextProps = [SlidesDesignerState, Dispatch<SlidesDesignerAction>]

export const SlidesDesignerContext = createContext<ContextProps>([ initialState, () => null,]);

export const SlidesDesignerProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(slidesDesignerReducer, initialState);

  return (
    <SlidesDesignerContext.Provider value={[state, dispatch]}>
      {children}
    </SlidesDesignerContext.Provider>
  );
};

export const useSlidesDesignerContext = () => React.useContext(SlidesDesignerContext);
