import React from 'react'

export const useInnerHeight = () => {
  React.useEffect(() => {
    const setDocHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
    }
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);

    setDocHeight();
    return () => {
      window.removeEventListener('resize', setDocHeight);
      window.removeEventListener('orientationchange', setDocHeight);
    }
  }, [])
} 

export default useInnerHeight;
