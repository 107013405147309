import React from "react";
import clsx from "clsx";
import { useSlidesDesignerContext } from "../../../state/slides-designer.context";
import styles from './NavSectionTitle.module.scss'

interface Props extends React.HTMLProps<HTMLDivElement> {
  sectionId: string;
}

export const NavSectionTitle: React.FC<Props> = ({className, sectionId}) => {
  const [{visibleSectionId}] = useSlidesDesignerContext();

  const classList = clsx(
    styles.container,
    visibleSectionId === sectionId ? styles.active : undefined,
    className
  )
  return <label className={classList}>{sectionId.toUpperCase()}</label>
};