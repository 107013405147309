import { groupBy, mapValues, sortBy } from 'lodash'
import { ServerListeningPostSlide } from '../../utils/trpc'

export const enum QueryKeys {
  FETCH_LISTENING_POST = 'FETCH_LISTENING_POST',
  UPSERT_FEEDBACK = 'UPSERT_FEEDBACK',
}

export const groupSlideIds = (slides: Record<string, ServerListeningPostSlide>) => {
  const sortedSlides = sortBy(Object.values(slides || {}), 'order')
  return mapValues(
    groupBy(sortedSlides, (slide) => slide.section),
    (slides) => slides.map((s) => s.id)
  )
}
