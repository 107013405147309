import React from 'react'

import { FullIconButton } from '../../components/button/button'
import { Spacer } from '../Spacer'
import { XmarkThin } from '../../currentColorIcons'

import styles from './DetailHeader.module.scss'

interface DetailHeaderProps {
  title: string
  onClose?: React.ReactEventHandler<Element>
  subtitle?: string
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({
  title,
  subtitle,
  children,
  onClose,
}) => {
  return (
    <div className={styles.header}>
      <h6>{title}</h6>
      {subtitle != null && <div className={styles.subheader}>{subtitle}</div>}
      <Spacer />
      {children}
      {onClose && <FullIconButton icon={<XmarkThin />} onClick={onClose} />}
    </div>
  )
}
