import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ListeningPostUpdate } from '../../../../server-nest/src/_trpc/models/listeningPost.model'
import DeletePopup from '../../components2/DeletePopup/DeletePopup'
import { LoadingContainer } from '../../components/loading/loading'
import { isNotNull, namespaceStyle } from '../../utils'
import {
  ServerListeningPost,
  ServerListeningPostSlide,
  ServerNamespace,
  ServerPublicNamespace,
  trpc,
} from '../../utils/trpc'
import AddSlideCard from '../components/AddSlideCard/AddSlideCard'
import LeftNav from '../components/left-nav/LeftNav/LeftNav'
import { PostHeader } from '../components/PostHeader/PostHeader'
import SlideCard from '../components/shared/slide-card/SlideCard/SlideCard'
import SlideDuration from '../components/SlideDuration/SlideDuration'
import { useUpdateListeningPostTrpc } from '../../trpcHooks/listeningPost/useUpdateListeningPostTrpc'
import { useUpdateNamespaceTrpc } from '../../trpcHooks/namespace/useUpdateNamespaceTrpc'
import styles from './DesignListeningPortal.module.scss'

interface Props {
  namespace: ServerPublicNamespace
}

export const DesignListeningPortal: React.FC<Props> = ({ namespace }) => {
  const { listeningPostId } = useParams()
  const namespaceId = namespace.id

  const listeningPostQuery = trpc.listeningPost.id.useQuery(
    { namespaceId, id: listeningPostId || '' },
    { enabled: Boolean(listeningPostId) }
  )

  if (listeningPostQuery.isLoading) return <LoadingContainer color={namespace.primaryColor} />
  if (!listeningPostQuery.data) return <LoadingContainer color={namespace.primaryColor} />

  return (
    <DesignListeningPortalWithData listeningPost={listeningPostQuery.data} namespace={namespace} />
  )
}

interface DesignListeningPortalWithDataProps {
  namespace: ServerPublicNamespace
  listeningPost: ServerListeningPost
}
const DesignListeningPortalWithData: React.FC<DesignListeningPortalWithDataProps> = ({
  namespace,
  listeningPost,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [listeningPostIdToBeDeleted, setListeningPostIdToBeDeleted] = React.useState<string>()
  const namespaceId = namespace.id
  const listeningPostId = listeningPost.id
  const listeningPostCloneMutate = trpc.listeningPost.clone.useMutation()
  const listeningPostDeleteMutate = trpc.listeningPost.delete.useMutation()
  const listeningPostUpdateMutate = useUpdateListeningPostTrpc()
  const navigate = useNavigate()
  const namespaceMutate = useUpdateNamespaceTrpc()

  React.useEffect(() => {
    const onScroll = (e: Event) => {
      if (!(e.target instanceof HTMLElement)) {
        return
      }
      if (e.target.scrollTop > 0) {
        e.target.classList.add('scrolled')
      } else {
        e.target.classList.remove('scrolled')
      }
    }

    ref.current?.addEventListener('scroll', onScroll, { passive: true })
    const localRefCurrent = ref.current
    return () => {
      localRefCurrent?.removeEventListener('scroll', onScroll)
    }
  }, [ref])

  const isDefault = namespace.defaultListeningPostId === listeningPost.id
  const publicListeningPostId = namespace.namespaceIdToPublicId?.[listeningPost.id]
  const previewUrl = isDefault
    ? '../../../'
    : `../../../${publicListeningPostId || listeningPost.id}`

  const deleteConfirmationHandler = async () => {
    if (listeningPostIdToBeDeleted == null) return
    setListeningPostIdToBeDeleted(undefined)

    await listeningPostDeleteMutate.mutateAsync({ namespaceId, id: listeningPost.id })
    navigate('/admin')
  }

  const onChangeDefault = (isDefault: boolean) => {
    if (namespace.defaultListeningPostId === listeningPostId) {
      if (isDefault) {
        namespaceMutate.mutate({
          id: namespaceId,
          data: { defaultListeningPostId: listeningPostId },
        })
      } else {
        namespaceMutate.mutate({ id: namespaceId, data: { defaultListeningPostId: null } })
      }
    } else if (isDefault) {
      namespaceMutate.mutate({ id: namespaceId, data: { defaultListeningPostId: listeningPostId } })
    }
  }

  const onClone = async () => {
    const clonedId = await listeningPostCloneMutate.mutateAsync({
      namespaceId,
      id: listeningPostId,
    })
    navigate(`/admin/edit/${clonedId}`)
  }

  const onUpdate = (update: ListeningPostUpdate) => {
    listeningPostUpdateMutate.mutate({ namespaceId, id: listeningPostId, data: update })
  }

  const hasSlides = (Object.keys(listeningPost).length ?? 0) > 0
  const style = namespaceStyle(listeningPost)

  return (
    <div
      ref={ref}
      style={style}
      className={clsx(
        styles.container,
        listeningPost.hidden && styles.hidden,
        hasSlides && styles.hasSlides
      )}
    >
      {listeningPost.ogSlideId && (
        <SlidePoller
          namespaceId={namespaceId}
          listeningPostId={listeningPostId}
          slideId={listeningPost.ogSlideId}
        />
      )}
      <LeftNav listeningPost={listeningPost} className={styles.leftNav} namespace={namespace} />
      <PostHeader
        onUpdate={onUpdate}
        namespaceId={namespace.id}
        previewUrl={previewUrl}
        listeningPost={listeningPost}
        onChangeDefault={onChangeDefault}
        onClone={onClone}
        onDeletePost={() => setListeningPostIdToBeDeleted(listeningPost.id)}
        isDefault={isDefault}
        className={styles.header}
      />

      <div className={styles.content}>
        <div className={styles.body}>
          {listeningPost.sections?.map((section) => {
            const slides = listeningPost.groupedSlideIds?.[section]
              ?.map((id) => listeningPost.slides?.[id])
              .filter(isNotNull)
            if (slides == null) return null
            return (
              <Section
                key={section}
                slides={slides}
                namespace={namespace}
                listeningPost={listeningPost}
                title={section}
              />
            )
          })}
        </div>

        <div className={styles.spacer} />
        <AddSlideCard
          namespaceId={namespaceId}
          listeningPostId={listeningPost.id}
          hasSlides={hasSlides}
          className={styles.add}
        />
        <div className={clsx(!hasSlides && styles.spacer)} />
      </div>

      {listeningPostIdToBeDeleted && (
        <DeletePopup
          onSubmit={deleteConfirmationHandler}
          onClose={() => setListeningPostIdToBeDeleted(undefined)}
        />
      )}
    </div>
  )
}

interface SectionProps {
  namespace: ServerNamespace
  listeningPost: ServerListeningPost
  slides: ServerListeningPostSlide[]
  title?: string
}

const Section: React.FC<SectionProps> = ({ slides, namespace, listeningPost, title }) => {
  return (
    <AnimatePresence>
      <div className={styles.sectionTitle}>{title}</div>
      {slides.map((slide, i, allSlides) => (
        <React.Fragment key={slide.id}>
          <SlideCard
            slideIndex={i}
            slide={slide}
            namespace={namespace}
            namespaceId={namespace.id}
            listeningPost={listeningPost}
          />
          {slide.kind === 'text' && allSlides.length - 1 !== i && (
            <SlideDuration
              slide={slide}
              namespaceId={namespace.id}
              listeningPostId={listeningPost.id}
            />
          )}
        </React.Fragment>
      ))}
    </AnimatePresence>
  )
}

interface SlidePollerProps {
  namespaceId: string
  listeningPostId: string
  slideId: string
}
const SlidePoller: React.FC<SlidePollerProps> = ({ slideId, listeningPostId, namespaceId }) => {
  const listeningPost = trpc.listeningPost.id.useQuery({ id: listeningPostId, namespaceId })
  const slide = listeningPost.data?.slides?.[slideId]

  const isUpdatingOgStartedDate = slide?.updatingOg?.startedOn.toMillis()
  const shouldUpdateOg =
    slide?.updatingOg?.value && Date.now() - 60 * 60 * 1000 < (isUpdatingOgStartedDate ?? 0)

  const utils = trpc.useContext()
  React.useEffect(() => {
    if (!shouldUpdateOg) {
      return
    }

    const timeoutRef = setInterval(() => {
      utils.listeningPost.id.invalidate({ id: listeningPostId, namespaceId })
      utils.listeningPost.publicId.invalidate({ id: listeningPostId, namespaceId })
    }, 4000)

    return () => {
      clearInterval(timeoutRef)
    }
  }, [shouldUpdateOg, isUpdatingOgStartedDate, utils, listeningPostId, namespaceId])
  return null
}
