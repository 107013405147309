import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { DragHandleIcon } from '../../../../currentColorIcons'
import { ServerListeningPostSlide } from '../../../../utils/trpc'
import { useSlidesDesignerContext } from '../../../state/slides-designer.context'
import { getSlideBgStyles } from '../../../utils'
import { SlideTitleInput } from '../../shared/slide-card/SlideTitleInput/SlideTitleInput'
import { DemographicQuestionField } from '../../slide-extra-field/DemographicQuestionField/DemographicQuestionField'
import { Likert } from '../../slide-extra-field/Likert/Likert'
import { Likert7 } from '../../slide-extra-field/Likert7/Likert7'
import { LinkedinField } from '../../slide-extra-field/LinkedinField'
import { MultipleChoiceField } from '../../slide-extra-field/MultipleChoiceField/MultipleChoiceField'
import { OpenEnd } from '../../slide-extra-field/OpenEnd/OpenEnd'
import { Recaptcha } from '../../slide-extra-field/Recaptcha/Recaptcha'
import styles from './NavItem.module.scss'

interface NavItemLinkProps extends React.HTMLProps<HTMLDivElement> {
  to?: string
  icon: React.ReactNode
}
export const NavItemLink: React.FC<NavItemLinkProps> = ({
  to,
  children,
  icon,
  onClick,
  className,
}) => {
  if (to) {
    return (
      <Link to={to} className={clsx(styles.navItem, className)}>
        <div className={styles.icon}>{icon}</div>
        <span className={styles.text}>{children}</span>
      </Link>
    )
  } else {
    return (
      <div className={clsx(styles.navItem, styles.addNavItem)} onClick={onClick}>
        <div className={styles.container}>
          <div className={styles.icon}>{icon}</div>
          <span className={styles.text}>{children}</span>
        </div>
      </div>
    )
  }
}

interface PropsV2 {
  className?: string
  onClick?: () => void
  slide: ServerListeningPostSlide & { scrollTo?: any }
  index: number
  draggableProps?: React.HtmlHTMLAttributes<HTMLAnchorElement>
  dragHandleProps?: React.HtmlHTMLAttributes<HTMLAnchorElement>
}

export const NavItemV2 = React.forwardRef<HTMLAnchorElement, PropsV2>(
  ({ className, slide, index, onClick, ...props }, ref) => {
    const [{ visibleSlideId }] = useSlidesDesignerContext()
    const classList = clsx(
      className,
      styles.navItem,
      visibleSlideId === slide.id ? styles.selected : undefined
    )
    const refW = React.useRef<HTMLDivElement>(null)
    const [isLittleCard, setIsLittleCard] = React.useState(false)
    React.useLayoutEffect(() => {
      const w = refW.current?.clientWidth
      if (w != null) {
        setIsLittleCard(w < 200)
      }
    }, [slide])

    return (
      <a ref={ref} href={`#${slide.id}`} onClick={onClick} className={classList} {...props}>
        <div className={styles.dragger}>
          <DragHandleIcon />
        </div>
        <h2 className={styles.index}>{index}</h2>
        <div style={getSlideBgStyles(slide)} className={styles.card} ref={refW}>
          <div className={styles.cardContent}>
            <SlideTitleInput
              key={slide.title}
              placeholder="Slide 1"
              defaultValue={slide.title || ''}
              className={clsx(styles.contentTop)}
            />
            {slide.kind !== 'text' && (
              <div className={styles.contentBottom}>
                {slide.kind === 'multipleChoice' && (
                  <MultipleChoiceField
                    scrollTo={slide.scrollTo}
                    defaultOptions={slide.answerOptions}
                    isLittleCard={isLittleCard}
                  />
                )}
                {slide.kind === 'linkedinAuth' && <LinkedinField isLittleCard={isLittleCard} />}
                {slide.kind === 'recaptcha' && <Recaptcha isLittleCard={isLittleCard} />}
                {slide.kind === 'likert' && <Likert isLittleCard={isLittleCard} />}
                {slide.kind === 'likert7' && <Likert7 isLittleCard={isLittleCard} />}
                {slide.kind === 'openEnd' && (
                  <OpenEnd defaultValue={slide.subtitle} isLittleCard={isLittleCard} />
                )}
                {slide.kind === 'demographics' && (
                  <DemographicQuestionField isLittleCard={isLittleCard} />
                )}
              </div>
            )}
          </div>
        </div>
      </a>
    )
  }
)
