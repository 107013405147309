import React, { createContext, useReducer, Dispatch } from 'react'
import {
  SlidesDisplayState,
  SlidesDisplayAction,
  slidesDisplayReducer,
  slidesDisplayInitialState,
} from './slides-display.reducer'

export const SlidesDisplayContext = createContext<{
  state: SlidesDisplayState
  dispatch: Dispatch<SlidesDisplayAction>
}>({
  state: slidesDisplayInitialState,
  dispatch: () => null,
})

export const SlidesDisplayProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(slidesDisplayReducer, slidesDisplayInitialState)

  return (
    <SlidesDisplayContext.Provider value={{ state, dispatch }}>
      {children}
    </SlidesDisplayContext.Provider>
  )
}

export const useSlidesDisplayContext = () => React.useContext(SlidesDisplayContext)
