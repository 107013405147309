import clsx from 'clsx'
import { sortBy } from 'lodash'
import React from 'react'
import { useKey } from '../../../../hooks/useKey'
import { AnswerOption } from '../../../../models/slide.model'
import { ServerFeedbackCreate, ServerListeningPostSlide } from '../../../../utils/trpc'
import styles from './MultipleChoiceSlide.module.scss'

interface Props {
  slide: ServerListeningPostSlide
  visible: boolean
  onSelect: (data: ServerFeedbackCreate) => void
}

const MultipleChoiceSlide: React.FC<Props> = ({ slide, visible, onSelect }) => {
  const [selectedOptionId, setSelectedOptionId] = React.useState('')

  const handleOptionSelect = React.useCallback(
    (id: string) => {
      setSelectedOptionId(id)
      onSelect({ kind: 'multiple', value: id })
    },
    [onSelect]
  )

  const slideAnswerOptions = React.useMemo(() => {
    return sortBy(Object.values(slide.answerOptions || {}), 'order')
  }, [slide.answerOptions])

  const noOfOptions = Object.keys(slide.answerOptions || {})?.length
  // generates an array of numbers from '1' to N, where N is the number of answer options
  const numbers = React.useMemo(() => {
    if (!noOfOptions) return []
    return Array.from({ length: noOfOptions }, (_, i) => (i + 1).toString())
  }, [noOfOptions])

  const handleKeyPress = React.useCallback(
    (e: KeyboardEvent | undefined) => {
      const key = e?.key
      if (!key) return
      if (!visible) return

      const option = slide.answerOptions?.[+key - 1]
      if (!option) return

      handleOptionSelect(option.id)
    },
    [slide.answerOptions, visible, handleOptionSelect]
  )

  useKey(numbers, handleKeyPress)

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      {slideAnswerOptions.map((option, index) => {
        return (
          <Option
            key={option.id}
            selected={selectedOptionId === option.id}
            option={option}
            index={index}
            onClick={handleOptionSelect}
          />
        )
      })}
    </div>
  )
}

interface OptionProps {
  selected: boolean
  option: AnswerOption
  index: number
  onClick: (optionId: string) => void
}

const Option: React.FC<OptionProps> = ({ option, index, onClick, selected }) => {
  return (
    <div
      onClick={() => onClick(option.id)}
      className={clsx(styles.option, selected && styles.selected)}
    >
      <div className={styles.key}>
        <span className={styles.keyText}>KEY</span>
        <span className={styles.keyNumber}>{index + 1}</span>
      </div>
      <div className={styles.text} tabIndex={-1}>
        {option.label}
      </div>
    </div>
  )
}

export default MultipleChoiceSlide
